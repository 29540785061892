<div id="educator-notes-offcanvas" class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="true"
  tabindex="-1" aria-labelledby="educatorNotesOffCanvasLabel">
  <div class="offcanvas-header">
    <h3 class="h5 offcanvas-title" id="educatorNotesOffCanvasLabel">Educator Notes</h3>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"
      (click)="closeNotes()"></button>
  </div>
  <div class="offcanvas-body">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h3 class="h5 mb-2" id="educatorNotesOffCanvasLabel">Add Note</h3>
      <span certTooltip="Toggle add note">
        <i data-bs-toggle="collapse" data-bs-target="#add-note"
          [ngClass]="{ 'add-icon': !isEditMode, 'dash-icon-fill': isEditMode }" (click)="toggleEditMode()"
          [attr.aria-expanded]="!isEditMode" aria-controls="add-note"></i>
      </span>
    </div>
    <div id="add-note" #collapse="ngbCollapse" [ngbCollapse]="!isEditMode" (ngbCollapseChange)="isEditMode = $event">
      <form #notesForm="ngForm" requiresPolicy="canCreateNotes">
        <div class="row">
          <div class="form-group col-md">
            <label for="content">Note</label>
            <textarea #content="ngModel" row="15" cols="50" class="form-control" id="content" name="content"
              [(ngModel)]="command.content"></textarea>
            <div validationErrors [for]="content.control" [errorEmitter]="errorEmitter"></div>
          </div>
        </div>
          <div>
            <cert-date-time-input name="dateAndTime" [(model)]="contactDateAndTimeString"
              [propertyName]="'dateAndTime'" />
          </div>
          <div class="form-group">
            <label for="contactTypeCodeId">Contact Type</label>
            <ng-select #contactTypeCodeId="ngModel" id="contactTypeCodeId" name="contactTypeCodeId"
              [closeOnSelect]="true" [multiple]="false" [(ngModel)]="command.contactTypeCodeId"
              placeholder="Select a contact type...">
              <ng-option *ngFor="let type of contactTypeCodes" [value]="type.contactTypeCodeId">
                <div title="{{type.description}}">
                  {{type.description}}
                </div>
              </ng-option>
            </ng-select>
            <div validationErrors [for]="contactTypeCodeId.control" [errorEmitter]="errorEmitter"></div>
          </div>
        <div class="row text-end mt-1">
          <div class="col">
            <button *ngIf="isAdd" class="btn btn-sm btn-outline-primary me-2" (click)="onCancelEdit()"
              type="button">Clear</button>
            <button *ngIf="isAdd" class="btn btn-sm btn-primary" (click)="onSubmitNote()" type="button">Add
              Note</button>
            <button *ngIf="!isAdd" class="btn btn-sm btn-outline-primary me-2" (click)="onCancelEdit()"
              type="button">Cancel</button>
            <button *ngIf="!isAdd" class="btn btn-sm btn-primary" (click)="onSubmitNote()" type="button">Update
              Note</button>
          </div>
        </div>
      </form>
    </div>
    <hr />
    <cert-educator-notes-list *ngIf="dataLoaded$ | async" (noteToEdit)="onEdit($event)" [personId$]="personId$"
      [educatorNotes]="educatorNotes" [educatorSystemEvents]="educatorSystemEvents"
      [educatorPersonAlerts]="educatorPersonAlerts" [dataLoaded$]="dataLoaded$" (noteDeleted)="handleFormEvent($event)">
    </cert-educator-notes-list>
  </div>
</div>


  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">Portal Type</th>
        <th scope="col">Title</th>
        <th scope="col">Description</th>
        <th scope="col">Content</th>
        <th scope="col">Content Type</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      @if(helpObjects.length > 0) {
        @for(helpObject of helpObjects; track helpObject.helpObjectId;) {
          <tr>
            <td>
              @if(helpObject.portalType === PortalType.Cert) {
                <span class="badge bg-success">{{ helpObject.portalTypeDescription }}</span>
              }
              @else if (helpObject.portalType === PortalType.LEA) {
                <span class="badge bg-info">{{ helpObject.portalTypeDescription }}</span>
              }
              @else if (helpObject.portalType === PortalType.EPP) {
                <span class="badge bg-warning">{{ helpObject.portalTypeDescription }}</span>
              }
            </td>
            <td>{{ helpObject.title }}</td>
            <td>{{ helpObject.description }}</td>
            <td>
                <a role="button" aria-controls="view-content" class="pe-2 pe-md-0 ps-md-2 text-decoration-none"
                  (click)="onViewContent(helpObject)">View Content</a>
            </td>
            <td>{{ helpObject.contentTypeDescription }}</td>
            <td>
              <i certTooltip="Delete" class="trash-icon text-danger" (click)="deleteHelpObject(helpObject.helpObjectId)"></i>
            </td>
          </tr>
        }
      }
      @else {
        <p class="ms-3 mt-3">There are currently no help objects in the system.</p>
      }
    </tbody>
  </table>

import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'epp-upload-warning-modal',
  templateUrl: './upload-warning-modal.component.html',
  styles: ``
})
export class UploadWarningModalComponent {
  autoAuthorizationCount!: number;
  manualAuthorizationCount!: number;
  cohortStudentProgramsToOverwriteCount!: number;

  constructor(private activeModal: NgbActiveModal) { }

  onConfirm(confirmed: boolean): void {
    this.activeModal.close(confirmed);
  }

  getAutoAuthorizationText(): string {
    switch (this.autoAuthorizationCount) {
      case 0:
        return `are <strong>0</strong> recommendations in the provided file that are`
      case 1:
        return `is <strong>1</strong> recommendation in the provided file that is`
      default:
        return `are <strong>${this.autoAuthorizationCount}</strong> recommendations in the provided file that are`
    }
  }

  getManualAuthorizationText(): string {
    switch (this.manualAuthorizationCount) {
      case 0:
        return `<strong>0</strong> recommendations`
      case 1:
        return `<strong>1</strong> recommendation`
      default:
        return `<strong>${this.manualAuthorizationCount}</strong> recommendations`
    }
  }

  getProgramsToOverwriteText(): string {
    switch (this.cohortStudentProgramsToOverwriteCount) {
      case 0:
        return `There are <strong>0</strong> records in the provided file that will overwrite an existing recommendation for a student in this cohort.`
      case 1:
        return `There is <strong>1</strong> record in the provided file that will overwrite an existing recommendation for a student in this cohort.`
      default:
        return `There are <strong>${this.cohortStudentProgramsToOverwriteCount}</strong> records in the provided file that will overwrite an existing recommendation for students in this cohort.`
    }
  }
}

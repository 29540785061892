<div class="form-group">
  <label class="form-label" for="test-email">Test Email</label>
  <div id="emailHelp" class="form-text">To login as a previous user, just use the same test email.</div>
  <input type="test-email" class="form-control" id="test-email" aria-describedby="emailHelp" [(ngModel)]="testEmail">
</div>
<div *ngFor="let edOrg of userEdOrgs" class="border border-1 rounded p-2 mb-2">
  <div class="form-group">
    <label class="form-label" for="role-select">Ed Org</label>
    <ng-select id="edOrg-select"
             [items]="edOrgs$ | async"
             bindLabel="edOrgName"
             bindValue="edOrgId"
             [(ngModel)]="edOrg.edOrgId"
             (search)="searchTerm$.next($event.term)">
  </ng-select>
  </div>
  <div class="form-group">
    <label class="form-label" for="role-select">Roles</label>
    <ng-select id="role-select" [items]="roles$ | async" [closeOnSelect]="true" [multiple]="true"
      bindLabel="adeConnectRoleName" bindValue="roleId" [(ngModel)]="edOrg.roleIds">
    </ng-select>
  </div>
</div>
<div class="d-flex justify-content-end">
  <button class="btn btn-sm btn-outline-primary" (click)="onAddEdOrg()">Add Ed Org</button>
</div>
<div class="d-grid gap-2 mt-4">
  <button class="btn btn-primary btn-block" (click)="onLogin()">Login</button>
</div>

<div class="row mb-2">
  <div class="col-6">
    <h2 class="h4">Recommendations File Upload</h2>
  </div>
  <div class="col-6">
    <button type="button" class="btn btn-primary float-end me-2" certTooltip="Return to cohort management."
      (click)="onBackToCohort()">Back to Cohort</button>
  </div>
</div>
<div class="row mt-3">
  <div class="col-6 d-flex">
    <cert-uploader [mimeType]="mimeType.XLSX" (fileSelected)="onFileSelected($event)" />
    <button type="button" class="btn btn-outline-primary ms-2 align-self-start" (click)="tryValidateIRFile()"
      [disabled]="!content">Validate File</button>
  </div>
</div>
<div class="mt-3">
  <epp-ir-validation-results [cohortId]="cohortId" [validationResults$]="validationResults$" />
</div>


<div class="container-fluid h-100 d-flex align-items-center justify-content-center loader-bg">
  <div class="spinner">
    <div class="rect1"></div>
    <div class="rect2"></div>
    <div class="rect3"></div>
    <div class="rect4"></div>
    <div class="rect5"></div>
    <p>Signing in</p>
  </div>
</div>

import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserPolicyEvalDto } from 'src/shared/services/api.service'; // Replace with the correct path
import { PolicySetKey } from 'src/shared/constants';
import { LocalStorageService } from 'src/shared/services/local-storage.service';

@Directive({
  selector: '[componentRequiresPolicy]'
})
export class ComponentRequiresPolicyDirective {
  constructor(
    private localStorageService: LocalStorageService,
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef
  ) { }

  @Input() set componentRequiresPolicy(policyName: keyof UserPolicyEvalDto) {
    const policySet = JSON.parse(this.localStorageService.get(PolicySetKey) ?? '{}') as UserPolicyEvalDto;
    if (policySet) {
      const result = policySet[policyName];
      if (result !== true) {
        this.viewContainerRef.clear();
      } else {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(phoneNumber?: number | string): string {
    if(phoneNumber === undefined || phoneNumber === null || (typeof phoneNumber === 'string' && (<string>phoneNumber).length === 0)) {
      return "";
    }

    let value = phoneNumber.toString();

    let areaCode, prefix, lineNumber;

    areaCode = value.slice(0, 3);
    prefix = value.slice(3, 6);
    lineNumber = value.slice(6);

    return "(" + areaCode + ") " + prefix + "-" + lineNumber;
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'cert-empty-landing',
  templateUrl: './empty-landing.component.html',
  styles: ``
})
export class EmptyLandingComponent {

}

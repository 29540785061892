import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CertPolicies } from "src/auth/cert-policies";
import { PolicyGuard } from "src/auth/policy.guard";
import { AdminLayoutComponent } from "./admin-layout/admin-layout.component";
import { RolePermissionAssignmentComponent } from "./role-permission-assignment/role-permission-assignment.component";
import { AdminHelpComponent } from "./admin-help/admin-help.component";


const appRoutes: Routes = [
  {
    path: '', component: AdminLayoutComponent, children: [
      { path: '', component: RolePermissionAssignmentComponent, title: 'Role-Permission Assignment', canActivate: [PolicyGuard], data: { policy: CertPolicies.CanMapRolePermissions } },
      { path: 'help', component: AdminHelpComponent, title: 'System Help Objects', canActivate: [PolicyGuard], data: { policy: CertPolicies.CanManageHelpObjects } },
    ]
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class AdminRoutingModule { }

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/auth/services/auth.service';
import { Production } from 'src/shared/constants';
import { ConfigService } from 'src/shared/services/config.service';

@Component({
  selector: 'cert-tos',
  templateUrl: './tos.component.html',
  styles: ``
})
export class TosComponent {
  constructor(private authService: AuthService, private configService: ConfigService, private router: Router) {
  }

  agree() {
    if (this.configService.config.environment !== Production && this.configService.config.testModeAllowed) {
      this.router.navigateByUrl('/launch/selector');
    } else {
      this.authService.login();
    }
  }
}

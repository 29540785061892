<div class="modal-content">
  <div class="modal-header bg-primary-100">
    <h5 class="modal-title"><i class="bi bi-bug-fill text-primary"></i> Unexpected Error</h5>
  </div>
  <div class="modal-body">
    <p>
      An unexpected error has occurred in the application. Please try again or click the button below to report the error.
    </p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-outline-secondary" (click)="onCancel()">Cancel</button>
    <button type="button" class="btn btn-danger"><cert-issue-collector [traceId]="traceId" [errorId]="errorId"/></button>
  </div>
</div>

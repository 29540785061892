import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'cert-custom-select-renderer',
  template: `
  <div class="d-flex align-items-center h-100">
    <ng-select
      class="full-width-select"
      appendTo="body"
      [closeOnSelect]="true"
      [multiple]="false"
      [(ngModel)]="selectedValue"
      [clearable]="false"
      [searchable]="false"
      (change)="onValueChange($event)">
        <ng-option *ngFor="let option of selectOptions" [value]="option.value" [disabled]="option.disabled" certTooltip="{{option.label}}">
          <div title="{{option.label}}">
            {{option.label}}
          </div>
        </ng-option>
    </ng-select>
  </div>
`,
  styles: [`
  ::ng-deep ng-select {
      width: 100%;
  }
  ::ng-deep .full-width-select {
    width: 100%;
  }
`]
})
export class CustomSelectRendererComponent implements ICellRendererAngularComp {
  private params!: ICellRendererParams<any, any>;
  selectedValue: any;
  selectOptions: CustomSelectOption[] = [];

  agInit(params: ICellRendererParams<any, any>): void {
    this.params = params;
    this.selectOptions = params!.colDef!.cellRendererParams(params).selectOptions;
    this.selectedValue = params!.colDef!.cellRendererParams(params).selectedValue;
  }

  onValueChange(value: any) {
    const eventData = {
      newValue: value,
      rowData: this.params.data
    };

    this.params.context.customSelectChangedEvent.emit(eventData);
  }

  refresh(params: any): boolean {
    return false;
  }
}

export interface CustomSelectOption {
  label: string,
  value: string,
  disabled: boolean
}

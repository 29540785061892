import { Component, EventEmitter, Input, Output } from '@angular/core';
import { dateParser } from '../services/mask-date-parser';

@Component({
  selector: 'cert-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent {
  @Input() labelText: string = '';

  @Input() name: string = '';

  @Input() startModel!: string;

  @Input() endModel!: string;

  @Input() model!: string;

  @Output() startModelChange = new EventEmitter<string>();

  @Output() endModelChange = new EventEmitter<string>();

  @Output() onChange = new EventEmitter();

  dateParser = dateParser;

  updateModel() {
    this.startModelChange.emit(this.startModel);
    this.endModelChange.emit(this.endModel);
    this.onChange.emit();
  }

  onClear() {
    this.startModel = '';
    this.endModel = '';
    this.updateModel();
  }
}

@switch (pdRequirementState) {
  @case (ProfessionalDevelopmentRequirementState.Met) {
    <span class="badge badge-success ms-2" [certTooltip]="'The professional development requirement has been met for renewal of this certificate.'">
      PD Requirement Met
    </span>
  }
  @case (ProfessionalDevelopmentRequirementState.NotMet) {
    <span class="badge badge-danger ms-2" [certTooltip]="'The professional development requirement has not been met for renewal.'">
      PD Requirement Not Met
    </span>
  }
  @case (ProfessionalDevelopmentRequirementState.NotRequiredYet) {
    <span class="badge badge-info ms-2" [certTooltip]="'Professional development is required to renew this certificate, but the certificate is not eligible for renewal'">
      PD Not Yet Required
    </span>
  }
}

import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'div[gridValidationErrors]'
})
export class GridValidationErrorsDirective implements OnInit, OnDestroy {
  @Input('errorEmitter') validationErrorsEmitter!: EventEmitter<string[]>;

  private validationErrorsEventSubscription!: Subscription;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    if (this.validationErrorsEmitter) {
      this.validationErrorsEventSubscription = this.validationErrorsEmitter.subscribe(x => {
        this.updateErrors(x);
      });
    }
  }

  ngOnDestroy(): void {
    this.validationErrorsEventSubscription?.unsubscribe();
  }

  updateErrors(errors: string[]): void {
    // Remove all child nodes
    while (this.el.nativeElement.firstChild) {
      this.renderer.removeChild(this.el.nativeElement, this.el.nativeElement.firstChild);
    }

    if (errors.length > 0) {
      // create bs alert
      const alertDiv = this.renderer.createElement('div');
      this.renderer.addClass(alertDiv, 'alert');
      this.renderer.addClass(alertDiv, 'alert-danger');
      this.renderer.addClass(alertDiv, 'alert-dismissible');
      this.renderer.addClass(alertDiv, 'fade');
      this.renderer.addClass(alertDiv, 'show');
      this.renderer.addClass(alertDiv, 'mb-0');
      this.renderer.addClass(alertDiv, 'border-0');
      this.renderer.setAttribute(alertDiv, 'role', 'alert');

      // add errors
      errors.forEach((error: string) => {
        const errorDiv = this.renderer.createElement('div');
        this.renderer.setProperty(errorDiv, 'textContent', error);
        this.renderer.appendChild(alertDiv, errorDiv);
      });

      // add dismiss
      const closeButton = this.renderer.createElement('button');
      this.renderer.setAttribute(closeButton, 'type', 'button');
      this.renderer.addClass(closeButton, 'btn-close');
      this.renderer.setAttribute(closeButton, 'data-bs-dismiss', 'alert');
      this.renderer.setAttribute(closeButton, 'aria-label', 'Close');
      this.renderer.appendChild(alertDiv, closeButton);

      this.renderer.appendChild(this.el.nativeElement, alertDiv);
    }
  }
}




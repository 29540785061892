import { Component } from '@angular/core';

@Component({
  selector: 'cert-lea-layout',
  templateUrl: './lea-layout.component.html',
  styles: ``
})
export class LeaLayoutComponent {

}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'cert-icon-link-renderer',
  template: `<a *ngIf="showIcon" (click)="onClick($event)"><i [class]="iconClass"></i></a>`,
  styles: ``
})
export class IconLinkRendererComponent implements ICellRendererAngularComp {
  params: any;
  url!: string;
  iconClass!: string;
  showIcon!: boolean;

  agInit(params: any): void {
    this.params = params;

    if (!this.params.iconClass) {
      throw new Error('No icon class was defined on the cellRendererParams. The IconLinkRenderComponent requires an iconClass.')
    }

    this.iconClass = params.iconClass;
    this.showIcon = params.showIcon === undefined ? true : params.showIcon;
  }

  refresh(params: any): boolean {
    return false;
  }

  onClick(event: any): void {
    if (this.params.onClick instanceof Function) {
      this.params.onClick(this.params.node.data);
    }
  }
}

import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'div[formLevelValidationErrors]'
})
export class FormLevelValidationErrorsDirective implements OnInit, OnDestroy {
  @Input('for') form!: NgForm;
  @Input('errorEmitter') validationErrorsEvent!: EventEmitter<any>;
  @Input('modelErrorsOnly') modelErrorsOnly: boolean = true;

  private statusChangesSubscription!: Subscription;
  private validationErrorsEventSubscription!: Subscription;

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    if (this.form) {
      this.statusChangesSubscription = this.form.statusChanges!.subscribe(() => {
        this.updateErrors([]);
      });
    }

    if (this.validationErrorsEvent) {
      this.validationErrorsEventSubscription = this.validationErrorsEvent.subscribe(errors => {
        if (errors) {
          const errorList = this.modelErrorsOnly ? errors.modelErrors : errors.allErrors;
          this.updateErrors(errorList);
        } else {
          this.updateErrors([]);
        }
      });
    }
  }

  ngOnDestroy(): void {
    if(this.form) {
      this.statusChangesSubscription.unsubscribe();
    }
    this.validationErrorsEventSubscription?.unsubscribe();
  }

  updateErrors(errors: any[]): void {
    this.el.nativeElement.innerHTML = '';

    errors.forEach((error: string) => {
      const span = document.createElement('span');
      span.className = 'field-validation-error';
      span.textContent = error;
      this.el.nativeElement.appendChild(span);
    });
  }
}

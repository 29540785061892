import { Component, EventEmitter, Input, ViewChild } from '@angular/core';
import { CohortManagementActionsCellRendererComponent } from './cohort-management-actions-cell-renderer/cohort-management-actions-cell-renderer.component';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, ColDef, GridOptions, RowNode } from 'ag-grid-community';
import { ConfirmationModalComponent } from 'src/shared/confirmation-modal/confirmation-modal.component';
import { GridCommonService } from 'src/shared/grid/grid-common.service';
import { EppClient, AgGridSortModel, EppCohortStudentDto, RemoveStudentFromCohortCommand } from 'src/shared/services/api.service';
import { catchValidationErrors } from 'src/shared/utilities/validator-utilities';

@Component({
  selector: 'epp-cohort-management-grid',
  templateUrl: './cohort-management-grid.component.html',
  styles: ``
})
export class CohortManagementGridComponent {
  constructor(
    private gridCommonService: GridCommonService,
    private eppClient: EppClient,
    private router: Router,
    private modalService: NgbModal) { }

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  @Input() cohortId!: number;

  get gridApi(): GridApi {
    return this.agGrid.api;
  }

  get gridHeight() {
    return (window.innerHeight - 300).toString() + 'px';
  }

  sortModel!: AgGridSortModel[];
  filterModel!: {};

  errorEmitter = new EventEmitter<any>();

  getColDefs(): ColDef[] {
    const colDefs: ColDef[] = [
      {
        field: 'cohortId',
        filter: 'agNumberColumnFilter',
        filterParams: { filterOptions: this.gridCommonService.numberFilterOptions },
        hide: true,
      },
      {
        field: 'personId',
        filter: 'agNumberColumnFilter',
        filterParams: { filterOptions: this.gridCommonService.numberFilterOptions },
        hide: true,
      },
      {
        field: 'ein',
        filter: 'agTextColumnFilter',
        headerName: 'EIN',
        cellRenderer: (params: any) => {
          const cohortId = this.cohortId ? `?cohortId=${this.cohortId}` : '';
          return `<a href="/epp/candidates/${params.value}${cohortId}" target="_blank">${params.value}</a>`;
        },
        flex: 0.75,
        headerTooltip: 'Educator Identification Number. The unique identifier for a person in the ADE Certification System.'
      },
      {
        field: 'lastName',
        filter: 'agTextColumnFilter',
        headerName: 'Last Name',
        flex: 1
      },
      {
        field: 'firstName',
        filter: 'agTextColumnFilter',
        headerName: 'First Name',
        flex: 1
      },
      {
        field: 'middleName',
        filter: 'agTextColumnFilter',
        headerName: 'Middle Name',
      },
      {
        field: 'ivpCardNumber',
        filter: 'agTextColumnFilter',
        headerName: 'IVP Card Number',
        flex: 1
      },
      {
        field: 'ivpCardStatus',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params: any) => {
            this.eppClient.getCohortStudentsSetFilterValues({
              propertyName: 'IvpCardStatus'
            }).subscribe(x => params.success(x.columnValues));
          }
        },
        headerName: 'IVP Card Status',
        flex: 0.75,
        headerTooltip: `A person's IVP card status in the ADE Certification System.`
      },
      {
        field: 'hasDisciplinaryHistory',
        filter: 'agSetColumnFilter',
        filterParams: {
          debounceMs: 1000,
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        valueFormatter: (params: any) => params.data.hasDisciplinaryHistory ? 'Yes' : 'No',
        valueGetter: (params: any) => params.data.hasDisciplinaryHistory ? 'Yes' : 'No',
        headerName: 'Has Disciplinary History',
        flex: 1,
        headerTooltip: `Indicates a discipline record in the ADE Certification System.`
      },
      {
        field: 'program',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params: any) => {
            this.eppClient.getCohortStudentsSetFilterValues({
              propertyName: 'Program'
            }).subscribe(x => params.success(x.columnValues));
          }
        },
        headerName: 'Program',
        flex: 1.25,
        cellRenderer: (params: any) => {
          return params.value ? params.value : 'N/A';
        },
        headerTooltip: `The preparation program number and name for your institution.`
      },
      {
        field: 'verified',
        filter: 'agSetColumnFilter',
        filterParams: {
          debounceMs: 1000,
          values: [true, false],
          valueFormatter: (params: any) => params.value ? 'Yes' : 'No'
        },
        valueFormatter: (params: any) => params.data.verified ? 'Yes' : 'No',
        valueGetter: (params: any) => params.data.verified ? 'Yes' : 'No',
        headerName: 'Authorized',
        flex: 0.5
      },
      {
        headerName: 'Actions',
        sortable: false,
        cellRenderer: CohortManagementActionsCellRendererComponent,
        suppressMenu: true,
        filter: false,
        flex: 0.75
      }
    ];

    return colDefs;
  }


  gridOptions: GridOptions<EppCohortStudentDto> = {
    getRowId: (params) => {
      return params.data.uniqueId.toString();
    },
    domLayout: 'normal',
    rowModelType: 'serverSide',
    cacheBlockSize: 100,
    pagination: true,
    paginationPageSize: 20,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    defaultColDef: {
      sortable: true,
      resizable: true,
      suppressMovable: true,
      suppressMenu: true,
      floatingFilter: true,
      filter: 'agTextColumnFilter'
    },
    columnDefs: this.getColDefs(),
    context: {
      componentParent: this
    },
    serverSideFilterOnServer: true,
    serverSideSortOnServer: true,
    serverSideDatasource: {
      getRows: (params) => {
        params.api.showLoadingOverlay();

        const requestPayload = {
          cohortId: this.cohortId,
          pagination: {
            startRow: params.request.startRow!,
            endRow: params.request.endRow!
          },
          sortModels: params.request.sortModel && params.request.sortModel.length > 0
            ? params.request.sortModel
            : [
              {
                colId: 'ein',
                sort: 'desc',
                sortIndex: 0,
              }
            ],
          filterModels: this.gridCommonService.onFilterChanged(params.request.filterModel),
        };

        this.eppClient.getCohortStudents(requestPayload).subscribe({
          next: (data) => {
            params.success({
              rowData: data.studentEntries,
              rowCount: data.totalStudentEntries,
            });
            params.api.hideOverlay();
          },
          error: () => {
            params.fail();
          },
        });
      },
    }
  }

  resetFilters() {
    if (this.gridApi) {
      this.gridApi.setFilterModel(null);
      this.gridApi.refreshServerSide();
    }
  }

  refreshServerSide() {
    this.gridApi.refreshServerSide();
  }

  onSelectionChanged(node: RowNode, isSelected: boolean) {
    this.gridCommonService.onSelectionChanged(node, isSelected);
  }

  viewStudentDetails(ein: string) {
    const queryParams = this.cohortId ? { cohortId: this.cohortId } : {};

    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/epp/candidates/${ein}`], { queryParams })
    );

    window.open(url, '_blank');
  }

  removeStudentFromCohort(cohortStudentId: number, cohortId: number, firstName: string, lastName: string, ein: string) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      backdrop: 'static',
      size: 'md',
      centered: true,
    });

    modalRef.componentInstance.header = 'Remove Candidate';
    modalRef.componentInstance.message = `You are removing candidate ${firstName} ${lastName} (EIN: ${ein}). Are you sure?`;

    modalRef.result.then((result) => {
      if (result) {
        this.confirmRemoveStudentFromCohort(cohortStudentId, cohortId);
      }
    });
  }

  confirmRemoveStudentFromCohort(cohortStudentId: number, cohortId: number) {
    let command: RemoveStudentFromCohortCommand = {
      cohortId: cohortId,
      cohortStudentId: cohortStudentId
    }

    this.eppClient.removeStudentFromCohort(command)
      .pipe(catchValidationErrors(undefined, this.errorEmitter))
      .subscribe(x => {
        this.gridApi.refreshServerSide();
      });
  }

  onExportStudents() {
    this.gridCommonService.exportToExcel('cohort_students.xlsx', this.gridApi);
  }
}

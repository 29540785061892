<div class="card">
  <div class="card-header">
    <div class="row">
      <div class="col d-flex align-items-center">
        <span class="h5 mb-0">Document Details</span>
        <span class="badge badge-md ms-2"
          [ngClass]="reviewBadgeClass">{{detailsForDocumentReview!.reviewStatusDescription}}</span>
      </div>
      <div class="col text-end">
        <span *ngIf="!isExpanded"><i class="collapsed-icon clickable" (click)="toggleExpand()"></i></span>
        <span *ngIf="isExpanded"><i class="expanded-icon clickable" (click)="toggleExpand()"></i></span>
      </div>
    </div>
  </div>
  <div class="card-body mx-2" #collapse="ngbCollapse" [ngbCollapse]="!isExpanded" (ngbCollapseChange)="isExpanded = $event">
    <div class="row mb-2">
      <div class="col-lg">
        <label class="fw-bold" for="ein">EIN</label>
        <p name="ein" *ngIf="detailsForDocumentReview">{{detailsForDocumentReview.ein}}</p>
      </div>
      <div class="col-lg">
        <label class="fw-bold" for="name">Name</label>
        <p name="name" *ngIf="detailsForDocumentReview">{{detailsForDocumentReview.educatorName}}</p>
      </div>
      <div class="col-lg">
        <label class="fw-bold" for="altNames">Alternate Names</label>
        <div class="input-group"></div>
        <ng-template #alternateNamesPopoverContent>
          <p class="popover-spacing" *ngFor="let alternateName of detailsForDocumentReview.alternateNames">
            {{alternateName}}</p>
        </ng-template>
        <a *ngIf="detailsForDocumentReview && detailsForDocumentReview.alternateNames.length" class="pointer clickable"
          [ngbPopover]="alternateNamesPopoverContent" popoverTitle="Alternate Names"
          certTooltip="Alternate Names">
          View
        </a>
        <p name="altNames" *ngIf="detailsForDocumentReview && !detailsForDocumentReview.alternateNames.length">None</p>
      </div>
      <div class="col-lg">
        <label class="fw-bold" for="dateOfBirth">Date of Birth</label>
        <p name="dateOfBirth" *ngIf="detailsForDocumentReview">{{detailsForDocumentReview.dateOfBirth | date
          : 'MM/dd/yyyy'}}</p>
      </div>
      <div class="col-lg">
        <label class="fw-bold" for="ssn">SSN</label>
        <div class="input-group">
          <ng-template #popoverBody>{{detailsForDocumentReview.ssn! | SSN}}</ng-template>
          <a type="button" popoverTitle="SSN" class="clickable" [ngbPopover]="popoverBody" certTooltip="SSN">View</a>
        </div>
      </div>
      <div class="col">
        <label class="fw-bold" for="addresses">Addresses</label>
        <div class="input-group">
          <ng-template #addressesPopoverBody>
            <div *ngFor="let address of detailsForDocumentReview.addresses; let isLast = last">
              <span>{{address.line1}}</span>
              <span class="ms-1" *ngIf="address.isActive && detailsForDocumentReview.addresses.length > 1">
                <i class="bi bi-star-fill text-primary" certTooltip="Active"></i>
              </span>
              <div *ngIf="address.line2">{{address.line2}}</div>
              <span *ngIf="!address.cityStateProvince">{{address.city}}, {{address.stateCode}}</span>
              <span *ngIf="address.cityStateProvince">{{address.cityStateProvince}},</span>
              {{address.zipCode}} <span *ngIf="address.zipPlusFourCode"> + {{address.zipPlusFourCode}}</span>
              <hr *ngIf="!isLast">
            </div>
          </ng-template>
          <a type="button" popoverTitle="Addresses" class="clickable" [ngbPopover]="addressesPopoverBody"
            certTooltip="Addresses">View</a>
        </div>
      </div>
      <div class="col">
        <label class="fw-bold" for="docReceivedDate">Received Date</label>
        <p name="docReceivedDate" *ngIf="detailsForDocumentReview">
          {{detailsForDocumentReview.documentReceivedDate | date : 'MM/dd/yyyy'}}</p>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-4">
        <label for="fileName">File Name</label>
        <input type="text" class="form-control" id="fileName" name="fileName" [(ngModel)]="fileName"
          certTooltip="Special characters are not allowed" fileSystemRegex>
      </div>
      <div class="col-lg-4">
        <label for="documentTypeSelections">Document Types</label>
        <ng-select id="documentTypeSelections" name="documentTypeSelections" [closeOnSelect]="true" [multiple]="true"
          [(ngModel)]="documentTypeSelections" placeholder="Select document types...">
          <ng-option *ngFor="let option of documentTypeOptions" [value]="option">
            {{option.description}}
          </ng-option>
        </ng-select>
      </div>
      <div class="col-lg-4">
        <label for="reviewStatus">Review Status</label>
        <ng-select id="reviewStatus" name="reviewStatus" [closeOnSelect]="true" [multiple]="false"
          [(ngModel)]="reviewStatus" placeholder="Select review status...">
          <ng-option *ngFor="let option of reviewStatusOptions" [value]="option.value">
            {{option.label}}
          </ng-option>
        </ng-select>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col">
        <label for="reviewComments">Review Comments</label>
        <textarea id="reviewComments" name="reviewComments" class="form-control" rows="2"
          [(ngModel)]="detailsForDocumentReview.reviewComments" placeholder="Enter review comments...">
          </textarea>
      </div>
    </div>
    <div class="row">
      <div class="col text-end">
        <button type="submit" class="btn btn-primary" (click)="onSubmit()">Save</button>
      </div>
    </div>
  </div>
</div>

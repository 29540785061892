export const dateParser = (value: string | number | undefined | null): string => {
  if (typeof value === 'string') {
    let month, day, year;

    if (value.length === 8) {
      month = Number(value.substring(0, 2));
      day = Number(value.substring(2, 4));
      year = Number(value.substring(4, 8));
    } else if (value.length === 7) {
      const monthDayResult = handleMonthDateAmbiguity(value);
      month = monthDayResult.month;
      day = monthDayResult.day;
      year = Number(value.substring(3, 7));
    } else {
      return value as string;  // Invalid format
    }

    const parsedDate = new Date(year, month - 1, day);

    // Check if the parsed date is valid
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate.toISOString();
    }
  }

  return value as string;
};

function handleMonthDateAmbiguity(dateText: string): { month: number, day: number } {
  const twoDigitMonth = Number(dateText.substring(0, 2));
  const oneDigitMonth = Number(dateText.substring(0, 1));

  let month = 0;
  let day = 0;

  // This function assumes that a parsable date will be at least 7 digits.
  // Which means that a single digit month and day will be disallowed.
  if (twoDigitMonth > 12) {
    month = oneDigitMonth;
    // in a one-digit month, assume a single digit day.
    day = Number(dateText.substring(1, 3));
  } else {
    month = twoDigitMonth;
    // in a two-digit month, assume a two digit day.
    day = Number(dateText.substring(2, 4));
  }

  return { month, day };
}

<div class="modal-content">
  <div *ngIf="showEducatorSearch">
    <div class="modal-header d-flex justify-content-between">
        <h3>Educator Search</h3>
        <div>
          <button type="button" class="btn btn-outline-primary btn-sm me-2" (click)="clearEducatorSearchForm()">Clear</button>
          <button type="button" class="btn btn-primary btn-sm me-2" *ngIf="showEducatorCreateButton" requiresPolicy="canCreatePersons" (click) ="toggleShowEducatorSearch(null)">Create New Educator</button>
          <button type="button" class="btn-close " data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
        </div>

    </div>
    <div class="modal-body m-2">
      <cert-educator-search-modal [previousSearchRequest]="getEducatorSearchQuery" [showCreateButton]="showEducatorCreateButton" (toggleCreateButton)="toggleEducatorCreateButton($event)"></cert-educator-search-modal>
    </div>
  </div>
  <div *ngIf="!showEducatorSearch">
    <div class="modal-header d-flex justify-content-between">
        <h3>Educator Create</h3>
        <div class="d-flex">
          <button type="button" class="btn btn-primary btn-sm me-2" (click) ="toggleShowEducatorSearch(null)">Search</button>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
        </div>
      </div>
    <div class="modal-body">
      <cert-educator-create-modal [createPersonsCommand]="createPersonsCommand" (toggleSearchComponent)="toggleShowEducatorSearch($event)"></cert-educator-create-modal>
    </div>
  </div>
</div>



import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { PolicySetKey } from 'src/shared/constants';
import { UserPolicyEvalDto } from 'src/shared/services/api.service';
import { LocalStorageService } from 'src/shared/services/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CertRedirectorService {
  constructor(private localStorageService: LocalStorageService) { }

  redirectUser(): string | UrlTree {
    const policySet = JSON.parse(this.localStorageService.get(PolicySetKey) ?? '{}') as UserPolicyEvalDto;

    if (policySet.canSelfAssignServiceItems) {
      return 'cert/services';
    } else if (policySet.canViewNasdtecEntries) {
      return 'cert/discipline/nasdtec-matches';
    } else if (policySet.canViewCollectedPaymentsReport) {
      return 'cert/payments/collected-report';
    } else {
      return 'cert/no-home'
    }
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'epp-help-page',
  templateUrl: './help-page.component.html',
  styles: ``
})
export class HelpPageComponent {
  constructor(private router: Router) { }

  navigateToIRUploadGuide() {
    this.router.navigateByUrl('/epp/help/ir-upload-guide');
  }
}

import { Component, Input } from "@angular/core";

@Component({
  selector: 'cert-custom-button-renderer',
  template: `
    <button (click)="btnClickedHandler($event)">{{ buttonText }}</button>
  `,
})
export class CustomButtonRendererComponent {
  @Input() buttonText: string | undefined;
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  btnClickedHandler(event: any) {
    this.params.clicked(this.params.value);
  }
}

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import flatpickr from 'flatpickr';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index';

@Component({
  selector: 'cert-date-picker',
  templateUrl: './date-picker.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements AfterViewInit {
  @Input()
  name: string = '';

  @Input()
  propertyName: string = '';

  @Input()
  model!: string | undefined;

  @Input()
  minDate!: string;

  @Input()
  maxDate!: string;

  @Input()
  defaultDate!: string;

  @Input()
  isDisabled: boolean = false;

  @Input()
  isRequired: boolean = false;

  @Input()
  onlyMonthYear: boolean = false;

  @Input()
  dateAndTime: boolean = false;

  maxLength!: number | null;

  isFieldValid = true;

  dateFormat: string = '';

  @Output() modelChange = new EventEmitter<string>();

  errorEmitter = new EventEmitter<any>();

  flatPicker!: any;

  updateModel(date: string) {
    this.model = date;

    this.modelChange.emit(this.model);
  }

  ngOnInit() {
    if (this.dateAndTime) {
      this.dateFormat = 'm/d/Y h:i K';
      this.maxLength = 18;
    } else if (this.onlyMonthYear) {
      this.dateFormat = 'm/Y';
    } else {
      this.dateFormat = 'm/d/Y';
      this.maxLength = 10;
    }
  }

  ngAfterViewInit() {
    this.flatPicker = flatpickr('#datePicker-' + this.name, {
      dateFormat: this.dateFormat,
      minDate: this.minDate,
      maxDate: this.maxDate,
      allowInput: !this.onlyMonthYear,
      enableTime: this.dateAndTime ? true : false,
      defaultDate: this.defaultDate,
      plugins: this.onlyMonthYear ? [monthSelectPlugin()] : [],
      onChange: (selectedDates: Date[], dateStr: string) => {
        this.updateModel(dateStr);
      },
    });
  }

  ngOnChanges() {
    if (this.model && this.onlyMonthYear) {
      const date = new Date(this.model);

      const monthName = date.toLocaleString('default', { month: 'long' });

      this.model = monthName + ' ' + date.getFullYear();
    }
  }

  onKeyDown(key: any) {
    if (key.which !== 8) {
      const numChars = key.target.value.length;
      if (numChars === 2 || numChars === 5) {
        let thisVal = key.target.value;
        thisVal += '/';
        key.target.value = thisVal;
      }
    }
  }

  resetForm() {
    if (this.flatPicker) {
      this.flatPicker.setDate(this.defaultDate);
    }
  }
}

export const timeParser = (value: string | number | undefined | null): string => {
  if (typeof value !== 'string') {
    return "";
  }

  const timeFormatRegex = /^(1[0-2]|0?[1-9]):([0-5][0-9])$/;
  if (!timeFormatRegex.test(value)) {
    return "";
  }

  let [hours, minutes] = value.split(':').map(Number);
  const formattedTime = `${hours.toString()}:${minutes.toString().padStart(2, '0')}`;

  return formattedTime;
};

<div class="mt-4 ms-4 fs-6">
  @if (errors) {
  @for(rowError of errors.rowErrors; track rowError) {
  <div class="mb-3">
    <div class="h6">Row {{ rowError.rowNumber }}</div>
    <div class="ms-4 mt-2">
      <ul class="list-group">
        @for(columnError of rowError.columnErrors; track columnError;) {
          <li class="list-group-item error-text">
            {{ columnError.errorMessage }}
            <span class="badge bg-info ms-2 fs-6">Cell {{columnError.location.column + columnError.location.row}}</span>
          </li>
          }
      </ul>
    </div>
  </div>
  }

  }
  @else {
  <div>There were no errors with this worksheet.</div>
  }
</div>

<body>
  <cert-loader *ngIf="isLoading"></cert-loader>
  <cert-nav-menu></cert-nav-menu>
  <main class="container-fluid mt-4 px-4" id="main">
    <router-outlet></router-outlet>
  </main>
  <cert-toast-container></cert-toast-container>
  <div *ngIf="environment && environment!=='Production'" class="fixed-bottom h6 ms-2 w-25">
    <span class="badge bg-warning"> {{environment | uppercase}}</span>
  </div>
</body>

import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IRSpreadsheetNames } from 'src/shared/constants';
import { EppClient, IRSpreadsheetErrorsDto, WorksheetErrorDto } from 'src/shared/services/api.service';
import { UploadWarningModalComponent } from '../upload-warning-modal/upload-warning-modal.component';
import { Router } from '@angular/router';
import { ToastService } from 'src/shared/services/toast.service';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/shared/services/loader.service';

@Component({
  selector: 'epp-ir-validation-results',
  templateUrl: './ir-validation-results.component.html',
  styles: ``
})
export class IrValidationResultsComponent {
  @Input() validationResults$!: Observable<IRSpreadsheetErrorsDto>;
  @Input() cohortId!: number;

  spreadsheetErrorsDto!: IRSpreadsheetErrorsDto;
  programsErrors!: WorksheetErrorDto;
  certificatesErrors!: WorksheetErrorDto;
  endorsementsErrors!: WorksheetErrorDto;
  requirementsMetErrors!: WorksheetErrorDto;
  IRSpreadsheetNames = IRSpreadsheetNames;

  constructor(private modalService: NgbModal, private eppClient: EppClient,
    private router: Router, private toastService: ToastService, private loaderService: LoaderService) { }

  ngOnInit() {
    this.validationResults$.subscribe(x => {
      this.assignWorksheetErrors(x);
      this.spreadsheetErrorsDto = x;
    });
  }

  assignWorksheetErrors(dto: IRSpreadsheetErrorsDto) {
    this.programsErrors = null as unknown as WorksheetErrorDto;
    this.certificatesErrors = null as unknown as WorksheetErrorDto;
    this.endorsementsErrors = null as unknown as WorksheetErrorDto;
    this.requirementsMetErrors = null as unknown as WorksheetErrorDto;

    dto.worksheetErrors.forEach(x => {
      switch (x.sheetName) {
        case IRSpreadsheetNames.Programs:
          this.programsErrors = x;
          break;
        case IRSpreadsheetNames.Certificates:
          this.certificatesErrors = x;
          break;
        case IRSpreadsheetNames.Endorsements:
          this.endorsementsErrors = x;
          break;
        case IRSpreadsheetNames.RequirementsMet:
          this.requirementsMetErrors = x;
          break;
        default:
          break;
      }
    });
  }

  getErrorsCount(worksheet: WorksheetErrorDto): string {
    return worksheet.rowErrors.map(x => x.columnErrors).length.toString() + ' Error(s) Detected';
  }


  onCompleteUpload() {
    this.showAutoAuthConfirmationModal();
  }

  showAutoAuthConfirmationModal() {
    const modalRef = this.modalService.open(UploadWarningModalComponent, {
      backdrop: 'static',
      size: 'md',
      centered: true,
    });

    modalRef.componentInstance.autoAuthorizationCount = this.spreadsheetErrorsDto.autoAuthorizationCount;
    modalRef.componentInstance.manualAuthorizationCount = this.spreadsheetErrorsDto.manualAuthorizationCount;
    modalRef.componentInstance.cohortStudentProgramsToOverwriteCount = this.spreadsheetErrorsDto.cohortStudentProgramsToOverwriteCount;

    modalRef.result.then((result) => {
      if (result) {
        this.confirmCompleteUpload();
      }
    });
  }

  confirmCompleteUpload() {
    this.loaderService.loaderOn();
    this.eppClient.completeIRUpload({ lookupKey: this.spreadsheetErrorsDto.lookupKey! }).subscribe(x => {
      this.loaderService.loaderOff();
      this.router.navigateByUrl(`epp/cohort-management/${this.cohortId}`);
      this.toastService.success('Recommendations file uploaded successfully.')
    });
  }
}

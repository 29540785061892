import { Component, OnInit } from '@angular/core';
import { EMPTY, Observable, Subject, debounceTime, filter, map, startWith, switchMap } from 'rxjs';
import { AuthService } from 'src/auth/services/auth.service';
import { AdminClient, AuthClient, AvailableEdOrgDto, EdOrgRoleSelectionDto, RoleDto } from 'src/shared/services/api.service';
import { RouteTypeService } from 'src/shared/services/route-type.service';

@Component({
  selector: 'cert-entity-configurator',
  templateUrl: './entity-configurator.component.html',
  styles: ``
})
export class EntityConfiguratorComponent implements OnInit {
  roles$!: Observable<RoleDto[]>;
  searchTerm$ = new Subject<string>();
  edOrgs$!: Observable<AvailableEdOrgDto[]>;
  testEmail!: string;

  selectedRoleIds: number[] = [];

  defaultEdOrg: AvailableEdOrgDto = {
    edOrgId: 79275,
    edOrgName: 'Arizona Department of Education'
  }

  userEdOrgs: EdOrgRoleSelectionDto[] = [{
    edOrgId: this.defaultEdOrg.edOrgId,
    roleIds: []
  }];

  minLengthTerm = 3;

  constructor(
    private authService: AuthService,
    private adminClient: AdminClient,
    private authClient: AuthClient,
    private routeTypeService: RouteTypeService) {
  }

  ngOnInit(): void {
    this.authService.isLoggedIn().pipe(switchMap(isLoggedIn => {
      if (isLoggedIn) {
        return this.routeTypeService.navigateToUserHomePage();
      } else {
        this.roles$ = this.adminClient.getRoles();
        this.edOrgs$ = this.searchTerm$.pipe(
          filter(searchTerm => {
            return searchTerm !== null && searchTerm.length >= this.minLengthTerm
          }),
          debounceTime(300), // wait for 300ms pause in typing
          switchMap(searchTerm => this.authClient.getEdOrgs({ edOrgNameSearch: searchTerm })),
          startWith([this.defaultEdOrg])
        );

        return EMPTY;
      }
    })).subscribe();
  }

  onAddEdOrg() {
    this.userEdOrgs.push({
      edOrgId: -1,
      roleIds: []
    })
  }

  onLogin() {
    this.authService.completeTestLogin(this.testEmail, this.userEdOrgs).pipe(switchMap(policySet => {
      // if we have a policy set, we're good to go.
      return policySet ? this.routeTypeService.navigateToUserHomePage() : EMPTY;
    })).subscribe();
  }
}

import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EppClient, IRUploadExpectedValuesDto } from 'src/shared/services/api.service';
import { FileService } from 'src/shared/services/file.service';

@Component({
  selector: 'epp-expected-values-modal',
  templateUrl: './expected-values-modal.component.html',
  styles: `
    .modal-body {
      max-height: 70vh;
      overflow-y: auto;
    }
  `
})
export class ExpectedValuesModalComponent {
  dataLoaded: boolean = false;
  expectedValues!: IRUploadExpectedValuesDto;

  constructor(private activeModal: NgbActiveModal, private eppClient: EppClient, private fileService: FileService) { }

  ngOnInit() {
    this.fetchExpectedValues();
  }

  fetchExpectedValues() {
    this.eppClient.getExpectedIRUploadValues().subscribe(results => {
      this.expectedValues = results;
      this.dataLoaded = true;
    });
  }

  onConfirm(confirmed: boolean): void {
    this.activeModal.close(confirmed);
  }

  onDownloadExpectedValuesWorkbook() {
    this.eppClient.downloadIRUploadExpectedValues().subscribe(fileResponse => {
      if (fileResponse?.data) {
        this.fileService.renameAndDownloadExcelFile(fileResponse, 'IR_Bulk_Upload_Expected_Values.xlsx');
      }
    })
  }
}

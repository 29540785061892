import { Component } from '@angular/core';

@Component({
  selector: 'cert-help-layout',
  templateUrl: './help-layout.component.html',
  styles: ``
})
export class HelpLayoutComponent {

}

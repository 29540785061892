<div *ngIf="isLoading" class="loader-container">
  <div class="container-fluid h-100 d-flex align-items-center justify-content-center">
    <div class="loader">
      <div class="rect1"></div>
      <div class="rect2"></div>
      <div class="rect3"></div>
      <div class="rect4"></div>
      <div class="rect5"></div>
      <p>Loading</p>
    </div>
  </div>
</div>

import { Component, Input } from '@angular/core';

@Component({
  selector: 'cert-audit-popover',
  templateUrl: './audit-popover.component.html',
  styles: ``
})
export class AuditPopoverComponent {

  @Input() modifiedBy: string | undefined;
  @Input() modifiedDate: Date | undefined;

}

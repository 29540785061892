<label for="dateAndTime" class="form-label">{{labelText}}</label>
<div class="form-group">
  <div class="input-group">
    <input id="date-input-{{name}}" class="form-control" type="text" [name]="name" [(ngModel)]="startModel"
      placeholder="mm/dd/yyyy" autocomplete="off" mask="M0/d0/0000"
      [outputTransformFn]="dateParser" [validation]="false" (ngModelChange)="updateModel()" />
    <input id="date-input-{{name}}" class="form-control" type="text" [name]="name" [(ngModel)]="endModel"
      placeholder="mm/dd/yyyy" autocomplete="off" mask="M0/d0/0000"
      [outputTransformFn]="dateParser" [validation]="false" (ngModelChange)="updateModel()" />
    <button type="button" title="Clear" class="btn btn-sm btn-outline-secondary" (click)="onClear()"><i
        class="bi bi-x-lg"></i></button>
  </div>
</div>

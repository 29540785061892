import { Injectable } from '@angular/core';
import { FileResponse } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  openFileInNewTab(fileResponse: FileResponse | null): void {
    if (fileResponse) {
      const blob = new Blob([fileResponse.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      }
    }
  }

  openPdfURLInNewTab(pdfURL: string | undefined): void {
    if (pdfURL) {
      const newTab = window.open(pdfURL, '_blank');
      if (newTab) {
        newTab.focus();
      }
    }
  }

  renameAndDownloadExcelFile(fileResponse: FileResponse, fileName: string): void {
    const blob = new Blob([fileResponse.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  }

  renameAndDownloadZipFile(fileResponse: FileResponse, fileName: string): void {
    const blob = new Blob([fileResponse.data], { type: 'application/zip' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  }

  renameAndDownloadPdfFile(fileResponse: FileResponse, fileName: string): void {
    const blob = new Blob([fileResponse.data], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  }

  renameAndDownloadPdfFileFromByteArray(pdfData: Uint8Array, fileName: string): void {
    const blob = new Blob([pdfData], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.style.display = 'none';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  }

  openFileInNewTabFromByteArray(pdfData: Uint8Array): void {
    if (pdfData) {
      const blob = new Blob([pdfData], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const newTab = window.open(url, '_blank');
      if (newTab) {
        newTab.focus();
      }
    }
  }

  public base64ToUint8Array(base64: string): Uint8Array {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return bytes;
}
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtService } from 'src/auth/services/jwt.service';
import { UserType } from 'src/shared/constants';
import { UserPolicyEvalDto } from 'src/shared/services/api.service';
import { RouteTypeService } from 'src/shared/services/route-type.service';

@Component({
  selector: 'cert-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  currentUserTypes: string[] = [];
  policySet!: UserPolicyEvalDto;
  userName!: string;

  constructor(private route: ActivatedRoute, private jwtService: JwtService, private router: Router, private routeTypeService: RouteTypeService) {
    this.policySet = this.route.snapshot.data.policySet;
  }

  ngOnInit() {
    this.jwtService.userTypes$.subscribe(x => this.currentUserTypes = x);
  }

  get hasCertUserType(): boolean {
    return this.currentUserTypes.some(type => type.toLowerCase() === UserType.Cert);
  }

  get hasLeaUserType(): boolean {
    return this.currentUserTypes.some(type => type.toLowerCase() === UserType.Lea);
  }

  get hasEppUserType(): boolean {
    return this.currentUserTypes.some(type => type.toLowerCase() === UserType.Epp);
  }

  navigateToCertHome() {
    this.router.navigateByUrl(this.routeTypeService.certHomeRoute, { replaceUrl: true });
  }

  navigateToLeaHome() {
    this.router.navigateByUrl(this.routeTypeService.leaHomeRoute);
  }

  navigateToEppHome() {
    this.router.navigateByUrl(this.routeTypeService.eppHomeRoute, { replaceUrl: true });
  }
}

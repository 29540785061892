import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EppClient } from 'src/shared/services/api.service';
import { FileService } from 'src/shared/services/file.service';
import { ExpectedValuesModalComponent } from './expected-values-modal/expected-values-modal.component';
import { ValidationRulesModalComponent } from './validation-rules-modal/validation-rules-modal.component';

@Component({
  selector: 'epp-ir-file-upload-guide',
  templateUrl: './ir-file-upload-guide.component.html',
  styles: ``
})
export class IrFileUploadGuideComponent {

  constructor(private eppClient: EppClient, private fileService: FileService, private modalService: NgbModal) { }

  onDownloadIRUploadFileTemplate() {
    this.eppClient.downloadIRUploadTemplate().subscribe(fileResponse => {
      if (fileResponse?.data) {
        this.fileService.renameAndDownloadExcelFile(fileResponse, 'IR_Bulk_Upload_Template.xlsx');
      }
    });
  }

  onViewValidationRules() {
    this.modalService.open(ValidationRulesModalComponent, {
      backdrop: 'static',
      size: 'xl',
      centered: true,
    });
  }

  onViewExpectedValues() {
    this.modalService.open(ExpectedValuesModalComponent, {
      backdrop: 'static',
      size: 'xl',
      centered: true,
    });
  }
}

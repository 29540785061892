import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[fileSystemRegex]'
})
export class FileSystemDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const formattedValue = value.replace(/[^a-zA-Z0-9_-]/g, '');

    this.ngControl.control?.setValue(formattedValue);
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'cert-is-linked-icon-renderer',
  template: `
    <i *ngIf="params.data.isLinked" certTooltip="Linked" class="link-icon-45deg"></i>
  `
})
export class IsLinkedIconRendererComponent {
  params: any;

  agInit(params: any): void {
    this.params = params;
  }
}

<div class="modal-content">
  <div class="modal-header bg-primary-100">
    <h5 class="modal-title">{{ header }}</h5>
  </div>
  <div class="modal-body">
    <p>{{ message }}</p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-secondary" (click)="onConfirm(false)">No</button>
    <button type="button" class="btn btn-primary" (click)="onConfirm(true)">Yes</button>
  </div>
</div>

import { Component, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CohortAddCohortModalComponent } from './cohort-add-cohort-modal/cohort-add-cohort-modal.component';
import { CohortsListingGridComponent } from './cohorts-listing-grid/cohorts-listing-grid.component';

@Component({
  selector: 'epp-cohorts-listing',
  templateUrl: './cohorts-listing.component.html',
  styles: ``
})
export class CohortsListingComponent {
  @ViewChild(CohortsListingGridComponent) gridComponent!: CohortsListingGridComponent;

  constructor(private modalService: NgbModal) { }

  onAddNewCohort() {
    const modalRef = this.modalService.open(CohortAddCohortModalComponent, {
      backdrop: 'static',
      size: 'md',
      centered: true,
    });

    modalRef.componentInstance.header = 'Add New Cohort';
    modalRef.componentInstance.message = `Please enter a fiscal year and description for the new cohort.`;
    modalRef.componentInstance.isUpdate = false;

    modalRef.result.then((result) => {
      if (result) {
        this.gridComponent.refreshServerSide();
      }
    });
  }

  resetFilters() {
    this.gridComponent.resetFilters();
  }
}

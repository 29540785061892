<h1 class="h2">Welcome to the Educator Preparation Program Help Page</h1>
<h2 class="h5 fw-light mt-3 mb-5">Choose the topic you need help with.</h2>
<div class="row">
  
  <!-- IR Upload Guide -->
  <div class="col-4">
    <h3 class="h4">Recommendations File Upload Integration Guide</h3>
    <div class="card">
      <div class="card-body">
        <p>The certification area is dedicated to managing all aspects of educator certification. It facilitates the storage and retrieval of certification information, and streamlines the evaluation and processing of applications and credentials.</p>
        <button class="btn btn-primary float-end" (click)="navigateToIRUploadGuide()">View</button>
      </div>
    </div>
  </div>
</div>

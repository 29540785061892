import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { EppClient, StudentSpreadsheetErrorsDto, WorksheetErrorDto } from 'src/shared/services/api.service';
import { LoaderService } from 'src/shared/services/loader.service';
import { ToastService } from 'src/shared/services/toast.service';

@Component({
  selector: 'epp-students-validation-results',
  templateUrl: './students-validation-results.component.html',
  styles: ``
})
export class StudentsValidationResultsComponent {
  @Input() validationResults$!: Observable<StudentSpreadsheetErrorsDto>;
  @Input() cohortId!: number;

  spreadsheetErrorsDto!: StudentSpreadsheetErrorsDto;
  errors!: WorksheetErrorDto;

  constructor(private modalService: NgbModal, private eppClient: EppClient,
    private router: Router, private toastService: ToastService, private loaderService: LoaderService) { }

  ngOnInit() {
    this.validationResults$.subscribe(x => {
      this.assignWorksheetErrors(x);
      this.spreadsheetErrorsDto = x;
    });
  }

  assignWorksheetErrors(dto: StudentSpreadsheetErrorsDto) {
    this.errors = null as unknown as WorksheetErrorDto;

    dto.worksheetErrors.forEach(x => {
      switch (x.sheetName) {
        default:
          this.errors = x;
          break;
      }
    });
  }

  getErrorsCount(worksheet: WorksheetErrorDto): string {
    return worksheet.rowErrors.map(x => x.columnErrors).length.toString() + ' Error(s) Detected';
  }

  onCompleteUpload() {
    this.loaderService.loaderOn();
    this.eppClient.completeStudentUpload({ lookupKey: this.spreadsheetErrorsDto.lookupKey! }).subscribe(x => {
      this.loaderService.loaderOff();
      this.router.navigateByUrl(`epp/cohort-management/${this.cohortId}`);
      this.toastService.success('Students file uploaded successfully.')
    });
  }
}

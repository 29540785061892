import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  convertToJavascriptDate(ngbDate: NgbDateStruct): Date {
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

  convertToNgbDateStruct(date: Date): NgbDateStruct {
    date = new Date(date); //converts to javascript date if not already
    const ngbDate: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    }

    return ngbDate;
  }

  getMinBirthday(): string {
    const today = new Date();

    return (today.getMonth() + 1) + '/' + today.getDate() + '/' + (today.getFullYear() - 150);
  }

  getMaxBirthday(): string {
    const today = new Date();

    return (today.getMonth() + 1) + '/' + today.getDate() + '/' + (today.getFullYear() - 15);
  }

  getPreviousDay(): string {
    const today = new Date();

    return (today.getMonth() + 1) + '/' + (today.getDate() - 1) + '/' + (today.getFullYear());
  }


  getCurrentDay(): string {
    const today = new Date();

    return (today.getMonth() + 1) + '/' + (today.getDate()) + '/' + (today.getFullYear());
  }

  isValidDate(date: string): boolean {
    const convertDate = Date.parse(date);

    if (date.length < 8 || isNaN(convertDate)) {
      return false;
    }

    return true;
  }

  getCurrentDayAndTime(): string {
    const today = new Date();

    return today.toLocaleDateString() + ' ' + today.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  }

  getDatesBetween(startDate: string, endDate: string, dates: string[]): string[] {
    return dates.filter(x => new Date(x) >= new Date(startDate) && new Date(x) <= new Date(endDate));
  }

  convertMonthYearToDate(date: string, day: number = 1) {
    const [month, year] = date.split('/');

    let transformedDate = new Date(parseInt(year), parseInt(month) - 1, day);

    return transformedDate;
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SSN'
})
export class SSNPipe implements PipeTransform {
  transform(value: string, mask: boolean = false): unknown {
    if (!value) {
      return '';
    }
    if(mask) {
      return 'XXX-XX-' + value.slice(5);
    }
    else {
      const sanitizedValue = value.replace(/-/g, '');
      return sanitizedValue.slice(0, 3) + '-' + sanitizedValue.slice(3, 5) + '-' + sanitizedValue.slice(5);
    }
  }
}

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize, Subject } from 'rxjs';
import { EppClient, StudentSpreadsheetErrorsDto, ValidateStudentsFromFileCommand } from 'src/shared/services/api.service';
import { LoaderService } from 'src/shared/services/loader.service';
import { MimeType } from 'src/shared/constants';

@Component({
  selector: 'epp-students-upload',
  templateUrl: './students-upload.component.html',
  styles: ``
})
export class StudentsUploadComponent {
  cohortId!: number;
  content!: string;
  validationResults$ = new Subject<StudentSpreadsheetErrorsDto>();
  mimeType = MimeType;

  constructor(
    private route: ActivatedRoute,
    private eppClient: EppClient,
    private router: Router,
    private loaderService: LoaderService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.cohortId = +params.get('cohortId')!;
    });
  }

  onBackToCohort() {
    this.router.navigateByUrl(`epp/cohort-management/${this.cohortId}`);
  }

  onFileSelected(content: string) {
    this.content = content;
  }

  tryValidateStudentsFile() {
    this.loaderService.loaderOn();

    const validateStudentsFromFileCommand: ValidateStudentsFromFileCommand = {
      cohortId: this.cohortId,
      content: this.content
    };

    this.eppClient.validateStudentsFromFile(validateStudentsFromFileCommand)
      .pipe(
        finalize(() => this.loaderService.loaderOff())
      )
      .subscribe(result => {
        this.validationResults$.next(result);
      });
  }
}

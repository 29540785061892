import { Component } from '@angular/core';

@Component({
  selector: 'cert-cert-nav-menu',
  templateUrl: './cert-nav-menu.component.html',
  styles: ``
})
export class CertNavMenuComponent {

}

import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[currency]'
})
export class CurrencyDirective {
  constructor(private ngControl: NgControl) {}

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    const digitsOnly = value.replace(/[^\d]/g, '');

    const formattedValue = digitsOnly.replace(/(\d+?)(\d{2})$/, '$1.$2');

    this.ngControl.control?.setValue(formattedValue);
  }
}

import { Directive, ElementRef, EventEmitter, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: 'div[validationErrors]'
})
export class ValidationErrorsDirective implements OnInit {
  @Input('for') control!: AbstractControl;
  @Input('errorEmitter') validationErrorsEvent!: EventEmitter<any>;

  private statusChangesSubscription!: Subscription;
  private validationErrorsEventSubscription!: Subscription;

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    this.updateErrors();
    this.statusChangesSubscription = this.control.statusChanges.subscribe(() => {
      this.updateErrors();
    });

    if (this.validationErrorsEvent) {
      this.validationErrorsEventSubscription = this.validationErrorsEvent.subscribe(() => {
        this.updateErrors();
      });
    }
  }

  ngOnDestroy(): void {
    this.statusChangesSubscription.unsubscribe();
    this.validationErrorsEventSubscription?.unsubscribe();
  }

  updateErrors(): void {
    const errors = this.control.errors;
    this.el.nativeElement.innerHTML = '';
    if (!Array.isArray(errors)) {
      return;
    }

    errors.forEach((error: string) => {
      const span = document.createElement('span');
      span.className = 'field-validation-error';
      span.textContent = error;
      this.control.markAsDirty();
      this.el.nativeElement.appendChild(span);
    });
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from 'src/shared/shared.module';
import { ToastContainerComponent } from './toast-container/toast-container.component';
import { ToastComponent } from './toast/toast.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AppContainerComponent } from './app-container/app-container.component';
import { AuthModule } from 'src/auth/auth.module';
import { NgxMaskPipe, NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { dateParserFormatter } from 'src/shared/date-parser-formatter';
import { AdminModule } from 'src/admin/admin.module';
import { EducatorModalComponent } from 'src/global-search/educator-modal/educator-modal.component';
import { EducatorSearchComponent } from 'src/global-search/educator-search-modal/educator-search-modal.component';
import { EducatorCreateComponent } from 'src/global-search/educator-create-modal/educator-create-modal.component';
import { EdOrgSelectionComponent } from './ed-org-selection/ed-org-selection.component';
import { RoleInfoComponent } from './role-info/role-info.component';
import { UnhandledErrorComponent } from './unhandled-error/unhandled-error.component';
import { EppNavMenuComponent } from './epp-nav-menu/epp-nav-menu.component';
import { LeaNavMenuComponent } from './lea-nav-menu/lea-nav-menu.component';
import { CertNavMenuComponent } from './cert-nav-menu/cert-nav-menu.component';
import { IssueCollectorComponent } from './nav-menu/issue-collector/issue-collector.component';
import { EmptyLandingComponent } from './empty-landing/empty-landing.component';


@NgModule({ declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        ToastContainerComponent,
        ToastComponent,
        SidebarComponent,
        AppContainerComponent,
        EducatorSearchComponent,
        EducatorModalComponent,
        EducatorCreateComponent,
        EdOrgSelectionComponent,
        RoleInfoComponent,
        UnhandledErrorComponent,
        EppNavMenuComponent,
        LeaNavMenuComponent,
        CertNavMenuComponent,
        IssueCollectorComponent,
        EmptyLandingComponent
    ],
    exports: [
        NgbModule
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        SharedModule,
        AppRoutingModule,
        NgbModule,
        AuthModule,
        NgxMaskDirective,
        NgxMaskPipe,
        AdminModule], providers: [provideNgxMask(), { provide: NgbDateParserFormatter, useClass: dateParserFormatter }, NgbActiveModal, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }

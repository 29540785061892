<ngx-skeleton-loader *ngIf="!dataLoaded" count="6" [theme]="{
  height: '7rem'
}">
</ngx-skeleton-loader>

<div class="d-flex align-items-center justify-content-between mb-2">
  <h3 class="h5 mb-2" id="educatorNotesOffCanvasLabel">History</h3>
  <span certTooltip="Toggle user notes filters">
    <i data-bs-toggle="collapse" data-bs-target="#notes-filters"
      [ngClass]="filtersActive ? 'filter-icon-fill' : 'filter-icon'" (click)="toggleFilters()" aria-expanded="false"
      aria-controls="notes-filters"></i>
  </span>

</div>
<div id="notes-filters" class="collapse" autocomplete="off">
  <div class="row">
    <div class="col">
      <p class="form-label" id="typeFilter">Type Filter</p>
      <div class="row">
        <div class="d-flex justify-content-between pt-2 ps-3">
          <div class="form-check form-switch" role="group" aria-label="typeFilter">
            <input class="form-check-input" type="checkbox" id="btnNotes" [checked]="displayNotes"
              (change)="toggleNotesDisplay()">
            <label class="form-check-label" style="padding-right: 1em;" for="btnNotes">Notes</label>
          </div>

          <div class="form-check form-switch" role="group" aria-label="typeFilter">
            <input class="form-check-input" type="checkbox" id="btnEvents" [checked]="displayEvents"
              (change)="toggleEventsDisplay()">
            <label class="form-check-label" style="padding-right: 1em;" for="btnEvents">Events</label>
          </div>

          <div class="form-check form-switch" role="group" aria-label="typeFilter">
            <input class="form-check-input" type="checkbox" id="btnAlerts" [checked]="displayAlerts"
              (change)="toggleAlertsDisplay()">
            <label class="form-check-label" style="padding-right: 1em;" for="btnAlerts">Alerts</label>
          </div>
        </div>

      </div>
    </div>
    <div class="col form-group" autocomplete="off">
      <label class="form-label" for="text-search">Text Search</label>
      <input id="text-search" name="text-search" type="text" class="form-control" [(ngModel)]="searchText"
        (keyup)="filterAuditItems()" placeholder="Search...">
    </div>
  </div>
  <div class="row">
    <div class="col">
      <cert-date-range-picker name="notes-datepicker" (onChange)="onFilterDateRangeChange()" id="range-search"
        name="range-search" [labelText]="'Date Range Filter'" [(startModel)]="startDateFilter" [(endModel)]="endDateFilter"/>
    </div>
  </div>
  <hr />
</div>

<div class="vstack gap-2 mb-2" *ngIf="dataLoaded && !!filteredEducatorAuditItems.length">
  <ng-container>
    <div *ngFor="let auditItem of filteredEducatorAuditItems" class="border border-primary-subtle rounded-2 p-3">
      <ng-container *ngTemplateOutlet="auditItem.itemType == noteTypes.EducatorNoteDto ? noteItem
        : auditItem.itemType == noteTypes.EducatorSystemEventDto ? systemEventItem
          : alertItem; context:{auditItem: auditItem}"></ng-container>
    </div>
  </ng-container>
</div>

<p *ngIf="dataLoaded && filteredEducatorAuditItems.length === 0" class="lead">There aren't any audit items for this
  educator.</p>

<ng-template #noteItem let-note="auditItem">
  <div class="row">
    <div class="col">
      <ng-container [ngSwitch]="note.contactTypeCode">
        <span *ngSwitchCase="ContactTypeCodes.None" class="badge text-bg-secondary">None</span>
        <span *ngSwitchCase="ContactTypeCodes.Phone" class="badge text-bg-secondary">Phone</span>
        <span *ngSwitchCase="ContactTypeCodes.Email" class="badge text-bg-secondary">Email</span>
        <span *ngSwitchCase="ContactTypeCodes.Counter" class="badge text-bg-secondary">Counter</span>
        <span *ngSwitchCase="ContactTypeCodes.Offsite" class="badge text-bg-secondary">Offsite</span>
        <span *ngSwitchDefault class="badge text-bg-secondary">Unknown</span>
      </ng-container>
    </div>
    <div class="col">
      <div class="row">
        <div class="col">
          <span class="fw-bold float-end">{{note.sortDate | date: 'short'}}</span>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="row">
      <div class="col">
        <span class="float-end fw-bold">{{note.createdBy}}</span>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      {{note.content}}
    </div>
  </div>
  <div requiresPolicy="canUpdateNotes" class="d-flex justify-content-end border-top mt-2 pt-2">
    <div class="btn-group">
      <div requiresPolicy="canUpdateNotes" class="me-3">
        <i certTooltip="Edit" class="edit-icon" (click)="onEdit(note)"></i>
      </div>
      <div requiresPolicy="canDeleteNotes" class="me-3">
        <i certTooltip="Delete" class="trash-icon" (click)="onDelete(note.personNoteId)"></i>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #systemEventItem let-event='auditItem'>
  <div class="row mb-2">
    <div class="col">
      <span class="badge text-bg-info">System Event</span>
    </div>
    <div class="col">
      <span class="float-end fw-bold ">{{event.sortDate | date: 'short'}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <span> {{event.eventDescription}}</span>
    </div>
    <div class="col-4">
      <span class="float-end fw-bold">{{event.createdBy}}</span>
    </div>
  </div>
</ng-template>

<ng-template #alertItem let-alert='auditItem'>
  <div class="row mb-2">
    <div class="col">
      <span class="badge text-bg-warning text-light">Person Alert</span>
    </div>
    <div class="col">
      <span class="float-end fw-bold">{{alert.sortDate | date: 'short'}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <span>{{alert.isAdd ? 'Added' : 'Removed'}} {{alert.description}}</span>
    </div>
    <div class="col-4">
      <span class="float-end fw-bold">{{alert.modifiedBy}}</span>
    </div>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { RouteType } from '../constants';
import { Router } from '@angular/router';
import { JwtService } from 'src/auth/services/jwt.service';
import { from, map, Observable, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouteTypeService {
  certHomeRoute: string = '/cert'
  leaHomeRoute: string = '/lea/roster';
  eppHomeRoute: string = '/epp/cohorts'
  userTypeSelectionRoute: string = '/user-type-selection';

  constructor(private jwtService: JwtService, private router: Router) { }

  getRouteType(): RouteType {
    const fullUrl = this.router.url;

    if (fullUrl.includes('/user-type-selection')) {
      return RouteType.Primary;
    } else if (fullUrl.includes('/cert')) {
      return RouteType.Cert;
    } else if (fullUrl.includes('/epp')) {
      return RouteType.Epp;
    } else if (fullUrl.includes('/lea')) {
      return RouteType.Lea;
    }

    return RouteType.Primary; // Default case
  }

  navigateToUserHomePage(opts?: { forceReload: boolean }): Observable<void> {
    const options = opts ?? { forceReload: false };

    return this.jwtService.userTypes$.pipe(switchMap(currentUserTypes => {
      const currentUrl = this.router.url;
      const targetRoute = this.getHomeRouteForUserType(currentUserTypes);

      if (targetRoute != currentUrl || options.forceReload) {
        return from(this.router.navigateByUrl(targetRoute)).pipe(
          map(() => undefined)
        );
      }

      return of(undefined);
    }));
  }

  navigateToEducatorManagement(personId: number): void {
    const currentUrl = this.router.url;
    const baseRoute = currentUrl.split('/')[1];

    switch (baseRoute) {
      case 'cert':
      case 'lea':
        this.router.navigateByUrl(`${baseRoute}/educators/${personId}`);
        break;
      case 'epp':
        this.router.navigateByUrl(`epp/candidates/${personId}`);
        break;
      default:
        console.error(`Unknown base route: ${baseRoute}`);
        // Handle the unknown base route case if necessary
        break;
    }
  }

  getHomeRouteForUserType(currentUserTypes: string[]) {
    const userType = (currentUserTypes.length === 0 || currentUserTypes.length > 1
        ? ''
        : currentUserTypes[0]).toLowerCase();

    switch (userType) {
      case 'cert':
        return this.certHomeRoute;
      case 'lea':
        return this.leaHomeRoute;
      case 'epp':
        return this.eppHomeRoute
      default:
        return this.userTypeSelectionRoute;
    }
  }
}


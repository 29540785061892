import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'cert-signin-redirect-callback',
  templateUrl: './signin-redirect-callback.component.html',
  styleUrls: ['./signin-redirect-callback.component.scss']
})
export class SigninRedirectCallbackComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router) {
  }

  ngOnInit() {
    this.authService.completeLogin().subscribe(policySet => {
      // if we have a policy set, we're good to go.
      if (policySet) {
        this.router.navigate(['/'], { replaceUrl: true });
      }
      else {
        console.log('policySet failed');
      }
    });
  }
}

import { AfterViewInit, Component, EventEmitter, Input, ViewChild } from '@angular/core';
import { EducatorNotesCanvasService } from 'src/educators/services/educator-notes-canvas.service';
import { Offcanvas } from 'bootstrap';
import { CodeTablesClient, ContactTypeCodeDto, AddOrUpdateEducatorNoteCommand, EducatorNoteDto, EducatorSystemEventDto, EducatorAlertDto, EducatorAuditItemsDto } from 'src/shared/services/api.service';
import { EducatorsClient } from 'src/shared/services/api.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { EducatorNotesListComponent } from './educator-notes-list/educator-notes-list.component';
import { DateService } from 'src/shared/services/date.service';
import { catchValidationErrors } from 'src/shared/utilities/validator-utilities';
import { NgForm } from '@angular/forms';
import { FormEvents } from 'src/shared/constants';
import { NotesService } from 'src/shared/services/notes.service';
import { ToastService } from 'src/shared/services/toast.service';
import { DateTimeInputComponent } from 'src/shared/date-time-input/date-time-input.component';

@Component({
  selector: 'cert-educator-notes',
  templateUrl: './educator-notes.component.html',
  styleUrls: ['./educator-notes.component.scss']
})
export class EducatorNotesComponent implements AfterViewInit {
  private canvasInstance!: Offcanvas;
  @Input() personId$!: Observable<number>;

  @ViewChild(EducatorNotesListComponent) educatorNotesListComponent!: EducatorNotesListComponent;
  @ViewChild('notesForm') form!: NgForm;
  @ViewChild(DateTimeInputComponent) dateTimeInput!: DateTimeInputComponent;

  private notes$!: Subscription;

  noteToUpdate!: EducatorNoteDto;
  isEditMode: boolean = true;

  get isAdd() {
    return !this.command?.personNoteId;
  }
  errorEmitter = new EventEmitter<any>();

  educatorNotes!: EducatorNoteDto[];
  educatorSystemEvents!: EducatorSystemEventDto[];
  educatorPersonAlerts!: EducatorAlertDto[];
  notes: EducatorAuditItemsDto[] = [];

  public command!: AddOrUpdateEducatorNoteCommand;
  contactDateAndTimeString: string = '';
  minDate!: string;
  maxDate!: string;
  currentDateAndTime = this.dateService.getCurrentDayAndTime();

  personId!: number;

  contactTypeCodes!: ContactTypeCodeDto[];

  public dataLoaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    private educatorNotesCanvasService: EducatorNotesCanvasService,
    private educatorsClient: EducatorsClient,
    private notesService: NotesService,
    private codeTablesClient: CodeTablesClient,
    private dateService: DateService,
    private toastService: ToastService) { }

  ngOnInit() {
    this.personId$.subscribe(x => {
      this.personId = x;
      this.resetCommand();
    })
    this.getContactTypeCodes();
    this.educatorNotesCanvasService.educatorNotesCanvasState.subscribe(show => show ? this.canvasInstance.show() : this.canvasInstance.hide());

    this.dataLoaded$.next(false);

    this.notesService.getNotes(this.personId); // handles the initial loading of data

    this.contactDateAndTimeString = this.currentDateAndTime;

    this.notes$ = this.notesService.notes$.subscribe(x => { // handles any changes from outside the notes component after init
      if (x) {
        this.educatorNotes = x.educatorNotes;
        this.educatorSystemEvents = x.educatorSystemEvents;
        this.educatorPersonAlerts = x.educatorAlerts;
        this.dataLoaded$.next(true);
      } else {
        this.dataLoaded$.next(false);
      }
    });
  }

  ngOnDestroy(): void {
    this.notes$.unsubscribe();
  }

  toggleEditMode() {
    this.resetForm();
    this.resetCommand();
    this.isEditMode = !this.isEditMode;
  }

  onCancelEdit() {
    this.resetForm();
    this.resetCommand();
  }

  closeNotes() {
    this.isEditMode = true;
    this.resetForm();
    this.resetCommand();
  }

  onSubmitNote() {
    const parsedDateAndTime = new Date(this.contactDateAndTimeString);
    if (isNaN(parsedDateAndTime.getTime())) {
      this.toastService.error('Invalid date or time format.');
      return;
    }

    this.command.dateAndTime = new Date(this.contactDateAndTimeString);

    const observable = this.isAdd
      ? this.educatorsClient.addEducatorNote(this.command!)
      : this.educatorsClient.updateEducatorNote(this.command!);

    observable
      .pipe(catchValidationErrors(this.form, this.errorEmitter))
      .subscribe({
        next: () => {
          this.isEditMode = true;
          this.notesService.getNotes(this.personId);
        },
        complete: () => this.resetCommand()
      });
  }

  handleFormEvent(emissionValue: FormEvents) {
    if (emissionValue == FormEvents.Successful) {
      this.notesService.getNotes(this.personId);
    }
  }



  resetCommand() {
    this.command = {
      personNoteId: undefined,
      personId: this.personId,
      content: '',
      contactTypeCodeId: undefined,
      dateAndTime: new Date()
    }
    this.contactDateAndTimeString = this.currentDateAndTime;
    this.dateService.getCurrentDayAndTime();
  }

  resetForm() {
    this.form.reset();
    this.dateTimeInput.setDefaultDateTime();
  }

  getContactTypeCodes() {
    this.codeTablesClient.getContactTypeCodes().subscribe(x => {
      this.contactTypeCodes = x;

    });
  }

  ngAfterViewInit(): void {
    this.canvasInstance = new Offcanvas('#educator-notes-offcanvas');
  }

  onEdit($event: EducatorNoteDto) {
    this.isEditMode = true;
    this.command = {
      personNoteId: $event.personNoteId,
      personId: $event.personId,
      content: $event.content,
      contactTypeCodeId: $event.contactTypeCodeId,
      dateAndTime: $event.sortDate
    }
    this.contactDateAndTimeString = $event.sortDate.toString().substring(0, 16);
    this.noteToUpdate = $event;
  }
}

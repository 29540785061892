import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EppClient, IRSpreadsheetErrorsDto, ValidateIRFileCommand } from 'src/shared/services/api.service';
import { MimeType } from 'src/shared/constants';
import { LoaderService } from 'src/shared/services/loader.service';
import { finalize, Subject } from 'rxjs';

@Component({
  selector: 'epp-recommendations-upload',
  templateUrl: './recommendations-upload.component.html',
  styles: ``
})
export class RecommendationsUploadComponent {

  cohortId!: number;
  content!: string;
  validationResults$ = new Subject<IRSpreadsheetErrorsDto>();
  mimeType = MimeType;

  constructor(
    private route: ActivatedRoute,
    private eppClient: EppClient,
    private router: Router,
    private loaderService: LoaderService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.cohortId = +params.get('cohortId')!;
    });
  }

  onBackToCohort() {
    this.router.navigateByUrl(`epp/cohort-management/${this.cohortId}`);
  }

  onFileSelected(content: string) {
    this.content = content;
  }

  tryValidateIRFile() {
    this.loaderService.loaderOn();

    const validateIRFileCommand: ValidateIRFileCommand = {
      cohortId: this.cohortId,
      file: this.content
    };

    this.eppClient.validateIRFile(validateIRFileCommand)
      .pipe(
        finalize(() => this.loaderService.loaderOff())
      )
      .subscribe(result => {
        this.validationResults$.next(result);
      });
  }
}

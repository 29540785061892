import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CertPolicies } from 'src/auth/cert-policies';
import { AuthService } from 'src/auth/services/auth.service';
import { EppClient } from 'src/shared/services/api.service';
import { LoaderService } from 'src/shared/services/loader.service';

@Component({
  selector: 'epp-cohorts-listing-actions-cell-renderer',
  templateUrl: './cohorts-listing-actions-cell-renderer.component.html',
  styles: ``
})
export class CohortsListingActionsCellRendererComponent implements ICellRendererAngularComp {
  private params: any;

  hasRemoveCohortPolicy: boolean = this.authService.hasPolicy(CertPolicies.CanDeleteCohort);
  hasUpdateCohortPolicy: boolean = this.authService.hasPolicy(CertPolicies.CanUpdateCohort);
  cohortId!: number;
  numberOfStudents!: number;

  constructor(private authService: AuthService, private eppClient: EppClient, private loaderService: LoaderService) { }

  agInit(params: any): void {
    this.params = params;
    this.cohortId = this.params.data.cohortId;
    this.numberOfStudents = this.params.data.numberOfStudents;
  }

  editCohort() {
    this.params.context.componentParent.editCohort(
      this.params.data.cohortId,
      this.params.data.description,
      this.params.data.fiscalYear
    );
  }

  removeCohort() {
    this.params.context.componentParent.removeCohort(
      this.params.data.cohortId,
      this.params.data.description,
      this.params.data.fiscalYear
    );
  }

  refresh(params: any): boolean {
    return false;
  }

  canRemoveCohort() {
    return this.hasRemoveCohortPolicy && this.numberOfStudents == 0;
  }

  exportCohort() {
    this.loaderService.loaderOn();

    this.eppClient.exportStudents(this.cohortId).subscribe(x => {
      if (x?.data) {
        const blob = new Blob([x.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');

        this.loaderService.loaderOff();
      }
    });
  }
}

import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AdminClient, PermissionDto, PermissionGroupDto, RoleDto, RolePermissionsDto } from 'src/shared/services/api.service';
import { catchValidationErrors } from 'src/shared/utilities/validator-utilities';


@Component({
  selector: 'cert-role-permission-assignment',
  templateUrl: './role-permission-assignment.component.html',
  styles: ``
})
export class RolePermissionAssignmentComponent implements OnInit {
  @ViewChild('rolePermissionsForm') form!: NgForm;

  constructor(private adminClient: AdminClient) { }

  roles: RoleDto[] = [];
  permissionGroups: PermissionGroupDto[] = [];
  permissions: PermissionDto[] = [];
  selectedRoleDescription: string | undefined = "";
  selectedRoleId!: number;

  errorEmitter = new EventEmitter<any>();

  ngOnInit(): void {
    this.permissions = [];
    this.adminClient.getRoles().subscribe(
      roles => {
        this.roles = roles.sort((a, b) => a.adeConnectRoleName.localeCompare(b.adeConnectRoleName));
      }
    );

    this.adminClient.getPermissionGroups().subscribe(
      permissionGroups => {
        this.permissionGroups = permissionGroups;

        this.permissionGroups.forEach(permissionGroup => {
          if (permissionGroup.permissions) {
            permissionGroup.permissions.forEach(permission => {
              this.permissions.push(permission);
            })
          }
        })
      }
    )
  }

  onRoleSelection(): void {
    this.setPermissionsIsSelectedToFalse();

    this.selectedRoleDescription = this.roles.find(x => x.roleId == this.selectedRoleId)?.description;

    this.adminClient.getRolePermissionsById(this.selectedRoleId).subscribe((rolePermissions: RolePermissionsDto[]) => {
      const selectedPermissionIds = rolePermissions.map(x => x.permissionId);

      this.permissions.forEach(permission => {
        permission.isSelected = selectedPermissionIds.includes(permission.permissionId);
      });
    });
  }

  resetPage() {
    this.selectedRoleId = 0;
    this.selectedRoleDescription = '';
    this.setPermissionsIsSelectedToFalse();
  }

  onSubmit() {
    const activePermissionIds: number[] = [];

    this.permissions.forEach(permission => {
      if (permission.isSelected == true) {
        activePermissionIds.push(permission.permissionId);
      }
    });

    this.adminClient.createRolePermissions({
      roleId: this.selectedRoleId,
      permissionIds: activePermissionIds
    })
      .pipe(catchValidationErrors(this.form, this.errorEmitter))
      .subscribe();
  }

  setPermissionsIsSelectedToFalse() {
    this.permissions.forEach(x => {
      x.isSelected = false;
    })
  }

  togglePermissionsIsSelected(permissionId: number) {
    this.permissions.forEach(permission => {
      if (permission.permissionId == permissionId) {
        permission.isSelected = !permission.isSelected;
      }
    })
  }
}

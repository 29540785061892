import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderSignal = new Subject<boolean>();
  loaderSignal$ = this.loaderSignal.asObservable();

  constructor() { }

  loaderOn = () => this.loaderSignal.next(true);
  loaderOff = () => this.loaderSignal.next(false);
}

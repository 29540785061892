<h1 class="h2">Welcome to the Arizona Department of Education Certification System</h1>
<h2 class="h5 fw-light mb-5">Choose the area of the system that you need to access.</h2>
<div class="row">
  @if (hasCertUserType) {
    <div class="col-4">
      <h3 class="h4">Certification</h3>
      <div class="card">
        <div class="card-body">
          <p>The certification area is dedicated to managing all aspects of educator certification. It facilitates the storage and retrieval of certification information, and streamlines the evaluation and processing of applications and credentials.</p>
          <button class="btn btn-primary float-end" (click)="navigateToCertHome()">Certification Home</button>
        </div>
      </div>
    </div>
  }
  @if (hasLeaUserType) {
    <div class="col-4">
      <h3 class="h4">LEA</h3>
      <div class="card">
        <div class="card-body">
          <p>This area serves local education agencies, providing tools to manage educator rosters. It supports the approval of professional development activities and enables the reporting of unprofessional conduct.</p>
          <button class="btn btn-primary float-end" (click)="navigateToLeaHome()">LEA Home</button>
        </div>
      </div>
    </div>
  }
  @if (hasEppUserType) {
    <div class="col-4">
      <h3 class="h4">EPP</h3>
      <div class="card">
        <div class="card-body">
          <p>Designed for educator preparation programs, this section assists in submitting certification recommendations for educators who are completing training at their respective preparation programs.</p>
          <button class="btn btn-primary float-end" (click)="navigateToEppHome()">EPP Home</button>
        </div>
      </div>
    </div>
  }
</div>

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RouteType, UserType } from 'src/shared/constants';
import { ContentType, HelpClient, HelpObjectDto, PortalType } from 'src/shared/services/api.service';
import { FileService } from 'src/shared/services/file.service';
import { RouteTypeService } from 'src/shared/services/route-type.service';

@Component({
  selector: 'cert-help-home',
  templateUrl: './help-home.component.html',
  styles: ``
})
export class HelpHomeComponent {
  portalType!: PortalType;
  helpObjects: HelpObjectDto[] = [];

  currentRouteType!: RouteType;

  UserType = UserType;
  ContentType = ContentType;
  RouteType = RouteType;

  dataLoaded: boolean = false;

  constructor(
    private routeTypeService: RouteTypeService,
    private router: Router,
    private helpClient: HelpClient,
    private fileService: FileService) { }

  ngOnInit() {
    this.currentRouteType = this.routeTypeService.getRouteType();
    if (this.currentRouteType) {
      this.dataLoaded = true;
      this.getHelpObjectsByPortal();
    }
  }

  mapUserTypesToPortalType() {
    switch (this.currentRouteType) {
      case RouteType.Cert: {
        this.portalType = PortalType.Cert;
        break;
      }
      case RouteType.Lea: {
        this.portalType = PortalType.LEA;
        break;
      }
      case RouteType.Epp: {
        this.portalType = PortalType.EPP;
        break;
      }
      default: {
        break;
      }
    }
  }

  getHelpObjectsByPortal() {
    this.mapUserTypesToPortalType();
    this.helpClient.getHelpObjectsByPortal(this.portalType).subscribe(results => {
      this.helpObjects = results;
      this.dataLoaded = true;
    });
  }

  navigateToIRUploadGuide() {
    this.router.navigateByUrl('/epp/help/ir-upload-guide');
  }

  onViewContent(helpObject: HelpObjectDto) {
    if (helpObject.contentType === ContentType.PDF) {
      const byteArray = this.fileService.base64ToUint8Array(helpObject.content!);
      this.fileService.openFileInNewTabFromByteArray(byteArray);
    }
    else if (helpObject.contentType === ContentType.Link) {
      window.open(helpObject.externalUri, '_blank');
    }
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { dateParser } from '../services/mask-date-parser';
import { timeParser } from '../services/time-parser.service';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'cert-date-time-input',
  templateUrl: './date-time-input.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styleUrl: './date-time-input.component.scss'
})
export class DateTimeInputComponent {
  @Input() model!: string | undefined;
  @Input() name: string = '';
  @Input() propertyName: string = '';
  @Input() isDisabled: boolean = false;

  modelDate: string | undefined;
  modelTime: string | undefined;
  modelAmPm: string | undefined;

  @Output() modelChange = new EventEmitter<string>();

  errorEmitter = new EventEmitter<any>();

  dateParser = dateParser;
  timeParser = timeParser;

  ngOnInit() {
    this.setDefaultDateTime();
  }

  setDefaultDateTime() {
    setTimeout(() => {
      const currentDate = new Date();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const day = currentDate.getDate().toString().padStart(2, '0');
      const year = currentDate.getFullYear();
      this.modelDate = `${month}/${day}/${year}`;

      const hours = currentDate.getHours();
      const minutes = currentDate.getMinutes().toString().padStart(2, '0');
      this.modelAmPm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = ((hours + 11) % 12 + 1).toString().padStart(2, '0'); // Convert 24h to 12h format
      this.modelTime = `${formattedHours}:${minutes}`;

      this.modelChange.emit(this.modelDate + ' ' + this.modelTime + ' ' + this.modelAmPm);
    });
  }

  modelChanged() {
    if (!this.modelDate || !this.modelTime || !this.modelAmPm) {
      return;
    }

    // Format modelTime to ensure it's in "HH:MM" format
    let formattedModelTime = this.modelTime;
    if (!formattedModelTime.includes(':')) {
      // Assuming the input is like "933" for 9:33
      if (formattedModelTime.length === 3) {
        formattedModelTime = `0${formattedModelTime.substring(0, 1)}:${formattedModelTime.substring(1)}`;
      } else if (formattedModelTime.length === 4) {
        formattedModelTime = `${formattedModelTime.substring(0, 2)}:${formattedModelTime.substring(2)}`;
      }
    }

    let [hours, minutes] = formattedModelTime.split(':').map(num => parseInt(num));
    if (this.modelAmPm === 'PM' && hours < 12) {
      hours += 12;
    } else if (this.modelAmPm === 'AM' && hours === 12) {
      hours = 0;
    }

    const combinedDateTime = `${this.modelDate.split('T')[0]}T${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
    const parsedDate = new Date(combinedDateTime);

    if (!isNaN(parsedDate.getTime())) {
      const dateTimeString = parsedDate.toISOString();
      this.modelChange.emit(dateTimeString);
    } else {
      console.error('Invalid date or time format.');
    }
  }

  get dateName() {
    return `${this.name}-date-input`;
  }

  get timeName() {
    return `${this.name}-time-input`;
  }
}

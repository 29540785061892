import { Directive, ElementRef, Input, OnChanges } from '@angular/core';
import { Tooltip } from 'bootstrap';
import { MarkdownService } from 'ngx-markdown';

@Directive({
  selector: '[certTooltip]'
})
export class TooltipDirective implements OnChanges {
  private tooltipInstance: Tooltip | null = null;

  @Input('certTooltip') tooltipText!: string;
  @Input() enableTooltip: boolean = true;

  constructor(private el: ElementRef, private markdownService: MarkdownService) {
    markdownService.renderer.paragraph = (text) => `<div>${text}</div>`;
  }

  ngOnChanges() {
    if (this.enableTooltip) {
      this.tooltipInstance = new Tooltip(this.el.nativeElement, {
        title: this.markdownService.parse(this.tooltipText) as string,
        html: true
      });
    }
    else if (this.tooltipInstance) {
      this.tooltipInstance.dispose();
      this.tooltipInstance = null;
    }
  }
  ngOnDestroy() {
    if (this.tooltipInstance) {
      this.tooltipInstance.dispose();
      this.tooltipInstance = null;
    }
  }
}

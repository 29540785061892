import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FileResponse } from '../services/api.service';
import { Observable } from 'rxjs';
import { FileService } from '../services/file.service';

@Component({
  selector: 'cert-document-viewer',
  templateUrl: './document-viewer.component.html',
  styles: ``
})
export class DocumentViewerComponent {
  @Input() document$!: Observable<FileResponse>;
  @Output() nextDocument = new EventEmitter();
  @Output() previousDocument = new EventEmitter();

  constructor(private fileService: FileService) {}

  pageNum: number = 1;
  document!: FileResponse;
  pdfBlob!: Blob;
  pdfDataUrl!: string;
  documentAngle: 0 | 90 | 180 | 270 = 0;

  zoomLevel: number | string = 80;
  private zoomMin = 10;
  private zoomMax = 160;

  ngOnInit() {
    this.document$.subscribe(x => {
      this.document = x;
      this.pdfBlob = new Blob([x.data], { type: 'application/pdf' });
      this.pdfDataUrl = URL.createObjectURL(this.pdfBlob);
    });
  }

  onViewDocumentInNewTab() {
    this.fileService.openPdfURLInNewTab(this.pdfDataUrl);
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'ArrowLeft') {
      this.onPreviousDocument();
    } else if (event.key === 'ArrowRight') {
      this.onNextDocument();
    } else if (event.ctrlKey && event.key === 'o') {
      this.onViewDocumentInNewTab();
    }
  }

  onRotate() {
    const angles = [0, 90, 180, 270];
    const currentIndex = angles.indexOf(this.documentAngle);
    this.documentAngle = angles[(currentIndex + 1) % angles.length] as 0 | 90 | 180 | 270;
  }

  onZoomOut() {
    let zLevel = this.zoomLevel as number;

    if (zLevel > this.zoomMin) {
      zLevel -= 20;
      this.zoomLevel = zLevel;
    }
  }

  onZoomIn() {
    let zLevel = this.zoomLevel as number;

    if (zLevel < this.zoomMax) {
      zLevel += 20;
      this.zoomLevel = zLevel;
    }
  }

  onNextDocument() {
    this.nextDocument.emit();
  }

  onPreviousDocument() {
    this.previousDocument.emit();
  }
}

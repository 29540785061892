import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, combineLatest, Observable, switchMap, tap } from 'rxjs';
import { EducatorNotesCanvasService } from 'src/educators/services/educator-notes-canvas.service';
import { FingerprintCardValidityStatus } from 'src/shared/constants';
import { EducatorsClient, EducatorHeaderDto, DeletePersonAlertCommand, GetAddressWindowDocumentCommand, ProfessionalDevelopmentRequirementState } from 'src/shared/services/api.service';
import { AddAlertModalComponent } from './add-alert-modal/add-alert-modal.component';
import { ConfirmationModalComponent } from 'src/shared/confirmation-modal/confirmation-modal.component';
import { NotesService } from '../services/notes.service';

@Component({
  selector: 'cert-educator-header [personId\\$]',
  templateUrl: './educator-header.component.html',
  styleUrls: ['./educator-header.component.scss']
})
export class EducatorHeaderComponent {
  constructor(
    private educatorsClient: EducatorsClient,
    private viewportScroller: ViewportScroller,
    private educatorNotesCanvasService: EducatorNotesCanvasService,
    private router: Router,
    private modalService: NgbModal,
    private notesService: NotesService) { }

  @Output() refreshHeaderInfo: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild('printFrame', { static: false }) printFrame!: ElementRef;
  @Input() personId$!: Observable<number>;
  @Input() educatorAddressExists!: boolean;
  ProfessionalDevelopmentRequirementState = ProfessionalDevelopmentRequirementState;
  headerInfoRefresh$ = new BehaviorSubject<void>(undefined);
  personId!: number;
  dataLoaded: boolean = false;
  educatorDetail!: EducatorHeaderDto;
  educatorDetail$!: Observable<EducatorHeaderDto>;
  fingerprintCardValidityStatus = FingerprintCardValidityStatus;
  fingerprintIsNew: boolean = true;
  loadingAddressWindowTab: boolean = false;

  get educatorCurrentEmployment() {
    return this.educatorDetail.currentEmploymentInfo == null
      ? 'N/A'
      : `${this.educatorDetail.currentEmploymentInfo.lea} (${this.educatorDetail.currentEmploymentInfo.school})`;
  }

  ngOnInit() {
    combineLatest([this.personId$, this.headerInfoRefresh$])
      .pipe(
        switchMap(([personId, _]) => {
          this.personId = personId;
          return this.educatorsClient.getEducatorManagementHeaderInfo(personId);
        })
      )
      .subscribe(x => {
        this.educatorDetail = x
        this.dataLoaded = true;
      });
  }

  openEducatorNotesCanvas() {
    this.educatorNotesCanvasService.openCanvas();
  }

  ngAfterViewInit() {
    const fontSize = window?.getComputedStyle(document.body).getPropertyValue('font-size');

    if (fontSize) {
      const headerHeight = Number(fontSize.match(/\d+/)) * 3;
      this.viewportScroller.setOffset([0, headerHeight]);
    }

  }

  openAddAlertModal() {
    const modalRef = this.modalService.open(AddAlertModalComponent, {
      backdrop: 'static',
      size: 'sm',
      centered: true,
    });

    modalRef.componentInstance.personId = this.personId;

    modalRef.result.then(() => {
      this.headerInfoRefresh$.next();
      this.notesService.getNotes(this.personId);
    });
  }

  openDeleteAlertModal(personAlertId: number) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      backdrop: 'static',
      size: 'sm',
      centered: true,
    });

    modalRef.componentInstance.header = 'Delete Alert';
    modalRef.componentInstance.message = 'Are you sure you would like to delete this alert? This action cannot be undone.';

    modalRef.result.then((result) => {
      if (result) {
        const command: DeletePersonAlertCommand = {
          personId: this.personId,
          personAlertId: personAlertId
        };

        this.educatorsClient.deletePersonAlert(command).subscribe({
          next: () => {
            this.headerInfoRefresh$.next();
            this.notesService.getNotes(this.personId);
          }
        });
      }
    });
  }

  badgeClassWhenNotOnFile() {
    return {
      'badge-warning': this.educatorDetail.pendingIVPCardExists,
      'badge-danger': !this.educatorDetail.pendingIVPCardExists,
    };
  }

  navigateToEducatorManagement(event: Event) {
    event.preventDefault();
    this.router.navigateByUrl(`/cert/educators/${this.personId}`);
  }

  printAddressWindow() {
    let command: GetAddressWindowDocumentCommand = {
      personId: this.personId
    }
    this.loadingAddressWindowTab = true;

    this.educatorsClient.getAddressWindowDocument(command).subscribe(fileResponse => {
      if (fileResponse && this.printFrame?.nativeElement) {
        const url = URL.createObjectURL(fileResponse.data);
        const iframe = this.printFrame.nativeElement;
        iframe.onload = () => {
          iframe.contentWindow.print();
        };
        iframe.src = url;
        this.loadingAddressWindowTab = false;
      }
    });
  }
}

<ul class="navbar-nav flex-grow">
  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" [routerLink]="['/cert']" [routerLinkActive]="['link-active']" role="button"
      data-bs-toggle="dropdown" aria-expanded="false">Service Items</a>
    <ul class="dropdown-menu">
      <li>
        <a requiresPolicy="canManageAdminQueue" class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/services/service-items-admin']">
          Service Items Admin</a>
      </li>
      <li>
        <a requiresPolicy="canSelfAssignServiceItems" class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/services']">My Service
          Items</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/services/unassigned-services']">Unassigned Service Items</a>
      </li>
    </ul>
  </li>

  <li class="nav-item dropdown" requiresPolicy="canViewPersons">
    <a class="nav-link dropdown-toggle" [routerLink]="['/cert/educators']" [routerLinkActive]="['link-active']"
      role="button" data-bs-toggle="dropdown" aria-expanded="false">Educators</a>
    <ul class="dropdown-menu">
      <li requiresPolicy="canReviewDocuments">
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/educators/document-review']">Document Review</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/educators/print-queue']">Print Queue</a>
      <li requiresPolicy="canCreatePrintJobs">
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/educators/print-jobs']">Print Jobs</a>
      </li>
    </ul>
  </li>

  <li class="nav-item dropdown" requiresPolicy="canEnterPaymentReversal">
    <a class="nav-link dropdown-toggle" [routerLink]="['/cert']" [routerLinkActive]="['link-active']" role="button"
      data-bs-toggle="dropdown" aria-expanded="false">Payments</a>
    <ul class="dropdown-menu">
      <li requiresPolicy="canEnterPaymentReversal">
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/payments/payment-reversals']">Add Payment Reversal</a>
      </li>
      <li requiresPolicy="canViewPaymentReversalReport">
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/payments/reversal-report']">Payment Reversals Work Queue</a>
      </li>
      <li requiresPolicy="canProcessRefunds">
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/payments/refunds']">Refunds Work Queue</a>
      </li>
      <li requiresPolicy="canViewCollectedPaymentsReport">
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/payments/collected-report']">Collected Payments Report</a>
      </li>
    </ul>
  </li>

  <li class="nav-item dropdown" requiresPolicy="canViewDiscipline">
    <a class="nav-link dropdown-toggle" [routerLink]="['/cert']" [routerLinkActive]="['link-active']" role="button"
      data-bs-toggle="dropdown" aria-expanded="false">Discipline</a>
    <ul class="dropdown-menu">
      <li requiresPolicy="canViewNasdtecEntries">
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/discipline/nasdtec-matches']">NASDTEC Matches</a>
      </li>
      <li requiresPolicy="canViewInvestigativeAlerts">
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/discipline/iu-fingerprint-cards-report']">IU Fingerprint Cards Report</a>
      </li>
    </ul>
  </li>

  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" [routerLink]="['/cert']" [routerLinkActive]="['link-active']" role="button"
      data-bs-toggle="dropdown" aria-expanded="false">Admin</a>

    <ul class="dropdown-menu dropdown-menu-left">
      <li [routerLinkActive]="['link-active']" requiresPolicy="canMapRolePermissions">
        <a class="dropdown-item" [routerLink]="['/cert/admin']">Manage Role Permissions</a>
      </li>
      <li [routerLinkActive]="['link-active']" requiresPolicy="canManageHangfire">
        <a class="dropdown-item" href="/hangfire">Manage Background Jobs</a>
      </li>
      <li [routerLinkActive]="['link-active']" requiresPolicy="canManageHelpObjects">
        <a class="dropdown-item" [routerLink]="['/cert/admin/help']">Manage Help Objects</a>
      </li>
    </ul>
  </li>

  <li class="nav-item dropdown">
    <a class="nav-link dropdown-toggle" [routerLink]="['/cert/lab']" [routerLinkActive]="['link-active']"
      role="button" data-bs-toggle="dropdown" aria-expanded="false">Lab</a>
    <ul class="dropdown-menu dropdown-menu-left">
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']" [routerLink]="['/cert//lab']">Style Guide</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']" [routerLink]="['/cert/lab/user-list']">User
          List</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']" [routerLink]="['/cert/lab/ag-grid']">AG
          Grid</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/lab/data-dictionary']">Data Dictionary</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']" [routerLink]="['/cert/lab/pdf-test']">Test PDF
          Generation</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/lab/evaluation-tool']">Evaluation Tool</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/lab/qualification-path-tool']">Qualification Path Tool</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/lab/scanner']">Scanner</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']" [routerLink]="['/cert/lab/case-iq']">CaseIQ</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/lab/nasdtec']">NASDTEC</a>
      </li>
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/cert/lab/document-verification']">Verify Document</a>
      </li>
    </ul>
  </li>

  <li class="nav-item">
    <a class="nav-link clickable" [routerLink]="['/cert/help']" [routerLinkActive]="['link-active']"
      role="button" aria-expanded="false">Help</a>
  </li>
</ul>


import { Component } from '@angular/core';
import { ToastConfig, ToastService } from 'src/shared/services/toast.service';

@Component({
  selector: 'cert-toast-container',
  templateUrl: './toast-container.component.html',
  styles: ``
})
export class ToastContainerComponent {
  toasts: ToastConfig[] = [];

  constructor(toastService: ToastService) {
    toastService.toasts$.subscribe({
      next: x => {
        this.toasts.push(x);
      }
    })
  }
}

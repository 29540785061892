<div class="modal-content">
  <div class="modal-header bg-primary-100">
    <h5 class="modal-title">Add Alert</h5>
  </div>
  <form #addAlertForm="ngForm">
    <div class="modal-body">
      <div class="form-group">
        <label for="alertCodeId" class="mb-1">Alert Code</label>
        <ng-select #alertCodeId="ngModel" name="alertCodeId" id="alertCodeId" [(ngModel)]="command.alertCodeId">
          <ng-option *ngFor="let alertCode of alertCodes$ | async" [value]="alertCode.alertCodeId" placeholder="test">
            {{alertCode.description}}</ng-option>
        </ng-select>
        <div validationErrors [for]="alertCodeId.control" [errorEmitter]="errorEmitter"></div>
      </div>
      <div class="form-group">
        <label for="alertCode" class="mb-1">Additional Information</label>
        <input type="text" name="additionalInformation" id="additionalInformation" class="form-control" [(ngModel)]="command.additionalInformation" />
      </div>
    </div>
    <div class="modal-footer d-flex">
      <button type="button" class="btn btn-primary" (click)="onSubmit()">Submit</button>
      <button type="button" class="btn btn-secondary" (click)="onClose()">Close</button>
    </div>
  </form>
</div>

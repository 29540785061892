import { Injectable } from '@angular/core';
import { ConfigClient, FeatureManagement } from './api.service';
import { BehaviorSubject, Observable, combineLatest, filter, map, take, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureManagementService {
  private featureSubject = new BehaviorSubject<FeatureManagement | undefined>(undefined);
  private features$ = this.featureSubject.asObservable();

  isInformationObscurityActiveSubject = new BehaviorSubject<boolean>(true);
  isInformationObscurityActive$ = this.isInformationObscurityActiveSubject.asObservable();

  initialFeatureState!: FeatureManagement;

  constructor(configClient: ConfigClient) {
    configClient.featureConfig().subscribe(featureState => {
      this.featureSubject.next(featureState);
      this.initialFeatureState = JSON.parse(JSON.stringify(featureState));
    });
  }

  get features(): Observable<FeatureManagement> {
    return combineLatest([this.features$, this.isInformationObscurityActive$]).pipe(
      filter(([features, _]) => !!features),
      map(([features, isInformationObscurityActive]) => {
        if (features) {
          features.informationObscurity = this.initialFeatureState.informationObscurity && isInformationObscurityActive;
        }
        return features;
      })) as Observable<FeatureManagement>;
  }

  isFeatureEnabled(featureName: keyof FeatureManagement): Observable<boolean> {
    return this.features$.pipe(
      map(config => config ? config[featureName] : false)
    );
  }

  toggleInformationObscurityActiveState() {
    this.isInformationObscurityActiveSubject.next(!this.isInformationObscurityActiveSubject.getValue());
  }
}

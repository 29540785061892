import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JwtService } from 'src/auth/services/jwt.service';

@Component({
  selector: 'cert-role-info',
  templateUrl: './role-info.component.html',
  styles: ``
})
export class RoleInfoComponent {
  currentEdOrgName?: string;
  currentRoles: string[];
  currentUserTypes: string[] = [];

  constructor(private jwtService: JwtService, private activeModal: NgbActiveModal) {
    this.currentEdOrgName = jwtService.currentEdOrgName;
    this.currentRoles = jwtService.roles;
  }

  ngOnInit() {
    this.jwtService.userTypes$.subscribe(x => this.currentUserTypes = x);
  }
  close() {
    this.activeModal.dismiss();
  }
}

import { Component } from '@angular/core';
import { AuthService } from 'src/auth/services/auth.service';
import { LocalStorageService } from 'src/shared/services/local-storage.service';
import { PolicySetKey, RouteType, UserType } from 'src/shared/constants';
import { AuthClient, AvailableEdOrgDto, UserPolicyEvalDto } from 'src/shared/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EducatorModalComponent } from 'src/global-search/educator-modal/educator-modal.component';
import { CertPolicies } from 'src/auth/cert-policies';
import { EdOrgSelectionComponent } from '../ed-org-selection/ed-org-selection.component';
import { BehaviorSubject, EMPTY, filter, from, switchMap } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { RoleInfoComponent } from '../role-info/role-info.component';
import { RouteTypeService } from 'src/shared/services/route-type.service';
import { JwtService } from 'src/auth/services/jwt.service';
import { ConfigService } from 'src/shared/services/config.service';
import { FeatureManagementService } from 'src/shared/services/feature-management.service';

@Component({
  selector: 'cert-nav-menu',
  templateUrl: './nav-menu.component.html',
  styles: ``
})
export class NavMenuComponent {
  isExpanded = false;
  policySet = JSON.parse(this.localStorageService.get(PolicySetKey) ?? '{}') as UserPolicyEvalDto;
  canSelfAssignServiceItems: boolean = this.authService.hasPolicy(CertPolicies.CanSelfAssignServiceItems);
  edOrgOptions: AvailableEdOrgDto[] = [];
  currentRouteType!: RouteType;
  currentEdOrgName?: string;
  currentEdOrgId?: number;
  fiscalYearDescription?: string;
  routeTypes = RouteType;
  homeUrl!: string;
  userTypeRoutes: { userType: UserType, route: string, description: string }[] = [];
  edOrgOptionsRefresh$ = new BehaviorSubject<void>(undefined);
  dataLoaded: boolean = false;
  obscurityFeatureEnabled!: boolean;
  obscurityFeatureActive!: boolean;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private localStorageService: LocalStorageService,
    private router: Router,
    private authClient: AuthClient,
    private routeTypeService: RouteTypeService,
    private jwtService: JwtService,
    configService: ConfigService,
    private featureManager: FeatureManagementService) {
    this.currentEdOrgName = jwtService.currentEdOrgName;
    this.currentEdOrgId = jwtService.currentEdOrgId;
    this.fiscalYearDescription = configService.fiscalYearDescription;
  }

  ngOnInit() {
    this.featureManager.isFeatureEnabled('informationObscurity').subscribe(isEnabled => {
      this.obscurityFeatureEnabled = isEnabled;
      this.obscurityFeatureActive = isEnabled;
    });

    this.getEdOrgOptions();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.currentRouteType = this.routeTypeService.getRouteType();
      if (this.currentRouteType) {
        this.dataLoaded = true;
      }
    });

    this.jwtService.userTypes$.subscribe(u => this.updateUserTypes(u));
    this.currentRouteType = this.routeTypeService.getRouteType();
    if (this.currentRouteType) {
      this.dataLoaded = true;
    }
  }

  getEdOrgOptions() {
    this.edOrgOptionsRefresh$.pipe(switchMap(() => {
      return this.authClient.getAvailableEdOrgs();
    })).subscribe(x => {
      this.edOrgOptions = x;
    });
  }

  updateUserTypes(currentUserTypes: string[]): void {
    this.homeUrl = this.routeTypeService.getHomeRouteForUserType(currentUserTypes);

    this.userTypeRoutes = [
      { userType: UserType.Cert, route: this.routeTypeService.certHomeRoute, description: 'Certification' },
      { userType: UserType.Lea, route: this.routeTypeService.leaHomeRoute, description: 'LEA' },
      { userType: UserType.Epp, route: this.routeTypeService.eppHomeRoute, description: 'EPP' }
    ].filter(x => currentUserTypes.some(t => t.toLowerCase() === x.userType.toLowerCase()))
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  get canChangeEdOrg() {
    return this.edOrgOptions.length > 0;
  }

  onChangeEdOrg(event: Event) {
    event.preventDefault();
    const modalRef = this.modalService.open(EdOrgSelectionComponent, { backdrop: 'static', size: 'lg', centered: true });

    const policySet$ = from(modalRef.result).pipe(switchMap(x => {
      const edOrgId = x as number;
      return this.authService.changeEdOrg(edOrgId);
    }));

    policySet$.pipe(switchMap(policySet => {
      if (!policySet) {
        return EMPTY;
      }

      return this.routeTypeService.navigateToUserHomePage({
        forceReload: true
      });
    })).subscribe();
  }

  onViewProfile(event: Event) {
    event.preventDefault();
    this.modalService.open(RoleInfoComponent, { backdrop: 'static', size: 'lg', centered: true });
  }

  logout(event: Event) {
    event.preventDefault();

    this.authService.logout();
  }

  openEducatorSearch() {
    const modalRef = this.modalService.open(EducatorModalComponent, { backdrop: 'static', size: 'xl', centered: true });

    modalRef.result
      .then((result) => {
      })
      .catch((error) => {
      });
  }

  onToggleObscurity(event: Event) {
    event.preventDefault();
    this.featureManager.toggleInformationObscurityActiveState();
    this.featureManager.features.subscribe(features => {
      this.obscurityFeatureActive = features.informationObscurity;
    });
  }

  showEducatorSearch() {
    return [RouteType.Cert, RouteType.Lea, RouteType.Epp].some(x => x === this.currentRouteType);
  }
}

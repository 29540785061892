import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from 'src/shared/confirmation-modal/confirmation-modal.component';
import { ContentType, HelpClient, HelpObjectDto, PortalType } from 'src/shared/services/api.service';
import { FileService } from 'src/shared/services/file.service';
import { LoaderService } from 'src/shared/services/loader.service';

@Component({
  selector: 'cert-help-results',
  templateUrl: './help-results.component.html',
  styles: ``
})
export class HelpResultsComponent {
  @Input() helpObjects!: HelpObjectDto[];
  @Output() helpFormUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  ContentType = ContentType;
  PortalType = PortalType;

  constructor(
    private helpClient: HelpClient,
    private fileService: FileService,
    private modalService: NgbModal,
    private loaderService: LoaderService) { }

  deleteHelpObject(helpObjectId: number): void {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      backdrop: 'static',
      size: 'md',
      centered: true,
    });

    modalRef.componentInstance.header = 'Delete Help Object';
    modalRef.componentInstance.message = `Are you sure you want to delete this help object? This action can't be undone.`;

    modalRef.result.then((result) => {
      if (result) {
        this.confirmDeleteHelpObject(helpObjectId);
      }
    });
  }

  confirmDeleteHelpObject(helpObjectId: number) {
    this.loaderService.loaderOn();
    this.helpClient.deleteHelpObject({ helpObjectId }).subscribe({
      next: () => {
        this.helpFormUpdate.emit(true);
      },
      complete: () => this.loaderService.loaderOff()
    });
  }

  onViewContent(helpObject: HelpObjectDto) {
    if (helpObject.contentType === ContentType.PDF) {
      const byteArray = this.fileService.base64ToUint8Array(helpObject.content!);
      this.fileService.openFileInNewTabFromByteArray(byteArray);
    }
    else if (helpObject.contentType === ContentType.Link) {
      window.open(helpObject.externalUri, '_blank');
    }
  }
}

<div class="modal-content">
  <div class="modal-header bg-primary-100">
    <h5 class="modal-title">Recommendations File Upload Validation Rules</h5>
  </div>
  <div class="modal-body">
    <ul class="nav nav-tabs" id="validationRulesTabs" role="tablist">
      <li class="nav-item h5">
        <a class="nav-link active clickable" id="tab-programs" data-bs-toggle="tab" data-bs-target="#content-programs"
          role="tab" aria-controls="content-programs" aria-selected="true">
          Programs
        </a>
      </li>
      <li class="nav-item h5">
        <a class="nav-link clickable" id="tab-certificates" data-bs-toggle="tab" data-bs-target="#content-certificates"
          role="tab" aria-controls="content-certificates" aria-selected="false">
          Certificates
        </a>
      </li>
      <li class="nav-item h5">
        <a class="nav-link clickable" id="tab-endorsements" data-bs-toggle="tab" data-bs-target="#content-endorsements"
          role="tab" aria-controls="content-endorsements" aria-selected="false">
          Endorsements
        </a>
      </li>
      <li class="nav-item h5">
        <a class="nav-link clickable" id="tab-requirements-met" data-bs-toggle="tab"
          data-bs-target="#content-requirements-met" role="tab" aria-controls="content-requirements-met"
          aria-selected="false">
          Requirements Met
        </a>
      </li>
    </ul>

    <!-- Tabs Content -->
    <div class="tab-content" id="validationRulesContent">
      <!-- Programs Tab Content -->
      <div class="tab-pane fade show active" id="content-programs" role="tabpanel" aria-labelledby="tab-programs">
        <div class="mt-2">
          <ul class="list-group">
            <li class="list-group-item pb-0 mt-1" markdown>**Student/Program Identity Info** must be provided on each row.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If `EIN` isn't provided, `LastName`, `DateOfBirth`, `LastFourSSN` must be provided.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If provided, `EIN` must be a seven-digit number.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If provided, `LastFourSSN` must be a four-digit number.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If provided, `DateOfBirth` must be a valid date in the *mm/dd/yyyy* format.</li>
            <li class="list-group-item pb-0 mt-1" markdown>An educator record must be found in the certification system using the `EIN` OR a
              combination of `LastName`, `DateOfBirth`, and `LastFourSSN`.</li>
            <li class="list-group-item pb-0 mt-1" markdown>`ProgramCode` must be provided.</li>
            <li class="list-group-item pb-0 mt-1" markdown>A program must be found in the certification system using the provided
              `ProgramCode`. This program must also belong to the institution that is uploading the spreadsheet.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If provided, `CompletionDate` must be a valid date in the *mm/dd/yyyy* format.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If `AutoAuthorize` is **Y**, `CompletionDate` must be provided.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If `AutoAuthorize` is **Y**, the logged in user must have the "Epp Authorized Signer" role.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If `ATA` is provided, it must be one of **Y** or **N**.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If `ATA` is provided, the uploading institution must be allowed to award ATA
              scholarships.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If any of `DegreeLevel`, `DegreeType`, `DegreeMajor`, or `AwardDate` is provided, then
              each of these values must be provided as it's the full definition of the degree information.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If provided, `DegreeLevel` must match a degree level found in the certification
              system.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If provided, `DegreeType` must match a degree type found in the certification
              system.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If provided, `DegreeMajor` must match a major type found in the certification
              system.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If provided, `AwardDate` must be a valid date in the *mm/dd/yyyy* format.</li>
            <li class="list-group-item pb-0 mt-1" markdown>`AutoAuthorize` must be one of **Y** or **N**.</li>
          </ul>
        </div>
      </div>

      <!-- Certificates Tab Content -->
      <div class="tab-pane fade" id="content-certificates" role="tabpanel" aria-labelledby="tab-certificates">
        <div class="mt-2">
          <ul class="list-group">
            <li class="list-group-item pb-0 mt-1" markdown>**Student/Program Identity Info** must be provided on each row.</li>
            <li class="list-group-item pb-0 mt-1" markdown>The **Student/Program Identity Info** must match a record found on the Programs worksheet.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If `AutoAuthorize` is **Y**, at least one certificate row is required for a **Student/Program Identity Info** combination found on the Programs Worksheet.</li>
            <li class="list-group-item pb-0 mt-1" markdown>`CertificateType` must be provided, found in the certification system, and the institution must be allowed to recommend the certificate in the related program.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If the provided `CertificateType` requires an area, `ApprovedArea` must be provided.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If provided, `ApprovedArea` must be found in the certification system and the institution must be allowed to recommend that area in the related program.</li>
          </ul>
        </div>
      </div>

      <!-- Endorsements Tab Content -->
      <div class="tab-pane fade" id="content-endorsements" role="tabpanel" aria-labelledby="tab-endorsements">
        <div class="mt-2">
          <ul class="list-group">
            <li class="list-group-item pb-0 mt-1" markdown>**Student/Program Identity Info** must be provided on each row.</li>
            <li class="list-group-item pb-0 mt-1" markdown>The **Student/Program Identity Info** must match a record found on the Programs worksheet.</li>
            <li class="list-group-item pb-0 mt-1" markdown>`Endorsement` must be provided, found in the certification system, and the institution must be allowed to recommend the endorsement in the related program.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If the provided `Endorsement` requires a language, `Language` must be provided.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If provided, `Language` must be found in the certification system.</li>
          </ul>
          <div class="alert alert-info mt-3 mb-0" role="alert">Currently, the only allowed language is <span class="fw-bold">Spanish</span>.</div>
        </div>
      </div>

      <!-- Requirements Met Tab Content -->
      <div class="tab-pane fade" id="content-requirements-met" role="tabpanel" aria-labelledby="tab-requirements-met">
        <div class="mt-2">
          <ul class="list-group">
            <li class="list-group-item pb-0 mt-1" markdown>**Student/Program Identity Info** must be provided on each row.</li>
            <li class="list-group-item pb-0 mt-1" markdown>The **Student/Program Identity Info** must match a record found on the Programs worksheet.</li>
            <li class="list-group-item pb-0 mt-1" markdown>`Requirement` must be provided, found in the certification system, and the institution must be allowed to recommend the requirement completion in the related program.</li>
            <li class="list-group-item pb-0 mt-1" markdown>If the provided `Requirement` requires a `CourseNumber`, `CourseNumber` must be provided.</li>
          </ul>
        </div>
      </div>
    </div>

  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-secondary" (click)="onConfirm(false)">Close</button>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SigninRedirectCallbackComponent } from './signin-redirect-callback/signin-redirect-callback.component';
import { TokenInterceptorProvider } from './interceptors/token.interceptor';
import { SharedModule } from 'src/shared/shared.module';
import { RequiresPolicyDirective } from './requires-policy.directive';
import { ToastInterceptorProvider } from './interceptors/toast.interceptor';


@NgModule({
  declarations: [
    SigninRedirectCallbackComponent,
    SigninRedirectCallbackComponent,
    RequiresPolicyDirective,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  providers:[
    TokenInterceptorProvider,
    ToastInterceptorProvider
  ],
  exports: [
    RequiresPolicyDirective,
  ]
})
export class AuthModule { }

<ngx-skeleton-loader *ngIf="!dataLoaded" count="1" appearance="circle" [theme]="{
  'border-radius': '5px',
    height: '4.5rem',
    width: '15rem'
  }">
</ngx-skeleton-loader>

<ngx-skeleton-loader *ngIf="!dataLoaded" count="2" appearance="circle" [theme]="{
  'border-radius': '5px',
    height: '2rem',
    width: '7rem'
  }">
</ngx-skeleton-loader>

<div class="d-flex justify-content-start flex-wrap">
  @if(dataLoaded)
  {
  <div class="mb-1 me-2">
    <div class="mb-2">
      <a class="h1 sneaky-link" href="/cert/educators/{{personId}}" (click)="navigateToEducatorManagement($event)">
        {{educatorDetail.lastName}}, {{educatorDetail.firstName}} {{educatorDetail.middleInitial}}
      </a>
      <span class="ps-2">
        <cert-pd-requirement-state [pdRequirementState]="educatorDetail.overallPdState" />
        <span class="badge badge-danger m-2 alert-badge" *ngFor="let alert of educatorDetail.alerts">{{ alert.display }} <span
            *ngIf="alert.canManuallyRemoveAlert" class="bi-x-circle-fill align-middle clickable"
            (click)="openDeleteAlertModal(alert.personAlertId)"></span></span>
        <span class="badge badge-light m-1 opacity-50 badge-skeleton" (click)="openAddAlertModal()">Add Alert <span
            class="bi-plus-circle-fill align-middle"></span></span>&nbsp;
      </span>
    </div>
    <div class="d-flex gap-3 fs-5">
      <div *ngIf="educatorDetail.ein ?? null" class="me-3">
        <span class="fw-bold me-1">EIN:</span>
        <span>{{educatorDetail.ein}}</span>
      </div>
      <div>
        <span [ngSwitch]="educatorDetail.validityStatus">
          <span *ngSwitchCase="fingerprintCardValidityStatus.NotOnFile">
            <span class="fw-bold me-1">IVP Fingerprint:</span>Not On File<i
              class="bi bi-stop-circle ps-1 text-danger"></i></span>
          <span *ngSwitchCase="fingerprintCardValidityStatus.Valid">
            <span class="fw-bold me-1">IVP Fingerprint:</span>Valid<i
              class="bi bi-check-circle ps-1 text-success"></i></span>
          <span *ngSwitchCase="fingerprintCardValidityStatus.Pending">
            <span class="fw-bold me-1">IVP Fingerprint:</span>Pending<i
              class="bi bi-exclamation-circle ps-1 text-warning"></i></span>
          <span *ngSwitchCase="fingerprintCardValidityStatus.NotValid">
            <span class="fw-bold me-1">IVP Fingerprint:</span>Not Valid<i
              class="bi bi-stop-circle ps-1 text-danger"></i></span>
          <span *ngSwitchCase="fingerprintCardValidityStatus.Expired"><span class="fw-bold me-1">IVP Fingerprint:</span>
            Expired<i class="bi bi-x-circle ps-1 text-danger"></i></span>
          <span *ngSwitchDefault><span class="fw-bold me-1">IVP Fingerprint:</span>
            Not On File<i class="bi bi-stop-circle ps-1 text-danger"></i></span>
        </span>
        <span *ngIf="educatorDetail.hasUnacknowledgedFingerprintReceived" class="badge bg-warning ms-2 my-auto"
          [certTooltip]="'New fingerprint has not been acknowledged by the assigned user'">New</span>
      </div>
      <span class="me-3">
        <span class="fw-bold me-1">Current Employment:</span>
        <span>{{educatorCurrentEmployment}}</span>
      </span>
    </div>
  </div>
  }
  <div class="ms-auto">
    <span requiresPolicy="canViewNotes" class="btn btn-outline-primary ms-auto me-2" *ngIf="loadingAddressWindowTab">
      <ng-container>
        Preparing Document
        <div class="spinner-alternating ms-2">
          <div class="dot bg-primary"></div>
          <div class="dot bg-primary"></div>
          <div class="dot bg-primary"></div>
        </div>
      </ng-container>
    </span>
    <span [certTooltip]="educatorAddressExists ? '' : 'Educator must have an address to use this feature.'">
      <button requiresPolicy="canViewNotes" class="btn btn-outline-primary ms-auto me-2"
        (click)="printAddressWindow()" *ngIf="!loadingAddressWindowTab"
        [disabled]="!educatorAddressExists">
        Print Address Window
      </button>
    </span>

    <iframe #printFrame title="print-frame" style="display:none;"></iframe>
    <button requiresPolicy="canViewNotes" class="btn btn-outline-primary ms-auto"
      (click)="openEducatorNotesCanvas()">Notes<i class="journal-icon ps-1"></i></button>
  </div>
</div>
<cert-educator-notes *componentRequiresPolicy="'canViewNotes'" [personId$]="personId$"></cert-educator-notes>

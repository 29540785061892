import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { PolicySetKey } from 'src/shared/constants';
import { UserPolicyEvalDto } from 'src/shared/services/api.service';
import { LocalStorageService } from 'src/shared/services/local-storage.service';
import { ToastService } from 'src/shared/services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class PolicyGuard  {

  constructor(private localStorageService: LocalStorageService, private toastService: ToastService, private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const policy = route.data.policy as keyof UserPolicyEvalDto;
    const policySet = JSON.parse(this.localStorageService.get(PolicySetKey) ?? '{}') as UserPolicyEvalDto;
    const result = policySet[policy];
    
    if (result === true) {
      return true;
    } else {
      this.router.navigate(['']).then(() => {
        this.toastService.error(`You're not authorized to view that page.`);
      });

      return false;
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }
}

<div class="container">
  <form #rolePermissionsForm novalidate>
    <div class="row">
      <div class="col-lg-6">
        <div class="form mb-3">
          <label for="roleSelect" class="mb-1">Role Name</label>
          <ng-select id="roleSelect" name="roleSelect"
            [closeOnSelect]="true" [multiple]="false" [(ngModel)]="selectedRoleId"
            placeholder="Select a role..." (change)="onRoleSelection()">
            <ng-option *ngFor="let role of roles" [value]="role.roleId">{{ role.adeConnectRoleName }}
            </ng-option>
          </ng-select>
        </div>
      </div>
      <p class="lead mt-1">
        {{selectedRoleDescription}}
      </p>
    </div>
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4" *ngIf="selectedRoleId">
      <div class="col" *ngFor="let permissionGroup of permissionGroups">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">{{permissionGroup.name}}</h5>
            <p class="card-subtitle mb-4 text-muted">{{permissionGroup.description}}</p>
            <ul class="list-group-flush list-group-horizontal">
              <li *ngFor="let permission of permissionGroup.permissions" class="list-group-item me-2">
                <div class="form-check form-switch">
                  <input class="form-check-input" type="checkbox" name="{{permission.displayName}}Checkbox"
                    value="{{permission.permissionId}}" id="{{permission.name}}Checkbox"
                    title="{{permission.description}}" data-toggle="tooltip" data-placement="top"
                    [checked]="permission.isSelected" (change)="togglePermissionsIsSelected(permission.permissionId)">
                  <label class="form-check-label" for="{{permission.displayName}}Checkbox">
                    {{permission.displayName}}
                  </label>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="mt-2 text-end" *ngIf="selectedRoleId">
    <button type="button" class="btn btn-outline-secondary me-2 cancel-button" (click)="resetPage()">Cancel</button>
    <button type="submit" class="btn btn-primary save-button" (click)="onSubmit()">Save Changes</button>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HelpRoutingModule } from './help-routing.module';
import { HelpLayoutComponent } from './help-layout/help-layout.component';
import { HelpHomeComponent } from './help-home/help-home.component';


@NgModule({
  declarations: [
    HelpLayoutComponent,
    HelpHomeComponent
  ],
  imports: [
    CommonModule,
    HelpRoutingModule
  ]
})
export class HelpModule { }

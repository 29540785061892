import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EducatorAuditItemsDto, EducatorsClient } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  private notesSubject$ = new Subject<EducatorAuditItemsDto>();
  public notes$ = this.notesSubject$.asObservable();

  constructor(private educatorsClient: EducatorsClient) { }

  public getNotes(personId: number) {
    this.educatorsClient.getEducatorNotes(personId)
      .subscribe(x => {
        this.notesSubject$.next(x);
      });
  }
}

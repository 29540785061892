import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { map } from 'rxjs';
import { JwtService } from 'src/auth/services/jwt.service';

export const userTypeMatchGuard: CanMatchFn = (route, segments) => {
  const jwtService = inject(JwtService);
  const target = segments.length > 0 ? segments[0].path : '';

  if (target) {
    return jwtService.userTypes$.pipe(map(userTypes =>
      userTypes.some(x => x.trim().toLowerCase() === target)
    ));
  }

  return true;
};

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MimeType } from '../constants';

@Component({
  selector: 'cert-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent {
  @Input() mimeType: MimeType = MimeType.PDF;
  @Output() fileSelected = new EventEmitter<string>();

  @ViewChild('uploadInput') uploadInput!: ElementRef;

  onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = (e: any) => {
        const base64Content = e.target.result.split(',')[1];

        this.fileSelected.emit(base64Content);
      }
    };
  }

  clearInput() {
    this.uploadInput.nativeElement.value = null;
  }
}

<span certTooltip="Manage Cohort" class="me-1"><a class="link-opacity-100" [routerLink]="'/epp/cohort-management/' + cohortId">Manage</a></span>
@if (hasUpdateCohortPolicy) {
  /&nbsp;<span certTooltip="Edit Cohort" (click)="editCohort()" class="me-1"><a class="link-opacity-100 clickable">Edit</a></span>
}
@else {
  /&nbsp;<span certTooltip="You do not have permission to edit this cohort" (click)="editCohort()" class="me-1"><a class="link-opacity-75 text-muted cursor-not-allowed">Edit</a></span>
}
@if(canRemoveCohort()) {
  /&nbsp;<span certTooltip="Remove Cohort" (click)="removeCohort()"><a class="link-opacity-100 clickable">Remove</a></span>
}
@else if (!canRemoveCohort() && hasRemoveCohortPolicy) {
  /&nbsp;<span certTooltip="Cohort can not be removed while students are present"><a class="link-opacity-75 text-muted cursor-not-allowed">Remove</a></span>
}
@else if (!canRemoveCohort() && numberOfStudents == 0) {
  /&nbsp;<span certTooltip="You do not have permission to remove this cohort"><a class="link-opacity-75 text-muted cursor-not-allowed">Remove</a></span>
}

/&nbsp;<span><a class="link-opacity-100 clickable" (click)="exportCohort()">Export</a></span>

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'cert-signout-redirect-callback',
  template: '<div></div>'
})
export class SignoutRedirectCallbackComponent implements OnInit{
  constructor(
    private authService: AuthService,
    private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.authService.completeLogout().then(() => {
      this.router.navigate(['/launch'], { replaceUrl: true });
    });
  }
}

import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs';
import { SelectListOption } from 'src/shared/models/SelectListOptions';
import { AddHelpObjectCommand, ContentType, HelpClient, PortalType } from 'src/shared/services/api.service';
import { LoaderService } from 'src/shared/services/loader.service';
import { catchValidationErrors } from 'src/shared/utilities/validator-utilities';

@Component({
  selector: 'cert-help-add-form',
  templateUrl: './help-add-form.component.html',
  styles: ``
})
export class HelpAddFormComponent {
  @Output() helpFormUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('addHelpObjectForm') form!: NgForm;

  command: AddHelpObjectCommand = {
    portalType: undefined,
    title: '',
    description: '',
    content: undefined,
    contentType: undefined,
    externalUri: undefined
  };

  contentTypeOptions: SelectListOption<ContentType>[] = [];
  portalTypeOptions: SelectListOption<PortalType>[] = [];

  ContentType = ContentType;

  errorEmitter = new EventEmitter<any>();

  constructor(private helpClient: HelpClient, private loaderService: LoaderService) {}

  ngOnInit() {
    this.initializeOptions();
  }

  initializeOptions() {
    this.portalTypeOptions = Object.keys(PortalType)
      .filter(key => isNaN(Number(key)))
      .map(key => ({
        value: PortalType[key as keyof typeof PortalType],
        text: key,
      }));

    this.contentTypeOptions = Object.keys(ContentType)
      .filter(key => isNaN(Number(key)))
      .map(key => ({
        value: ContentType[key as keyof typeof ContentType],
        text: key,
      }));
  }

  setUploadContent(content: string) {
    this.command.content = content;
  }

  cancelAddHelpObject() {
    this.helpFormUpdate.emit(false);
  }

  onAddHelpObject() {
    this.loaderService.loaderOn();
    this.helpClient.addHelpObject(this.command)
    .pipe(
      catchValidationErrors(this.form, this.errorEmitter),
      finalize(() => this.loaderService.loaderOff()))
    .subscribe({
      next: () => {
      this.helpFormUpdate.emit(true);
      },
      complete: () => this.loaderService.loaderOff()
    });
  }
}

import { Component, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, ColDef, GridOptions, RowNode } from 'ag-grid-community';
import { ConfirmationModalComponent } from 'src/shared/confirmation-modal/confirmation-modal.component';
import { GridCommonService } from 'src/shared/grid/grid-common.service';
import { AgGridSortModel, EppClient, EppCohortDto, RemoveCohortCommand } from 'src/shared/services/api.service';
import { catchValidationErrors } from 'src/shared/utilities/validator-utilities';
import { CohortsListingActionsCellRendererComponent } from './cohorts-listing-actions-cell-renderer/cohorts-listing-actions-cell-renderer.component';
import { CohortAddCohortModalComponent } from '../cohort-add-cohort-modal/cohort-add-cohort-modal.component';

@Component({
  selector: 'epp-cohorts-listing-grid',
  templateUrl: './cohorts-listing-grid.component.html',
  styles: ``
})
export class CohortsListingGridComponent {
  constructor(
    private gridCommonService: GridCommonService,
    private eppClient: EppClient,
    private modalService: NgbModal) { }

  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  readonly setFilterColumnNames = ['fiscalYear', 'description', 'institution'];

  get gridApi(): GridApi {
    return this.agGrid.api;
  }

  get gridHeight() {
    return (window.innerHeight - 300).toString() + 'px';
  }

  sortModel!: AgGridSortModel[];
  filterModel!: {};

  errorEmitter = new EventEmitter<any>();

  getColDefs(): ColDef[] {
    const colDefs: ColDef[] = [
      {
        field: 'cohortId',
        filter: 'agNumberColumnFilter',
        filterParams: { filterOptions: this.gridCommonService.numberFilterOptions },
        hide: true,
      },
      {
        field: 'institution',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params: any) => {
            this.eppClient.getCohortsSetFilterValues({
              propertyName: 'Institution'
            }).subscribe(x => params.success(x.columnValues));
          }
        },
        headerName: 'Institution',
        flex: 1
      },
      {
        field: 'fiscalYear',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params: any) => {
            this.eppClient.getCohortsSetFilterValues({
              propertyName: 'FiscalYear'
            }).subscribe(x => params.success(x.columnValues));
          }
        },
        headerName: 'Year',
        flex: .5
      },
      {
        field: 'description',
        filter: 'agSetColumnFilter',
        filterParams: {
          values: (params: any) => {
            this.eppClient.getCohortsSetFilterValues({
              propertyName: 'Description'
            }).subscribe(x => params.success(x.columnValues));
          }
        },
        headerName: 'Cohort Name',
        flex: 1
      },
      {
        field: 'numberOfStudents',
        filter: 'agNumberColumnFilter',
        headerName: 'Number of Candidates',
        floatingFilter: false,
        suppressMenu: false,
        menuTabs: [
          'filterMenuTab'
        ],
        flex: .75
      },
      {
        headerName: 'Actions',
        sortable: false,
        cellRenderer: CohortsListingActionsCellRendererComponent,
        suppressMenu: true,
        filter: false,
        flex: 0.75
      }
    ];

    return colDefs;
  }


  gridOptions: GridOptions<EppCohortDto> = {
    getRowId: (params) => {
      return params.data.cohortId.toString();
    },
    domLayout: 'normal',
    rowModelType: 'serverSide',
    cacheBlockSize: 100,
    pagination: true,
    paginationPageSize: 20,
    suppressRowClickSelection: true,
    rowSelection: 'multiple',
    defaultColDef: {
      sortable: true,
      resizable: true,
      suppressMovable: true,
      suppressMenu: true,
      floatingFilter: true,
      filter: 'agTextColumnFilter'
    },
    columnDefs: this.getColDefs(),
    context: {
      componentParent: this
    },
    serverSideFilterOnServer: true,
    serverSideSortOnServer: true,
    serverSideDatasource: {
      getRows: (params) => {
        params.api.showLoadingOverlay();

        const requestPayload = {
          pagination: {
            startRow: params.request.startRow!,
            endRow: params.request.endRow!
          },
          sortModels: params.request.sortModel && params.request.sortModel.length > 0
            ? params.request.sortModel
            : [
                {
                  colId: 'fiscalYear',
                  sort: 'desc',
                  sortIndex: 0,
                }
              ],
          filterModels: this.gridCommonService.onFilterChanged(params.request.filterModel),
        };

        this.eppClient.getCohorts(requestPayload).subscribe({
          next: (data) => {
            params.success({
              rowData: data.cohortEntries,
              rowCount: data.totalCohortEntries,
            });
            params.api.hideOverlay();
          },
          error: () => {
            params.fail();
          },
        });
      },
    }
  }

  resetFilters() {
    if (this.gridApi) {
      this.gridApi.setFilterModel(null);
      this.gridApi.refreshServerSide();
    }
  }

  refreshServerSide() {
    this.gridApi.refreshServerSide();
  }

  onSelectionChanged(node: RowNode, isSelected: boolean) {
    this.gridCommonService.onSelectionChanged(node, isSelected);
  }

  editCohort(cohortId: number, cohortDescription: string, fiscalYear: string) {
    const modalRef = this.modalService.open(CohortAddCohortModalComponent, {
      backdrop: 'static',
      size: 'md',
      centered: true,
    });

    modalRef.componentInstance.header = 'Update Cohort';
    modalRef.componentInstance.message = `Please enter a fiscal year and description for the cohort.`;
    modalRef.componentInstance.cohortId = cohortId;
    modalRef.componentInstance.currentFiscalYear = fiscalYear;
    modalRef.componentInstance.currentDescription = cohortDescription;
    modalRef.componentInstance.isUpdate = true;

    modalRef.result.then((result) => {
      if (result) {
        this.refreshServerSide();
      }
    });
  }

  removeCohort(cohortId: number, cohortDescription: string, fiscalYear: string) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      backdrop: 'static',
      size: 'md',
      centered: true,
    });

    modalRef.componentInstance.header = 'Remove Cohort';
    modalRef.componentInstance.message = `You are removing cohort ${cohortDescription} for the fiscal year ${fiscalYear}. Are you sure?`;

    modalRef.result.then((result) => {
      if (result) {
        this.confirmRemoveFromRoster(cohortId);
      }
    });
  }

  confirmRemoveFromRoster(cohortId: number) {
    let command: RemoveCohortCommand = {
      cohortId: cohortId
    }

    this.eppClient.removeCohort(command)
      .pipe(catchValidationErrors(undefined, this.errorEmitter))
      .subscribe(x => {
        this.gridApi.refreshServerSide();
      });
  }
}

import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { LeaEducatorRosterCertificateDto, ProfessionalDevelopmentRequirementState } from 'src/shared/services/api.service';

@Component({
  selector: 'lea-educator-roster-cert-results',
  templateUrl: './educator-roster-cert-results.component.html',
  styles: ``
})
export class EducatorRosterCertResultsComponent implements ICellRendererAngularComp {

  ProfessionalDevelopmentRequirementState = ProfessionalDevelopmentRequirementState;
  educatorCertificates!: LeaEducatorRosterCertificateDto[];
  allowableDefDescription!: string;

  agInit(params: ICellRendererParams): void {
    this.educatorCertificates = params.data.educatorCertificates;
    this.allowableDefDescription = this.educatorCertificates.some(x => x.allowableDeficiencies)
     ? this.educatorCertificates.map(x => x.allowableDeficiencies).join(',')
     : 'N/A';
  }

  refresh(params: ICellRendererParams): boolean { return false; }

  getExpiryMonthsDescription(expiryMonths: number) {
    const years = Math.round(expiryMonths / 12);
    const yearDescription = years == 1 ? 'year' : 'years';

    if (years != 0) {
      return expiryMonths > 0
      ? `expires in ${years} ${yearDescription}`
      : `expired ${years * -1} ${yearDescription} ago`;
    }

    return expiryMonths > 0
      ? `expires in ${expiryMonths} months`
      : `expired ${expiryMonths * -1} months ago`;
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'cert-epp-nav-menu',
  templateUrl: './epp-nav-menu.component.html',
  styles: ``
})
export class EppNavMenuComponent {

}

import { Component } from '@angular/core';

@Component({
  selector: 'cert-dots-loading',
  templateUrl: './dots-loading.component.html',
  styles: `.loading-spinner {
    width: 0.75rem;
    height: 0.75rem;
    margin: 0.75rem 0.2rem 0.75rem;
    animation: pulse 1.5s infinite;
  }

  .loading-spinner:nth-child(1) {
    animation-delay: 0s;
  }

  .loading-spinner:nth-child(2) {
    animation-delay: 0.5s;
  }

  .loading-spinner:nth-child(3) {
    animation-delay: 1s;
  }

  @keyframes pulse {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.3;
    }
  }`
})
export class DotsLoadingComponent {

}

<h2 class="h4 mb-3">Recommendations File Upload Integration Guide</h2>
<div class="row mb-3">
  <div class="col">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Upload Process</h5>
        <hr>
        <h6 class="card-subtitle mb-2">
          There is an “Upload Recommendations File” button available on the Cohort Management page.
          The uploaded students will be added to the cohort the user is managing. There is a two-step process of validation and completion.
        </h6>
        <a class="link-opacity-75 float-end clickable card-link" (click)="onDownloadIRUploadFileTemplate()">Download Recommendations File Upload Template</a>
      </div>
    </div>
  </div>
</div>
<div class="row row-cols-1 row-cols-md-2 mb-4">
  <div class="col d-flex align-items-stretch">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Validation Step</h5>
        <div class="card p-3 mb-2 bg-light">
          <p class="card-text">
            There are a number of validation rules in place to ensure the accuracy of the data uploaded by the EPPs and to enable us to construct the appropriate IR record in the certification system.
          </p>
        </div>
        <div class="card p-3 mb-2">
          <p class="card-text" markdown>
            A central part of the validation to be performed requires locating a matching student in the certification system as well as correlating rows across worksheets using
          the student and program information. To help the validation rules read more concisely, we'll use the term **Student/Program Identity Info** to describe the
          `EIN`, `FirstName`, `LastName`, `DateOfBirth`, `LastFourSSN`, and `ProgramCode` columns.
          </p>
        </div>
        <div class="card p-3 mb-3">
          <p class="card-text" markdown>
            **Y/N Values** Columns that requires a yes/no or true/false value should use a single character
            of **Y** for yes or true and **N** for no or false.
          </p>
        </div>
        <div>
          <button type="button" class="btn btn-outline-primary" (click)="onViewValidationRules()">View Validation Rules</button>
          <button type="button" class="btn btn-outline-primary float-end" (click)="onViewExpectedValues()">View Expected Values</button>
        </div>
      </div>
    </div>
  </div>
  <div class="col d-flex align-items-stretch">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Completion Step</h5>
        <div class="card p-3 mb-2 bg-light">
          <p class="card-text">
            After the uploaded file has been successfully validated, meaning there are no errors found, you will be able to continue to the completion step.
            When completing the upload process the user will see a modal giving helpful information about the uploaded file. These details include:
          </p>
          <ul>
            <li>The number of programs configured to automatically authorized upon upload completion.</li>
            <li>The number of programs configured to require manual authorization upon upload completion.</li>
            <li>The number of programs in the file that will be overwritten by the new program details.
              This occurs when the student being uploaded already belongs to this particular cohort and program.</li>
          </ul>
          <p class="card-text">
            This information serves as a warning to the user in the event the results are unexpected.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

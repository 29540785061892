import { FilterDetailsDictionary } from "src/shared/grid/grid-common.service";

export const LeaRosterQuicklinkFilterModelsDictionary: FilterDetailsDictionary = {
  ivpStatus: {
    filterType: "set",
    values: ["Invalid", "Not On File"],
    filterModels: []
  },
  needsProfessionalDevelopment: {
    filterType: "set",
    values: ["true"],
    filterModels: []
  },
  hasRenewableCertificates: {
    filterType: "set",
    values: ["true"],
    filterModels: []
  },
  hasDeficiencies: {
    filterType: "set",
    values: ["true"],
    filterModels: []
  }
}

import { Component, Input } from '@angular/core';
import { ToastConfig } from 'src/shared/services/toast.service';
import { v4 as uuid } from 'uuid';
import { Toast } from 'bootstrap';

@Component({
  selector: 'cert-toast',
  templateUrl: './toast.component.html',
  styles: ``
})
export class ToastComponent {
  @Input() public toastConfig!: ToastConfig;
  public toastId: string;

  constructor() {
    this.toastId = uuid();
  }

  ngAfterViewInit() {
    const toastEl = document.getElementById(this.toastId) as Element;
    const toast = new Toast(toastEl, {
      autohide: this.toastConfig.type === 'success' || this.toastConfig.type === 'info',
      delay: 2500
    })

    toast.show();
  }
}

import { Component, Input } from '@angular/core';
import { JwtService } from 'src/auth/services/jwt.service';
import { ConfigService } from 'src/shared/services/config.service';

@Component({
  selector: 'cert-issue-collector',
  template: `<a class="dropdown-item clickable" href="#" (click)="openIssueCollector($event)">Report Error</a>`
})
export class IssueCollectorComponent {
  @Input() traceId!: string;
  @Input() errorId!: string;

  constructor(private configService: ConfigService, private jwtService: JwtService) {}

  openIssueCollector(event: Event) {
    event.preventDefault();
    this.removeExistingScript(this.configService.config.jiraIssueCollectorUrl);
    this.loadScript(this.configService.config.jiraIssueCollectorUrl)
      .then(() => {
        return this.setAtlJqPageProps();
      })
      .then(() => {
        if (typeof (window as any).showCollectorDialog === 'function') {
          (window as any).showCollectorDialog();
        } else {
          console.error('showCollectorDialog is not a function or not defined.');
        }
      })
      .catch(err => {
        console.error('Failed to load script or set ATL_JQ_PAGE_PROPS:', err);
      });
  }

  setAtlJqPageProps(): Promise<void> {
    return new Promise((resolve) => {
      const self = this;

      delete (window as any).ATL_JQ_PAGE_PROPS;

      (window as any).ATL_JQ_PAGE_PROPS = {
        triggerFunction: function(showCollectorDialog: any) {
          (window as any).showCollectorDialog = showCollectorDialog;
          resolve();
        },
        fieldValues: function() {
          const values: any = {};
          values['summary'] = 'Summarize problem here.';
          values['description'] = 'Add details about the problem here, what you did, what happened, what you expected. ' + self.getTraceIdString() + self.getErrorIdString();
          values['customfield_10124'] = 'Please add the EIN, if applicable.';
          values['fullname'] = 'UserId: ' + self.jwtService.getUserId();
          values['email'] = self.jwtService.getUserEmail();

          return values;
        }
      };
    });
  }

  loadScript(url: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const scriptExists = !!document.querySelector(`script[src="${url}"]`);
      if (scriptExists) {
        resolve();

        return;
      }
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        reject(new Error(`Failed to load script: ${url}`));
      };
      document.body.appendChild(script);
    });
  }

  removeExistingScript(url: string) {
    const existingScript = document.querySelector(`script[src="${url}"]`);
    if (existingScript) {
      existingScript.remove();
    }
  }

  getTraceIdString(): string {
    return this.traceId ? `\n\n TraceId: ${this.traceId}` : '';
  }

  getErrorIdString(): string {
    return this.errorId ? `\n\n ErrorId: ${this.errorId}` : '';
  }
}

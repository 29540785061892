<div class="row my-5">
  <div class="col me-4 text-center">
    <div class="clickable border rounded-4 p-2 border-1"
      [class.active-filter]="activeFilter === LeaEducatorRosterFilters.HasMissingIvpFingerprint"
      certTooltip="Filter educators with no IVP fingerprint card on file"
      (click)="filterServiceItems(LeaEducatorRosterFilters.HasMissingIvpFingerprint)">
      @if(quickFilterCountsDto) {
        <h4 class="fw-normal fs-4">{{ quickFilterCountsDto!.missingIVPFingerprintCount }}</h4>
      } @else {
        <cert-dots-loading />
      }
      <h5 class="fw-normal fs-5">Missing IVP Fingerprint</h5>
    </div>
  </div>
  <div class="col mx-4 text-center">
    <div class="clickable border rounded-4 p-2 border-1" certTooltip="Filter educators needing professional development"
      [class.active-filter]="activeFilter === LeaEducatorRosterFilters.NeedsProfessionalDevelopment"
      (click)="filterServiceItems(LeaEducatorRosterFilters.NeedsProfessionalDevelopment)">
      @if(quickCountsDataLoaded) {
        <h4 class="fw-normal fs-4">{{ quickFilterCountsDto!.needsProfessionalDevelopmentCount }}</h4>
      } @else {
        <cert-dots-loading />
      }
      <h5 class="fw-normal fs-5">Needs PD Requirement Verified</h5>
    </div>
  </div>
  <div class="col mx-4 text-center">
    <div class="clickable border rounded-4 p-2 border-1" certTooltip="Filter educators with renewable certificates"
      [class.active-filter]="activeFilter === LeaEducatorRosterFilters.HasRenewableFilters"
      (click)="filterServiceItems(LeaEducatorRosterFilters.HasRenewableFilters)">
      @if(quickFilterCountsDto) {
        <h4 class="fw-normal fs-4">{{ quickFilterCountsDto!.renewableCertificatesCount }}</h4>
      } @else {
        <cert-dots-loading />
      }
      <h5 class="fw-normal fs-5">Renewable Certificates</h5>
    </div>
  </div>
  <div class="col ms-4 text-center">
    <div class="clickable border rounded-4 p-2 border-1" certTooltip="Filter educators with allowable deficiencies"
      [class.active-filter]="activeFilter === LeaEducatorRosterFilters.HasDeficiencies"
      (click)="filterServiceItems(LeaEducatorRosterFilters.HasDeficiencies)">
      @if(quickFilterCountsDto) {
        <h4 class="fw-normal fs-4">{{ quickFilterCountsDto!.hasDeficienciesCount }}</h4>
      } @else {
        <cert-dots-loading />
      }
      <h5 class="fw-normal fs-5">Has Deficiencies</h5>
    </div>
  </div>
</div>

<form #educatorRosterForm="ngForm">
  <div class="row mb-2">
    <div class="col-6">
      <h2 class="h4">My Educator Roster</h2>
    </div>
    <div class="col-6" >
      <button type="button" class="btn btn-primary float-end" requiresPolicy="canVerifyProfessionalDevelopment"
        certTooltip="Add professional development to all selected certificates"
        (click)="onAddProfessionalDevelopment()">Verify PD Requirement</button>
      <button type="button" class="btn btn-outline-primary float-end me-2" (click)="resetFilters()">Reset
        Filters</button>
    </div>
  </div>

  <lea-educator-roster-grid (quickFilterChanged)="onQuickFilterChange($event)" />
  <div formLevelValidationErrors [errorEmitter]="errorEmitter"></div>
</form>

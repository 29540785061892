import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LeaRoutingModule } from './lea-routing.module';
import { LeaLayoutComponent } from './lea-layout/lea-layout.component';
import { EducatorRosterComponent } from './educator-roster/educator-roster.component';
import { EducatorRosterGridComponent } from './educator-roster/educator-roster-grid/educator-roster-grid.component';
import { EducatorRosterCertResultsComponent } from './educator-roster/educator-roster-grid/educator-roster-cert-results/educator-roster-cert-results.component';
import { SharedModule } from 'src/shared/shared.module';
import { RosterActionsCellRendererComponent } from './educator-roster/educator-roster-grid/roster-actions-cell-renderer/roster-actions-cell-renderer.component';
import { VerifyPdConfirmationComponent } from './educator-roster/verify-pd-confirmation/verify-pd-confirmation.component';
import { AuthModule } from 'src/auth/auth.module';

@NgModule({
  declarations: [
    LeaLayoutComponent,
    EducatorRosterComponent,
    EducatorRosterGridComponent,
    EducatorRosterCertResultsComponent,
    RosterActionsCellRendererComponent,
    VerifyPdConfirmationComponent,
  ],
  imports: [
    CommonModule,
    LeaRoutingModule,
    SharedModule,
    AuthModule
  ]
})
export class LeaModule { }

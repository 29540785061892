import { Component, EventEmitter, ViewChild } from '@angular/core';
import { LeaClient, LeaEducatorRosterQuickFilterCountsDto, UpdatePersonCertificateProfessionalDevelopmentCommand } from 'src/shared/services/api.service';
import { EducatorRosterGridComponent } from './educator-roster-grid/educator-roster-grid.component';
import { catchGridValidationErrors } from 'src/shared/utilities/validator-utilities';
import { NgForm } from '@angular/forms';
import { LeaEducatorRosterFilters } from 'src/shared/constants';
import { BehaviorSubject, switchMap } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { VerifyPdConfirmationComponent } from './verify-pd-confirmation/verify-pd-confirmation.component';
import { LoaderService } from 'src/shared/services/loader.service';

@Component({
  selector: 'lea-educator-roster',
  templateUrl: './educator-roster.component.html',
  styles: ``
})
export class EducatorRosterComponent {
  educatorRosterQuickFilterCountsRefresh$ = new BehaviorSubject<void>(undefined);
  quickFilterCountsDto?: LeaEducatorRosterQuickFilterCountsDto;
  quickCountsDataLoaded: boolean = false;
  activeFilter: LeaEducatorRosterFilters | undefined = undefined;

  LeaEducatorRosterFilters = LeaEducatorRosterFilters;

  @ViewChild(EducatorRosterGridComponent) gridComponent!: EducatorRosterGridComponent;
  @ViewChild('educatorRosterForm') form!: NgForm;

  errorEmitter = new EventEmitter<any>();

  constructor(private leaClient: LeaClient, private modalService: NgbModal, private loaderService: LoaderService) { }

  ngOnInit() {
    this.getQuickFilterCounts();
  }

  getQuickFilterCounts() {
    this.educatorRosterQuickFilterCountsRefresh$.pipe(switchMap(() => {
      this.quickCountsDataLoaded = false;
      return this.leaClient.getLeaEducatorRosterQuickFilterCounts();
    })).subscribe(x => {
      this.quickFilterCountsDto = x;
      this.quickCountsDataLoaded = true;
    });
  }

  filterServiceItems(filter: LeaEducatorRosterFilters) {
    this.gridComponent.filterServiceItems(filter);
  }

  resetFilters() {
    this.gridComponent.resetFilters();
  }

  onAddProfessionalDevelopment() {
    const selectedRows = this.gridComponent.gridApi.getSelectedRows();
    const selectedPersonIds: number[] = selectedRows.map(x => x.personId);

    const modalRef = this.modalService.open(VerifyPdConfirmationComponent, {
      backdrop: 'static',
      size: 'lg',
      centered: true,
    });

    modalRef.componentInstance.header = 'Verify PD Requirement';
    modalRef.componentInstance.selectedPersons = selectedRows.map(x => `${x.firstName} ${x.lastName} (EIN: ${x.ein})`);;

    modalRef.result.then((result) => {
      if (result) {
        this.addProfessionalDevelopment(selectedPersonIds);
      }
    });
  }

  addProfessionalDevelopment(selectedPersonIds: number[]) {
    this.loaderService.loaderOn();
    const updatePersonCertificateProfessionalDevelopmentCommand: UpdatePersonCertificateProfessionalDevelopmentCommand = {
      personIds: selectedPersonIds
    };

    this.leaClient.updatePersonCertificateProfessionalDevelopment(updatePersonCertificateProfessionalDevelopmentCommand)
      .pipe(catchGridValidationErrors(this.errorEmitter))
      .subscribe({
        next: () => {
          this.gridComponent.gridApi.refreshServerSide();
          this.gridComponent.gridApi.deselectAll();
          this.educatorRosterQuickFilterCountsRefresh$.next();
        },
        complete: () => {
          this.loaderService.loaderOff();
        }
      });
  }

  onQuickFilterChange(filterKey: LeaEducatorRosterFilters | undefined) {
    this.activeFilter = filterKey;
  }
}

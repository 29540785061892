import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'epp-validation-rules-modal',
  templateUrl: './validation-rules-modal.component.html',
  styles: `
    .modal-body {
      max-height: 70vh;
      overflow-y: auto;
    }
  `
})
export class ValidationRulesModalComponent {

  constructor(private activeModal: NgbActiveModal) { }

  onConfirm(confirmed: boolean): void {
    this.activeModal.close(confirmed);
  }
}

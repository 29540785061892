import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from 'src/shared/confirmation-modal/confirmation-modal.component';
import { CheckExistingEducatorQuery, CodeTablesClient, CreatePersonsCommand, EthnicityCodeDto, GenderCodeDto, GetEducatorSearchQuery, RaceCodeDto } from 'src/shared/services/api.service';
import { EducatorsClient } from 'src/shared/services/api.service';
import { DateService } from 'src/shared/services/date.service';
import { LoaderService } from 'src/shared/services/loader.service';
import { catchValidationErrors } from 'src/shared/utilities/validator-utilities';


@Component({
  selector: 'cert-educator-create-modal',
  templateUrl: './educator-create-modal.component.html',
  styleUrls: ['./educator-create-modal.component.scss'],
})
export class EducatorCreateComponent {
  @Output() toggleSearchComponent = new EventEmitter<GetEducatorSearchQuery>();

  @Input()
  createPersonsCommand: CreatePersonsCommand = {
    ssn: '',
    dateOfBirth: null!,
    firstName: '',
    middleName: '',
    lastName: '',
    generateSSN: false,
    genderCodeId: undefined,
    ethnicityCodeId: undefined,
    raceCodesIds: []
  };
  @ViewChild('createPersonsForm', { static: true }) form!: NgForm;

  showConfirmationModal: boolean = false;
  isConfirmed: boolean = false;
  dateOfBirthString!: string;
  createSSN: boolean = false;
  minDate: string = this.dateService.getMinBirthday();
  maxDate: string = this.dateService.getMaxBirthday();
  errorEmitter = new EventEmitter<any>();

  genderCodes!: GenderCodeDto[];
  ethnicityCodes!: EthnicityCodeDto[];
  raceCodes!: RaceCodeDto[];

  constructor(
    private educatorsClient: EducatorsClient,
    private modalService: NgbModal,
    private dateService: DateService,
    private router: Router,
    private activeModalService: NgbActiveModal,
    private codeTablesClient: CodeTablesClient,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    if (this.createPersonsCommand.dateOfBirth) {
      this.dateOfBirthString = this.createPersonsCommand.dateOfBirth.toLocaleDateString();
    }

    this.codeTablesClient.getGenderEthnicityRaceCodes().subscribe(x => {
      this.genderCodes = x.genderCodes;
      this.ethnicityCodes = x.ethnicityCodes;
      this.raceCodes = x.raceCodes;
    });
  }

  onSubmit() {
    if (this.dateOfBirthString) {
      this.createPersonsCommand.dateOfBirth = new Date(this.dateOfBirthString);
    }

    const checkExistingEducatorQuery: CheckExistingEducatorQuery = {
      dateOfBirth: this.createPersonsCommand.dateOfBirth,
      lastName: this.createPersonsCommand.lastName
    }

    this.educatorsClient.checkExistingEducator(checkExistingEducatorQuery).subscribe({
      next: (result) => {
        if (result) {
          this.openConfirmationModal();
        }
        else {
          this.createPerson();
        }
      }
    });
  }

  createSearchForCreatedPersonQuery(): GetEducatorSearchQuery {
    const searchForCreatedPersonQuery: GetEducatorSearchQuery = {
      ssn: this.createPersonsCommand.ssn,
      firstName: this.createPersonsCommand.firstName,
      lastName: this.createPersonsCommand.lastName,
      middleName: this.createPersonsCommand.middleName,
      dateOfBirth: this.createPersonsCommand.dateOfBirth,
      pageNumber: 1,
      pageSize: 10
    }

    return searchForCreatedPersonQuery;
  }

  onCancel() {
    this.toggleSearchComponent.emit(this.createSearchForCreatedPersonQuery());
  }

  createPerson() {
    this.loaderService.loaderOn();
    this.educatorsClient.createPersons(this.createPersonsCommand)
      .pipe(catchValidationErrors(this.form, this.errorEmitter))
      .subscribe({
        next: () => {
          this.toggleSearchComponent.emit(this.createSearchForCreatedPersonQuery());
          this.loaderService.loaderOff();
        }
      });
  }

  goToEducatorManagement(personId: number) {
    this.activeModalService.close();
    this.router.navigateByUrl(`cert/educators/${personId}`);
  }


  openConfirmationModal() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      backdrop: 'static',
      size: 'sm',
      centered: true,
    });
    modalRef.componentInstance.header = 'Similar Educator Found';
    modalRef.componentInstance.message = 'An educator with the same Last Name and Date of Birth was found. Are you sure you want to create a new Educator? ';

    modalRef.result.then((result) => {
      if (result) {
        this.createPerson();
      } else {
        this.onCancel();
      }
    });
  }

  clearSSN() {
    this.createPersonsCommand.ssn = '';
  }
}

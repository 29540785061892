import { Component, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AddCohortCommand, EppClient, UpdateCohortCommand } from 'src/shared/services/api.service';
import { catchValidationErrors } from 'src/shared/utilities/validator-utilities';

@Component({
  selector: 'epp-cohort-add-cohort-modal',
  templateUrl: './cohort-add-cohort-modal.component.html',
  styles: ``
})
export class CohortAddCohortModalComponent {
  @ViewChild('addCohortForm') form!: NgForm;

  isUpdate!: boolean;
  message!: string;
  header!: string;
  cohortId!: number;
  currentFiscalYear!: number;
  currentDescription!: string;

  fiscalYearInput!: string;
  descriptionInput!: string;

  errorEmitter = new EventEmitter<any>();

  constructor(private activeModal: NgbActiveModal, private eppClient: EppClient) { }

  ngOnInit() {
    if (this.isUpdate) {
      this.fiscalYearInput = this.currentFiscalYear.toString();
      this.descriptionInput = this.currentDescription;
    }
  }

  onCancel(): void {
    this.activeModal.close(false);
  }

  onAddCohort() {
    let command: AddCohortCommand = {
      fiscalYear: this.fiscalYearInput ?? '',
      description: this.descriptionInput ?? ''
    };
    this.eppClient.addCohort(command)
      .pipe(catchValidationErrors(this.form, this.errorEmitter))
      .subscribe(x => this.activeModal.close(true));
  }

  onUpdateCohort() {
    let command: UpdateCohortCommand = {
      cohortId: this.cohortId,
      fiscalYear: this.fiscalYearInput,
      description: this.descriptionInput
    };
    this.eppClient.updateCohort(command)
      .pipe(catchValidationErrors(this.form, this.errorEmitter))
      .subscribe(x => this.activeModal.close(true));
  }
}

<ul class="navbar-nav flex-grow">
  <li class="nav-item dropdown my-auto">
    <a class="nav-link dropdown-toggle" [routerLink]="['/lea']" [routerLinkActive]="['link-active']" role="button"
      data-bs-toggle="dropdown" aria-expanded="false">Educators</a>
    <ul class="dropdown-menu dropdown-menu-left">
      <li>
        <a class="dropdown-item" [routerLinkActive]="['link-active']"
          [routerLink]="['/lea/roster']">
          My Educator Roster</a>
      </li>
    </ul>
  </li>
  <li class="nav-item">
    <a class="nav-link clickable" [routerLink]="['/lea/help']" [routerLinkActive]="['link-active']" role="button"
      aria-expanded="false">Help</a>
  </li>
</ul>

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EducatorNotesCanvasService {
  public educatorNotesCanvasState: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  openCanvas() {
    this.educatorNotesCanvasState.next(true);
  }

  closeCanvas() {
    this.educatorNotesCanvasState.next(false);
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'cert-admin-layout',
  templateUrl: './admin-layout.component.html',
  styles: ``
})
export class AdminLayoutComponent {

}

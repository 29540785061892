import { Component, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CreatePersonsCommand, GetEducatorSearchQuery } from 'src/shared/services/api.service';
import { CreateEducatorParams, EducatorSearchComponent } from '../educator-search-modal/educator-search-modal.component';

@Component({
  selector: 'cert-educator-modal',
  templateUrl: './educator-modal.component.html',
  styles: ``
})
export class EducatorModalComponent {
  @ViewChild(EducatorSearchComponent) educatorSearchComponent!: EducatorSearchComponent;

  constructor(private activeModal: NgbActiveModal) {

  }
  showEducatorSearch: boolean = true;
  showEducatorCreateButton: boolean = false;
  createPersonsCommand!: CreatePersonsCommand;
  getEducatorSearchQuery!: GetEducatorSearchQuery;

  toggleShowEducatorSearch(query: GetEducatorSearchQuery | null) {
    this.showEducatorSearch = !this.showEducatorSearch;
    if (query) {
      this.getEducatorSearchQuery = query;
    }
  }

  toggleEducatorCreateButton(createEducatorParams: CreateEducatorParams) {

    this.showEducatorCreateButton = true;

    const ngbDate = createEducatorParams.dateStruct;

    this.createPersonsCommand = {
      ssn: createEducatorParams.searchQuery.ssn ?? '',
      dateOfBirth: ngbDate ? new Date(ngbDate) : null!,
      firstName: createEducatorParams.searchQuery.firstName ?? '',
      middleName: createEducatorParams.searchQuery.middleName,
      lastName: createEducatorParams.searchQuery.lastName ?? '',
      generateSSN: false,
      genderCodeId: undefined,
      ethnicityCodeId: undefined,
      raceCodesIds: []
    };

  }

  close() {
    this.activeModal.close();
  }

  clearEducatorSearchForm() {
    this.educatorSearchComponent.clearFormInputs();
  }
}

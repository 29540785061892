import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LoaderComponent } from './loader/loader.component';
import { GridLoaderComponent } from './grid/grid-loader/grid-loader.component';
import { ConfigServiceProvider } from './services/config.service'
import { AgGridModule } from 'ag-grid-angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { PhoneNumberPipe } from 'src/shared/phone-number.pipe/phone-number.pipe';
import { SSNPipe } from './ssn.pipe/ssn.pipe';
import { NumericOnlyDirective } from './directives/numeric-only.directive';
import { ValidationErrorsDirective } from './utilities/validation-errors.directive';
import { ComponentRequiresPolicyDirective } from './directives/component-requires-policy.directive';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { NgbCollapseModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuditPopoverComponent } from './audit-popover/audit-popover.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { FormLevelValidationErrorsDirective } from './utilities/form-level-validation-errors.directive';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { CurrencyDirective } from './directives/currency.directive';
import { IconLinkRendererComponent } from './grid/icon-link-renderer/icon-link-renderer.component';
import { ScannerComponent } from './scanner/scanner.component';
import { MonthYearPickerComponent } from './month-year-picker/month-year-picker.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OrderByPipe } from './order-by.pipe/order-by.pipe';
import { IsLinkedIconRendererComponent } from './grid/is-linked-icon-renderer/is-linked-icon-renderer.component';
import { UploaderComponent } from './uploader/uploader.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { DocumentReviewEducatorDetailsComponent } from './document-review-educator-details/document-review-educator-details.component';
import { ScannerLauncherComponent } from './scanner/scanner-launcher/scanner-launcher.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { GridValidationErrorsDirective } from './utilities/grid-validation.directive';
import { FileSystemDirective } from './directives/file-system.directive';
import { CustomSelectRendererComponent } from './grid/custom-select-renderer/custom-select-renderer.component';
import { CustomButtonRendererComponent } from './grid/custom-button-renderer/custom-button-renderer.component';
import { EducatorHeaderComponent } from './educator-header/educator-header.component';
import { AddAlertModalComponent } from './educator-header/add-alert-modal/add-alert-modal.component';
import { EducatorNotesComponent } from './educator-header/educator-notes/educator-notes.component';
import { EducatorNotesListComponent } from './educator-header/educator-notes/educator-notes-list/educator-notes-list.component';
import { MARKED_OPTIONS, MarkdownModule } from 'ngx-markdown';
import { markedOptionsFactory } from './markdown-options/markdown-factories';
import { NgRouteRendererComponent } from './grid/ng-route-renderer/ng-route-renderer.component';
import { NgxMaskPipe, NgxMaskDirective, provideEnvironmentNgxMask } from 'ngx-mask';
import { DateInputComponent } from './date-input/date-input.component';
import { BadgeCollectionRendererComponent } from './grid/badge-collection-renderer/badge-collection-renderer.component';
import { DateTimeInputComponent } from './date-time-input/date-time-input.component';
import { PdRequirementStateComponent } from './pd-requirement-state/pd-requirement-state.component';
import { DotsLoadingComponent } from './dots-loading/dots-loading.component';
import { ObscurityDirective } from './directives/obscurity.directive';
import { ObscuredAgGridCellRendererComponent } from './obscured-ag-grid-cell-renderer/obscured-ag-grid-cell-renderer.component';


@NgModule({
  declarations: [
    LoaderComponent,
    ConfirmationModalComponent,
    PhoneNumberPipe,
    SSNPipe,
    NumericOnlyDirective,
    ValidationErrorsDirective,
    DatePickerComponent,
    DateRangePickerComponent,
    AuditPopoverComponent,
    FormLevelValidationErrorsDirective,
    ComponentRequiresPolicyDirective,
    TooltipDirective,
    IconLinkRendererComponent,
    ScannerComponent,
    CurrencyDirective,
    MonthYearPickerComponent,
    OrderByPipe,
    GridLoaderComponent,
    IsLinkedIconRendererComponent,
    UploaderComponent,
    DocumentViewerComponent,
    DocumentReviewEducatorDetailsComponent,
    ScannerLauncherComponent,
    GridValidationErrorsDirective,
    FileSystemDirective,
    CustomSelectRendererComponent,
    CustomButtonRendererComponent,
    EducatorHeaderComponent,
    AddAlertModalComponent,
    EducatorNotesComponent,
    EducatorNotesListComponent,
    DateInputComponent,
    NgRouteRendererComponent,
    BadgeCollectionRendererComponent,
    DateTimeInputComponent,
    PdRequirementStateComponent,
    DotsLoadingComponent,
    ObscurityDirective,
    ObscuredAgGridCellRendererComponent
  ],
  imports: [
    CommonModule,
    NgxSkeletonLoaderModule.forRoot({
      animation: 'progress',
      loadingText: 'Loading...',
      ariaLabel: 'Loading'
    }),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory,
      }
    }),
    AgGridModule,
    RouterModule,
    InfiniteScrollModule,
    NgSelectModule,
    FormsModule,
    NgbModule,
    FlatpickrModule.forRoot(),
    NgbTooltipModule,
    NgbCollapseModule,
    DragDropModule,
    NgxExtendedPdfViewerModule,
    NgxMaskPipe,
    NgxMaskDirective
  ],
  exports: [
    NgxSkeletonLoaderModule,
    AgGridModule,
    InfiniteScrollModule,
    NgSelectModule,
    FormsModule,
    PhoneNumberPipe,
    SSNPipe,
    NumericOnlyDirective,
    ValidationErrorsDirective,
    FormLevelValidationErrorsDirective,
    ComponentRequiresPolicyDirective,
    DatePickerComponent,
    DateRangePickerComponent,
    AuditPopoverComponent,
    FlatpickrModule,
    NgbTooltipModule,
    DatePipe,
    CurrencyPipe,
    TooltipDirective,
    NgbCollapseModule,
    ScannerComponent,
    CurrencyDirective,
    LoaderComponent,
    GridLoaderComponent,
    MonthYearPickerComponent,
    DragDropModule,
    OrderByPipe,
    UploaderComponent,
    DocumentViewerComponent,
    DocumentReviewEducatorDetailsComponent,
    ScannerLauncherComponent,
    GridValidationErrorsDirective,
    FileSystemDirective,
    EducatorHeaderComponent,
    NgxMaskPipe,
    NgxMaskDirective,
    DateInputComponent,
    DateTimeInputComponent,
    MarkdownModule,
    PdRequirementStateComponent,
    DotsLoadingComponent,
    ObscurityDirective,
    ObscuredAgGridCellRendererComponent
  ],
  providers: [
    ConfigServiceProvider,
    DatePipe,
    CurrencyPipe,
    provideEnvironmentNgxMask()
  ]
})
export class SharedModule { }

import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable, Provider } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable, catchError, tap, throwError } from "rxjs";
import { UnhandledErrorComponent } from "src/app/unhandled-error/unhandled-error.component";
import { RouteTypeService } from "src/shared/services/route-type.service";
import { ToastService } from "src/shared/services/toast.service";

@Injectable()
export class ToastInterceptor implements HttpInterceptor {
  constructor(private toastService: ToastService, private modalService: NgbModal, private routeTypeService: RouteTypeService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const message = event.headers.get('X-Message');
          if (message) {
            this.toastService.success(message);
          }
        }
      }),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          const message = error.headers.get('X-Message');
          const errorId = error.headers.get('X-ErrorId');
          const traceId = error.headers.get('X-TraceId');

          if (message) {
            this.toastService.error(message);
          }

          if (error.status === 500 && traceId && errorId) {
            const errorPayload = { traceId, errorId };
            const modalRef = this.modalService.open(UnhandledErrorComponent, {
              backdrop: 'static',
              size: 'lg',
              centered: true
            });

            modalRef.componentInstance.traceId = errorPayload.traceId;
            modalRef.componentInstance.errorId = errorPayload.errorId;

            modalRef.result.then(x => {
              if (!x) { return; }
              this.routeTypeService.navigateToUserHomePage().subscribe();
            });
          }
        }
        return throwError(() => error);
      })
    );
  }
}
export const ToastInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: ToastInterceptor,
  multi: true
}

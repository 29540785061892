@if (validationResults$ | async) {
@if(spreadsheetErrorsDto.structuralErrors.length !== 0) {
<div class="alert alert-danger">
  <div class="h6">The following structural errors were identified on the file you provided.</div>
  <div>
    <ul>
      @for (error of spreadsheetErrorsDto.structuralErrors; track $index) {
      <li>{{error}}</li>
      }
    </ul>
  </div>
</div>
}
@else if(spreadsheetErrorsDto.worksheetErrors.length === 0)
{
<div class="alert alert-success d-flex justify-content-between align-items-center">
  <div>There were no validation errors on the file you provided. Click the "Complete Upload" to process the file.</div>
  <button type="button" class="btn btn-primary" (click)="onCompleteUpload()">Complete Upload</button>
</div>
}
@else {
<div class="alert alert-danger">There were validation errors on the file you provided. Review the errors in the tabs
  below and re-validate the file.</div>
}

@if (spreadsheetErrorsDto.structuralErrors.length === 0) {
<!-- Tabs Navigation -->
<ul class="nav nav-tabs" id="worksheetErrorTabs" role="tablist">
  <li class="nav-item h5">
    <a class="nav-link active clickable" id="tab-programs" data-bs-toggle="tab" data-bs-target="#content-programs"
      role="tab" aria-controls="content-programs" aria-selected="true">
      Programs
      @if(!programsErrors) {
      <i certTooltip="No Errors Detected" class="check-circle-icon no-errors text-success"></i>
      }
      @else {
      <i [certTooltip]="getErrorsCount(programsErrors)" class="x-circle-icon text-danger"></i>
      }
    </a>
  </li>
  <li class="nav-item h5">
    <a class="nav-link clickable" id="tab-certificates" data-bs-toggle="tab" data-bs-target="#content-certificates"
      role="tab" aria-controls="content-certificates" aria-selected="false">
      Certificates
      @if(!certificatesErrors) {
      <i certTooltip="No Errors Detected" class="check-circle-icon no-errors text-success"></i>
      }
      @else {
      <i [certTooltip]="getErrorsCount(certificatesErrors)" class="x-circle-icon text-danger"></i>
      }
    </a>
  </li>
  <li class="nav-item h5">
    <a class="nav-link clickable" id="tab-endorsements" data-bs-toggle="tab" data-bs-target="#content-endorsements"
      role="tab" aria-controls="content-endorsements" aria-selected="false">
      Endorsements
      @if(!endorsementsErrors) {
      <i certTooltip="No Errors Detected" class="check-circle-icon no-errors text-success"></i>
      }
      @else {
      <i [certTooltip]="getErrorsCount(endorsementsErrors)" class="x-circle-icon text-danger"></i>
      }
    </a>
  </li>
  <li class="nav-item h5">
    <a class="nav-link clickable" id="tab-requirements-met" data-bs-toggle="tab"
      data-bs-target="#content-requirements-met" role="tab" aria-controls="content-requirements-met"
      aria-selected="false">
      Requirements Met
      @if(!requirementsMetErrors) {
      <i certTooltip="No Errors Detected" class="check-circle-icon no-errors text-success"></i>
      }
      @else {
      <i [certTooltip]="getErrorsCount(requirementsMetErrors)" class="x-circle-icon text-danger"></i>
      }
    </a>
  </li>
</ul>

<!-- Tabs Content -->
<div class="tab-content" id="worksheetErrorContent">
  <!-- Programs Tab Content -->
  <div class="tab-pane fade show active" id="content-programs" role="tabpanel" aria-labelledby="tab-programs">
    <epp-ir-worksheet-errors [errors]="programsErrors" />
  </div>

  <!-- Certificates Tab Content -->
  <div class="tab-pane fade" id="content-certificates" role="tabpanel" aria-labelledby="tab-certificates">
    <epp-ir-worksheet-errors [errors]="certificatesErrors" />
  </div>

  <!-- Endorsements Tab Content -->
  <div class="tab-pane fade" id="content-endorsements" role="tabpanel" aria-labelledby="tab-endorsements">
    <epp-ir-worksheet-errors [errors]="endorsementsErrors" />
  </div>

  <!-- Requirements Met Tab Content -->
  <div class="tab-pane fade" id="content-requirements-met" role="tabpanel" aria-labelledby="tab-requirements-met">
    <epp-ir-worksheet-errors [errors]="requirementsMetErrors" />
  </div>
</div>
}
}

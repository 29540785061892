import { Component } from '@angular/core';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'cert-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  isLoading: boolean = false;
  constructor(private loaderService: LoaderService) {

  }

  ngOnInit() {
    this.loaderService.loaderSignal$.subscribe(x => {
      this.isLoading = x;
    })
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'cert-badge-collection-renderer',
  templateUrl: './badge-collection-renderer.component.html',
  styles: ``
})
export class BadgeCollectionRendererComponent {
  params: any;
  items: string[] = [];

  agInit(params: any): void {
    this.params = params;
    this.items = params.value.map((x: any) => params.mapCollectionToString(x));
  }

  refresh(params: any): boolean {
    return false;
  }

}

<div id="{{toastId}}" class="toast toast-{{toastConfig.type}} mt-2" role="alert" aria-live="assertive"
  aria-atomic="true">
  <div class="toast-header bg-{{toastConfig.type}} text-light">
    <strong class="me-auto">{{toastConfig.title}}</strong>
    <button type="button" class="btn-close btn-close-white" data-bs-dismiss="toast" aria-label="Close"></button>
  </div>
  <div class="toast-body">
    {{toastConfig.body}}
    <ul *ngIf="toastConfig.items && !!toastConfig.items.length">
      <li *ngFor="let item of toastConfig.items">{{item}}</li>
    </ul>
  </div>
</div>

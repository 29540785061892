import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { DocumentCodeDto, DocumentReviewDetailsDto, UpdateEducatorDocumentFromReviewCommand, ReviewStatus } from 'src/shared/services/api.service';
import { ReviewStatusOptions } from '../constants';

@Component({
  selector: 'cert-document-review-educator-details',
  templateUrl: './document-review-educator-details.component.html',
  styles: ``
})
export class DocumentReviewEducatorDetailsComponent {
  @Input() documentTypeOptions!: DocumentCodeDto[];
  @Input() detailsForDocumentReview!: DocumentReviewDetailsDto;
  @Output() documentUpdateSave = new EventEmitter<UpdateEducatorDocumentFromReviewCommand>;

  reviewStatusOptions = ReviewStatusOptions;
  reviewStatus!: ReviewStatus;
  ReviewStatus = ReviewStatus;

  fileName: string = '';
  documentTypeSelections!: DocumentCodeDto[];

  isExpanded: boolean = true;

  errorEmitter = new EventEmitter<any>();

  onSubmit() {
    let command: UpdateEducatorDocumentFromReviewCommand = {
      personDocumentId: this.detailsForDocumentReview.personDocumentId,
      fileName: this.fileName,
      reviewStatus: this.reviewStatus,
      reviewComments: this.detailsForDocumentReview.reviewComments,
      documentCodeIds: this.documentTypeSelections.map(x => x.documentCodeId!)
    }
    this.documentUpdateSave.emit(command);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.detailsForDocumentReview && this.detailsForDocumentReview) {
      this.fileName = this.detailsForDocumentReview.fileName;
      this.reviewStatus = this.detailsForDocumentReview.reviewStatus!;
      this.documentTypeSelections = this.documentTypeOptions.filter(option =>
        this.detailsForDocumentReview.documentCodeIds.includes(option.documentCodeId!)
      );
    }
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  get reviewBadgeClass() {
    return {
      'badge-success': this.reviewStatus == ReviewStatus.Reviewed,
      'badge-warning': this.reviewStatus == ReviewStatus.PendingReview,
      'badge-danger': this.reviewStatus == ReviewStatus.Rejected,
    }
  }
}


import { inject, NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { SigninRedirectCallbackComponent } from 'src/auth/signin-redirect-callback/signin-redirect-callback.component';
import { SignoutRedirectCallbackComponent } from 'src/auth/signout-redirect-callback/signout-redirect-callback.component';
import { SplashModule } from 'src/splash/splash.module';
import { AppContainerComponent } from './app-container/app-container.component';
import { HomeComponent } from './home/home.component';
import { AzdeCertRouteReuseStrategy } from './azde-cert-route-reuse.strategy';
import { IsLoggedInGuard } from './route-guards/is-logged-in.guard';
import { LeaModule } from 'src/lea/lea.module';
import { EppModule } from 'src/epp/epp.module';
import { userTypeMatchGuard } from './user-type-match.guard';
import { CertRedirectorService } from './cert-redirector.service';
import { EmptyLandingComponent } from './empty-landing/empty-landing.component';
import { HelpModule } from 'src/help/help.module';

const appRoutes: Routes = [
  {
    path: 'launch', loadChildren: () => SplashModule
  },
  {
    path: '', component: AppContainerComponent, canActivate: [IsLoggedInGuard],
    children: [
      {
        path: 'cert', canMatch: [userTypeMatchGuard], redirectTo: () => inject(CertRedirectorService).redirectUser(), pathMatch: 'full'
      },
      {
        path: 'user-type-selection', component: HomeComponent, title: 'User Type Selection'
      },
      {
        path: 'cert/no-home', component: EmptyLandingComponent, title: 'Home'
      },
      {
        path: 'cert/help', loadChildren: () => HelpModule
      },
      {
        path: 'lea', canMatch: [userTypeMatchGuard], loadChildren: () => LeaModule
      },
      {
        path: 'epp', canMatch: [userTypeMatchGuard], loadChildren: () => EppModule
      },
      { path: 'cert/lab', canMatch: [userTypeMatchGuard], loadChildren: () => import('../lab/lab.module').then(m => m.LabModule) },
      { path: 'cert/educators', canMatch: [userTypeMatchGuard], loadChildren: () => import('../educators/educators.module').then(m => m.EducatorsModule) },
      { path: 'cert/admin', canMatch: [userTypeMatchGuard], loadChildren: () => import('src/admin/admin.module').then(m => m.AdminModule) },
      { path: 'cert/services', canMatch: [userTypeMatchGuard], loadChildren: () => import('src/service-evaluations/service-evaluations.module').then(m => m.ServiceEvaluationsModule) },
      { path: 'cert/report', canMatch: [userTypeMatchGuard], loadChildren: () => import('src/report/report.module').then(m => m.ReportModule) },
      { path: 'cert/payments', canMatch: [userTypeMatchGuard], loadChildren: () => import('src/payments/payments.module').then(m => m.PaymentsModule) },
      { path: 'cert/discipline', canMatch: [userTypeMatchGuard], loadChildren: () => import('src/discipline/discipline.module').then(m => m.DisciplineModule) },
    ]
  },
  { path: 'signin-callback', component: SigninRedirectCallbackComponent },
  { path: 'signout-callback', component: SignoutRedirectCallbackComponent },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes, {
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload'
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: AzdeCertRouteReuseStrategy }
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule { }

@if (validationResults$ | async) {
  @if(spreadsheetErrorsDto.structuralErrors.length !== 0) {
  <div class="alert alert-danger">
    <div class="h6">The following structural errors were identified on the file you provided.</div>
    <div>
      <ul>
        @for (error of spreadsheetErrorsDto.structuralErrors; track $index) {
        <li>{{error}}</li>
        }
      </ul>
    </div>
  </div>
  }
  @else if(spreadsheetErrorsDto.worksheetErrors.length === 0)
  {
  <div class="alert alert-success d-flex justify-content-between align-items-center">
    <div>There were no validation errors on the file you provided. Click the "Complete Upload" to process the file.</div>
    <button type="button" class="btn btn-primary" (click)="onCompleteUpload()">Complete Upload</button>
  </div>
  }
  @else {
  <div class="alert alert-danger">There were validation errors on the file you provided. Review the errors in the tabs
    below and re-validate the file.</div>
  }

  @if (spreadsheetErrorsDto.structuralErrors.length === 0) {
  <!-- Tabs Navigation -->
  <ul class="nav nav-tabs" id="worksheetErrorTabs" role="tablist">
    <li class="nav-item h5">
      <a class="nav-link active clickable" id="tab-students" data-bs-toggle="tab" data-bs-target="#content-students"
        role="tab" aria-controls="content-students" aria-selected="true">
        Students
        @if(!errors) {
        <i certTooltip="No Errors Detected" class="check-circle-icon no-errors text-success"></i>
        }
        @else {
        <i [certTooltip]="getErrorsCount(errors)" class="x-circle-icon text-danger"></i>
        }
      </a>
    </li>
  </ul>

  <!-- Tabs Content -->
  <div class="tab-content" id="worksheetErrorContent">
    <!-- Students Tab Content -->
    <div class="tab-pane fade show active" id="content-students" role="tabpanel" aria-labelledby="tab-students">
      <epp-ir-worksheet-errors [errors]="errors" />
    </div>
  </div>
  }
}

<div class="modal-content">
  <div class="modal-header bg-primary-100">
    <h5 class="modal-title">{{ header }}</h5>
  </div>
  <div class="modal-body">
    <p>{{ message }}</p>
    <form #addCohortForm="ngForm">
      <div class="row">
        <div class="col-6 form-group">
          <label for="fiscalYear">Year</label>
          <input #fiscalYear="ngModel" type="text" class="form-control" id="fiscalYear" name="fiscalYear"
            [(ngModel)]="fiscalYearInput" numericOnly [maxLength]="4">
          <div validationErrors [for]="fiscalYear.control" [errorEmitter]="errorEmitter"></div>
        </div>
        <div class="col-6 form-group">
          <label for="description">Description</label>
          <input #description="ngModel" type="text" class="form-control" id="description" name="description"
            [(ngModel)]="descriptionInput">
          <div validationErrors [for]="description.control" [errorEmitter]="errorEmitter"></div>
        </div>
      </div>
      <div formLevelValidationErrors [for]="addCohortForm" [errorEmitter]="errorEmitter"></div>
    </form>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
    @if(isUpdate) {
      <button type="button" class="btn btn-primary" (click)="onUpdateCohort()">Update Cohort</button>
    }
    @else {
      <button type="button" class="btn btn-primary" (click)="onAddCohort()">Add Cohort</button>
    }
  </div>
</div>

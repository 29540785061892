<form #form="ngForm" autocomplete="off">
  <div class="row align-items-end">
    <div class="col-sm">
      <div class="form-group">
        <label for="lastName" class="form-label">Last Name</label>
        <input #lastName="ngModel" id="lastNameSearch" type="text" class="form-control field"
          [(ngModel)]="searchRequest.lastName" name="lastName">
      </div>
    </div>
    <div class="col-sm">
      <div class="form-group">
        <label for="firstName" class="form-label">First Name</label>
        <input id="firstName" type="text" class="form-control field" [(ngModel)]="searchRequest.firstName"
          name="firstName">
      </div>
    </div>
    <div class="col-sm">
      <div class="form-group">
        <label for="middleName" class="form-label">Middle Name</label>
        <input id="middleName" type="text" class="form-control field" [(ngModel)]="searchRequest.middleName"
          name="middleName">
      </div>
    </div>
    <div class="col-sm">
      <div class="form-group" autocomplete="off">
        <label for="phoneNumber" class="form-label">Phone Number</label>
        <input id="phoneNumber" type="text" class="form-control" [(ngModel)]="searchRequest.phoneNumber"
          name="phoneNumber" numericOnly>
      </div>
    </div>
  </div>
  <div class="row align-items-end">
    <div class="col-sm">
      <div class="form-group">
        <label for="ein" class="form-label">EIN</label>
        <input id="ein" type="text" class="form-control" [(ngModel)]="searchRequest.ein" name="ein" numericOnly>
      </div>
    </div>
    <div class="col-sm">
      <div class="form-group">
        <label for="dateOfBirth" class="form-label">Date of Birth</label>
        <cert-date-input name="dateOfBirth" [(model)]="dateOfBirthString" [propertyName]="'dateOfBirth'"/>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="form-group">
        <label for="email" class="form-label">Email</label>
        <input id="email" type="email" class="form-control email-field" [(ngModel)]="searchRequest.email" name="email">
      </div>
    </div>
    <div class="col-sm">
      <div class="form-group">
        <label for="ssn" class="form-label">Social Security</label>
        <input id="ssn" type="text" class="form-control" [(ngModel)]="searchRequest.ssn" name="ssn" numericOnly>
      </div>
    </div>
  </div>
</form>


<ngx-skeleton-loader *ngIf="searching" count="5"></ngx-skeleton-loader>

<!--results-->
<div *ngIf="!searching" class="table-container d-flex flex-column">
  <div *ngIf="educators && educators.length !== 0" infinite-scroll [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
    [scrollWindow]="false" [infiniteScrollDistance]="2" class="table-responsive">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Social Security</th>
          <th scope="col">EIN</th>
          <th scope="col">Date of Birth</th>
          <th scope="col">Phone Number</th>
          <th scope="col">Email</th>
        </tr>
      </thead>
      <tbody>
        <tr scope="row" *ngFor="let educator of educators" class="educator-row"
          (click)="goToEducatorManagement(educator.personId)">
          <td>
            {{educator.educatorName}}
            <ng-template #alternateNamesPopoverContent>
              <p class="popover-spacing" *ngFor="let alternateName of educator.alternateNames">{{alternateName}}</p>
            </ng-template>
            <i *ngIf="educator.alternateNames && educator.alternateNames.length" class="bi bi-info-circle pointer"
              [ngbPopover]="alternateNamesPopoverContent" (click)="stopPropagation($event)"
              popoverTitle="Alternate Names" certTooltip="Alternate Names">
            </i>
          </td>
          <td>{{educator.ssn | SSN: true}}</td>
          <td>{{educator.ein}}</td>
          <td>{{educator.dateOfBirth | date: 'MM/dd/yyyy'}}</td>
          <td>{{educator.lastActivePhoneNumber | phoneNumber}}
            <ng-template #phoneNumbersPopoverContent>
              <p class="popover-spacing" *ngFor="let phoneNumber of educator.alternatePhoneNumbers">{{phoneNumber |
                phoneNumber}}</p>
            </ng-template>
            <i *ngIf="educator.phoneNumbers && educator.alternatePhoneNumbers.length" class="bi bi-info-circle pointer"
              [ngbPopover]="phoneNumbersPopoverContent" (click)="stopPropagation($event)" popoverTitle="Phone Numbers">
            </i>

          </td>
          <td>{{educator.lastActiveEmail}}
            <ng-template #emailsPopoverContent>
              <p class="popover-spacing" *ngFor="let email of educator.alternateEmails">{{email}}</p>
            </ng-template>
            <i *ngIf="educator.emails && educator.alternateEmails.length" class="bi bi-info-circle pointer"
              [ngbPopover]="emailsPopoverContent" (click)="stopPropagation($event)" popoverTitle="Emails">
            </i>
          </td>
        </tr>
        <tr>
          <td colspan="6" class="text-center">
            <p *ngIf="!endOfResults" class="text-secondary my-auto">Scroll down for more results</p>
            <p *ngIf="endOfResults" class="text-secondary my-auto">No more results exist</p>
          </td>
        </tr>
      </tbody>
    </table>

    <ngx-skeleton-loader *ngIf="scrollSearching" count="5"></ngx-skeleton-loader>
  </div>
  <div *ngIf="educators && educators.length === 0" class="my-2 d-flex justify-content-center">
    <h5>No Results</h5>
  </div>
</div>

import { APP_INITIALIZER, Injectable, Provider } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { CertificationClientConfigDto, ConfigClient } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  config!: CertificationClientConfigDto;

  constructor(private configClient: ConfigClient) { }

  async loadConfig(): Promise<CertificationClientConfigDto> {
    this.config = await firstValueFrom(this.configClient.clientConfig())

    return this.config;
  }

  get fiscalYearDescription(): string | undefined {
    const fiscalYearDescription = this.config.fiscalYearDescription;

    if (!fiscalYearDescription) {
      return undefined;
    }

    return fiscalYearDescription;
  }
}

export const ConfigServiceProvider: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  deps: [ConfigService],
  useFactory: (appConfigSvc: ConfigService) => {
    return async () => {
      await appConfigSvc.loadConfig();
    };
  }
}

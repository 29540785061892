import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CertPolicies } from 'src/auth/cert-policies';
import { AuthService } from 'src/auth/services/auth.service';

@Component({
  selector: 'epp-cohort-management-actions-cell-renderer',
  templateUrl: './cohort-management-actions-cell-renderer.component.html',
  styles: ``
})
export class CohortManagementActionsCellRendererComponent implements ICellRendererAngularComp {
  private params: any;

  hasRemoveStudentPolicy: boolean = this.authService.hasPolicy(CertPolicies.CanRemoveStudentFromCohort);
  hasAuthorizedProgram!: boolean;
  personId!: number;

  constructor(private authService: AuthService) {}

  agInit(params: any): void {
    this.params = params;
    this.personId = this.params.data.personId;
    this.hasAuthorizedProgram = this.params.data.hasAuthorizedProgram;
  }

  viewStudentDetails() {
    this.params.context.componentParent.viewStudentDetails(this.params.data.ein);
  }

  removeStudentFromCohort() {
    this.params.context.componentParent.removeStudentFromCohort(
      this.params.data.cohortStudentId,
      this.params.data.cohortId,
      this.params.data.firstName,
      this.params.data.lastName,
      this.params.data.ein
    );
  }

  refresh(params: any): boolean {
    return false;
  }
}

import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cert-verify-pd-confirmation',
  templateUrl: './verify-pd-confirmation.component.html',
  styles: ``
})
export class VerifyPdConfirmationComponent {
  selectedPersons!: string[];
  header!: string;

  constructor(private activeModal: NgbActiveModal) { }

  onConfirm(confirmed: boolean): void {
    this.activeModal.close(confirmed);
  }
}

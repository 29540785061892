import { Component, EventEmitter, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject, debounceTime, takeUntil } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EducatorSearchResultDto, GetEducatorSearchQuery, SearchClient } from 'src/shared/services/api.service';
import { DateService } from 'src/shared/services/date.service';
import { Router } from '@angular/router';
import { RouteTypeService } from 'src/shared/services/route-type.service';


@Component({
  selector: 'cert-educator-search-modal',
  templateUrl: './educator-search-modal.component.html',
  styleUrls: ['./educator-search-modal.component.scss']
})
export class EducatorSearchComponent {
  @ViewChild('form', { static: true }) ngForm!: NgForm;
  @Input() searchModalShown!: boolean;

  searching: boolean = false;
  scrollSearching: boolean = false;
  formChangesSubscription: any;
  educators: EducatorSearchResultDto[] | undefined = undefined;
  dateOfBirthString!: string;

  @Input()
  showCreateButton: boolean = false;

  @Input()
  previousSearchRequest: GetEducatorSearchQuery = {
    pageNumber: 1,
    pageSize: 10
  };

  private cancelSearch$ = new Subject<void>();

  searchRequest: GetEducatorSearchQuery = {
    pageNumber: 1,
    pageSize: 10
  }

  endOfResults: boolean = false;

  @Output() toggleCreateButton = new EventEmitter<CreateEducatorParams>();

  constructor(
    private searchClient: SearchClient,
    private router: Router,
    private dateService: DateService,
    private activeModalService: NgbActiveModal,
    private renderer: Renderer2,
    private routeTypeService: RouteTypeService) {
  }

  ngOnInit() {
    if (this.previousSearchRequest) {
      this.searchRequest = this.previousSearchRequest;

      if (this.searchRequest.dateOfBirth) {
        this.dateOfBirthString = this.searchRequest.dateOfBirth.toLocaleDateString();
      }
    }

    this.formChangesSubscription = this.ngForm.form.valueChanges.pipe(debounceTime(1000)).subscribe(input => {
      if (input.phoneNumber === '') {
        input.phoneNumber = null;
      }

      if (input.dateOfBirth && this.dateService.isValidDate(this.dateOfBirthString)) {
        this.searchRequest.dateOfBirth = new Date(input.dateOfBirth);
      }

      if (!this.isInputEmpty(input)) {

        this.getSearchResults(input);
      }
      else {
        this.educators = undefined;
      }
    })
  }

  ngAfterViewInit() {
    this.renderer.selectRootElement('#lastNameSearch').focus();
  }

  ngOnDestroy() {
    this.formChangesSubscription.unsubscribe();
  }

  getSearchResults(searchRequest: GetEducatorSearchQuery) {
    this.endOfResults = false;
    this.cancelSearch$.next();

    this.searching = true;
    this.searchRequest.pageNumber = 1;

    if (this.dateOfBirthString && this.dateService.isValidDate(this.dateOfBirthString)) {
      searchRequest.dateOfBirth = new Date(this.dateOfBirthString);
    }
    else {
      searchRequest.dateOfBirth = undefined;
    }

    this.searchClient.search(searchRequest)
      .pipe(takeUntil(this.cancelSearch$))
      .subscribe({
        next: (result) => {
          this.educators = result.educatorSearchResults;
          if (result.educatorSearchResults && result.educatorSearchResults.length < this.searchRequest.pageSize) {
            this.endOfResults = true;
          }
          this.toggleCreateButton.emit({ searchQuery: this.searchRequest, dateStruct: this.dateOfBirthString });
        },
        complete: () => this.searching = false
      });
  }

  onScroll() {
    if (!this.endOfResults) {
      this.scrollSearching = true;
      this.searchRequest.pageNumber++;

      this.searchClient.search(this.searchRequest).subscribe({
        next: (result) => {
          if (result?.educatorSearchResults?.length) {
            this.endOfResults = false;
            this.educators = this.educators?.concat(result.educatorSearchResults);

            if (result.educatorSearchResults.length < this.searchRequest.pageSize) {
              this.endOfResults = true;
            }
          }
        },
        complete: () => this.scrollSearching = false
      });
    }
  }

  clearFormInputs() {
    this.cancelSearch$.next();

    this.searchRequest = {
      pageNumber: 1,
      pageSize: 10
    };
    this.ngForm.reset();

    this.searching = false;
    this.endOfResults = false;
  }

  isInputEmpty(input: GetEducatorSearchQuery): Boolean {

    if (this.doesMeetsSearchRequirements(input.lastName, 3)) return false;
    if (this.doesMeetsSearchRequirements(input.firstName, 2)) return false;
    if (this.doesMeetsSearchRequirements(input.middleName, 1)) return false;
    if (this.doesMeetsSearchRequirements(input.phoneNumber, 3)) return false;
    if (this.doesMeetsSearchRequirements(input.email, 3)) return false;
    if (this.doesMeetsSearchRequirements(input.ein, 7)) return false;
    if (this.doesMeetsSearchRequirements(input.ssn, 4)) return false;
    if (input.dateOfBirth !== null && input.dateOfBirth !== undefined && input.dateOfBirth.toString().length > 8 &&
      !isNaN(new Date(input.dateOfBirth).getTime())) return false;

    return true;
  }

  doesMeetsSearchRequirements(input: string | undefined, length: number): Boolean {
    return !input || (input && input!.length < length) ? false : true;
  }

  goToEducatorManagement(personId: number) {
    this.activeModalService.close();
    this.routeTypeService.navigateToEducatorManagement(personId);
  }

  stopPropagation(event: MouseEvent): void {
    // stop the click from propagating to the parent
    // so that we can still see the popovers while the row is clickable.
    if (event) {
      event.stopPropagation();
    }
  }
}

export interface CreateEducatorParams {
  searchQuery: GetEducatorSearchQuery,
  dateStruct: string
}

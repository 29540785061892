import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'lea-roster-actions-cell-renderer',
  templateUrl: './roster-actions-cell-renderer.component.html',
  styles: ``
})
export class RosterActionsCellRendererComponent implements ICellRendererAngularComp {
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  viewDetails() {
    this.params.context.componentParent.viewDetails(this.params.data.personId);
  }

  removeFromRoster() {
    let educatorName: string = `${this.params.data.firstName} ${this.params.data.lastName}`;
    this.params.context.componentParent.removeFromRoster(
      this.params.data.personId,
      educatorName,
      this.params.data.ein,
      this.params.data.schoolDescription,
      this.params.data.leaDescription
    );
  }

  refresh(params: any): boolean {
    return false;
  }

  get canRemoveFromRoster() {
    return this.params.data.isManualEducatorEmployment;
  }
}

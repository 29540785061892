import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigClient } from 'src/shared/services/api.service';

@Component({
  selector: 'cert-unhandled-error',
  templateUrl: './unhandled-error.component.html',
  styles: ``
})
export class UnhandledErrorComponent {
  @Input() traceId!: string;
  @Input() errorId!: string;

  constructor(private activeModal: NgbActiveModal, private configClient: ConfigClient) { }

  onReportError() {
    this.configClient.reportError({
      site: 'Certification',
      traceId: this.traceId,
      errorId: this.errorId
    }).subscribe(() => {
      this.activeModal.close(true);
    })
  }

  onCancel() {
    this.activeModal.close(false);
  }
}

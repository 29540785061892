import { Component } from '@angular/core';

@Component({
  selector: 'cert-ng-route-renderer',
  template: `
    <a [routerLink]="route" target="_blank">{{ cellValue }}</a>
  `,
})
export class NgRouteRendererComponent {
  public cellValue!: string;
  public route!: string;

  agInit(params: any): void {
    this.cellValue = params.value;
    this.route = params.route;
  }

  refresh(params: any): boolean {
    return false;
  }
}

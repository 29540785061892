import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { TokenKey } from 'src/shared/constants';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JwtService {
  userTypes$ = new BehaviorSubject<string[]>([]);

  constructor(private localStorageService: LocalStorageService) {
    this.updateUserTypes();
  }

  setToken(token: string) {
    if (token) {
      this.localStorageService.set(TokenKey, token);

      this.updateUserTypes();
    }
  }

  decodeToken(): { [key: string]: string } | undefined {
    let token = this.localStorageService.get(TokenKey);

    if (!token) {
      return;
    }

    return jwtDecode(token);
  }

  getToken(): string | null {
    return this.localStorageService.get(TokenKey);
  }

  removeToken(): void {
    this.localStorageService.remove(TokenKey);
  }

  isTokenExpired(): boolean {
    const token = this.decodeToken();

    if (!token) {
      return false;
    }

    const expiryTime: number = parseInt(token.exp);

    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    } else {
      return false;
    }
  }

  isTokenRenewable(): boolean {
    const token = this.decodeToken();

    if (!token) {
      return false;
    }

    const refreshExpiry: number = parseInt(token.RefreshExpiry);

    if (refreshExpiry) {
      return ((1000 * refreshExpiry) - (new Date()).getTime()) > 5000;
    } else {
      return false;
    }
  }

  getUserId(): number | undefined {
    const token = this.decodeToken();

    if (!token?.sub) {
      return undefined;
    }

    return parseInt(token.sub);
  }

  getUserEmail(): string | undefined {
    const token = this.decodeToken();

    if (!token?.email) {
      return undefined;
    }

    return token.email;
  }

  getAuthMethod() {
    const token = this.decodeToken();

    if (!token?.authmethod) {
      return undefined;
    }

    return token.authmethod;
  }

  get currentEdOrgId(): number | undefined {
    const token = this.decodeToken();

    if (!token?.EdOrgId) {
      return undefined;
    }

    return parseInt(token.EdOrgId);
  }

  get currentEdOrgName(): string | undefined {
    const token = this.decodeToken();

    if (!token?.EdOrgName) {
      return undefined;
    }

    return token.EdOrgName;
  }

  get roles(): string[] {
    const token = this.decodeToken();

    if (!token?.role) {
      return [];
    }

    if (typeof token.role === 'string') {
      return [token.role];
    }

    if (Array.isArray(token.role)) {
      return token.role;
    }

    return [];
  }

  get userTypes(): string[] {
    const token = this.decodeToken();

    if (!token?.UserTypes) {
      return [];
    }

    if (typeof token.UserTypes === 'string') {
      return token.UserTypes.trim() ? [token.UserTypes] : [];
    }

    if (Array.isArray(token.UserTypes)) {
      return (token.UserTypes as string[]).filter((type: string) => type.trim() !== '');
    }

    return [];
  }

  updateUserTypes() {
    this.userTypes$.next(this.userTypes);
  }
}


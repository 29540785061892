import { ReviewStatus } from "./services/api.service";

export const PolicySetKey: string = 'POLICY_SET';
export const TokenKey: string = 'LOCAL_TOKEN';
export const Production: string = 'Production';
export enum AuthenticationMethods {
  adeConnect = 'adeConnect',
  test = 'test'
}
export const MinAge: number = 15;
export const MaxAge: number = 150;

export enum FormEvents {
  Successful,
  Error,
  Cancelled
}
export const AzStateCode: string = 'AZ';
export const DeclinedToAnswerCode: string = 'D';
export enum ApplicationGroupCodes {
  Add = 'ADD',
  Maintain = 'MNT',
  Other = 'OTH'
}
export enum CertificateLevelCodes {
  Alt = 'alt',
  Interim = 'interim',
  STIC = 'stic',
  Emerg = 'emerg',
  Intl = 'intl'
}
export enum ApplicationOptionCodes {
  AdCert = 'adcert',
  Adda = 'adaa',
  Adend = 'adend',
  Adircert = 'adircert',
  Adirend = 'adirend',
  Ren = 'ren',
  Defrmv = 'defrmv',
  Ext = 'ext',
  Nmchng = 'nmchng',
  Copy = 'copy',
  Ssnchng = 'ssnchng',
}
export enum ServiceCodes {
  AddCert = '25',
  AddArea = '23',
  AddEndorsement = '21',
  AddIRCert = '88',
  AddIREndorsement = '26',
  Renewal = '74',
  DeficiencyRemoval = '77',
  Extension = '120',
  NameChange = '75',
  CopyCert = '76',
  SSNChange = '109',
  Alignment = '119'
}
export enum ContactTypeCodes {
  None = 'NN',
  Phone = 'PH',
  Email = 'EM',
  Counter = 'CTR',
  Offsite = 'OS'
}
export enum NoteTypes {
  EducatorNoteDto = 'EducatorNoteDto',
  EducatorSystemEventDto = 'EducatorSystemEventDto',
  EducatorAlertDto = 'EducatorAlertDto'
}

export const ScannerResourcesPath = 'assets/dwt-resources';

//This is a trial product key
export const ScannerProductKey = `t01898AUAAFlArbe7V1PZvzHhcqWEYaWbBNvWqea1hcZeBjJlZmr2zEROsnJRGnBdmiq4MCiT7auzAnekIjevDjEgGhSNQHT2+OfEAKf0dwr1d/IAJ245iaZ1GMtuu9+8PAEZeK2AbMdhA1gDpZYGePtaG1sALEAMQKwa2AJOV3H8+KA2IPrtlQ1dnBjglP5OHZA+Th7gxC1nCkiI5Oa02rkEhPXJ2QGwADkFuP7IDgGhFoAFyAGAD4Lg5Qtu0yrg`;

export const ApplicationBuildingStatus = 'Building';

export enum ApplicationStatus {
  Building = 'building',
  Accepted = 'accepted',
  ReturnedIncomplete = 'returned incomplete',
  Open = 'open',
  Closed = 'closed'
}

export const AccreditedInstitutionOtherCode = 'other';
export const NonePaymentTypeCode = 'none';
export const ValidStatus = 'valid';
export const ValidManualStatus = 'valid - manually entered';

export const ValidCertificateStatusCode = 'VAL';

export enum ServiceStatus {
  Received = 'Received',
  Cancelled = 'Cancelled',
  AdministrativeIncomplete = 'AdministrativeIncomplete',
  SubstantiveIncomplete = 'SubstantiveIncomplete',
  SubstantiveReview = 'SubstantiveReview',
  Denied = 'Denied',
  Appealed = 'Appealed',
  Issued = 'Issued'
}

export enum MyServicesFilters {
  HasMaterialsReceived = 'hasMaterialsReceived',
  IsAIExpiringSoon = 'isAIExpiringSoon',
  IsSIExpiringSoon = 'isSIExpiringSoon',
  IsARExpiringSoon = 'isARExpiringSoon'
}

export enum ServiceItemsAdminFilters {
  AppealedStatus = 'hasAppealedStatus',
  FinalSIReviewStatus = 'hasFinalSIReviewStatus'
 }

export enum PaymentReversalReportFilters {
  Is30daysOld = 'is30DaysOld',
  Is90daysOld = 'is90DaysOld',
  IsYearOld = 'isYearOld',
  IsInCollections = 'isInCollections'
}

export enum FingerprintCardValidityStatus {
  Valid = 'Valid',
  NotValid = 'Not Valid',
  Pending = 'Pending',
  NotOnFile = 'Not On File',
  Expired = 'Expired'
}

export enum PaymentTypes {
  Check = 'Check',
  CreditCard = 'Credit Card',
  MoneyOrder = 'Money Order',
  NoPayment = 'No Payment',
  Redeposit = 'Redeposit'
}

export enum SubRequirementGroupType {
  Alternative = 0,
  Composite = 1
}

export const FullEndorsementLevel: string = 'full';

export enum RouteType {
  Primary,
  Cert,
  Lea,
  Epp
}

export enum UserType {
  Cert = "cert",
  Lea = "lea",
  Epp = "epp"
}

export enum LeaEducatorRosterFilters {
  HasMissingIvpFingerprint = 'ivpStatus',
  NeedsProfessionalDevelopment = 'needsProfessionalDevelopment',
  HasRenewableFilters = 'hasRenewableCertificates',
  HasDeficiencies = 'hasDeficiencies'
}

export enum MimeType {
  PDF = 'application/pdf',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV = 'text/csv',
}

export enum IRSpreadsheetNames {
  Programs = 'Programs',
  Certificates = 'Certificates',
  Endorsements = 'Endorsements',
  RequirementsMet = 'RequirementsMet'
}


export const ReviewStatusOptions = [
  { value: ReviewStatus.Reviewed, label: 'Reviewed' },
  { value: ReviewStatus.PendingReview, label: 'Pending Review' },
  { value: ReviewStatus.Rejected, label: 'Rejected' },
];

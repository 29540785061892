import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashLayoutComponent } from './splash-layout/splash-layout.component';
import { TosComponent } from './pages/tos/tos.component';
import { SplashRoutingModule } from './splash-routing.module';
import { SelectorComponent } from './pages/selector/selector.component';
import { EntityConfiguratorComponent } from './pages/entity-configurator/entity-configurator.component';
import { SharedModule } from 'src/shared/shared.module';


@NgModule({
  declarations: [
    SplashLayoutComponent,
    TosComponent,
    SelectorComponent,
    EntityConfiguratorComponent
  ],
  imports: [
    CommonModule,
    SplashRoutingModule,
    SharedModule
  ]
})
export class SplashModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SplashLayoutComponent } from './splash-layout/splash-layout.component';
import { TosComponent } from './pages/tos/tos.component';
import { SelectorComponent } from './pages/selector/selector.component';
import { EntityConfiguratorComponent } from './pages/entity-configurator/entity-configurator.component';
import { TestModeGuard } from 'src/auth/test-mode.guard';

const moduleRoutes: Routes = [
  {
    path: '', component: SplashLayoutComponent,
    children: [
      { path: '', component: TosComponent, title: 'ADE Terms of Service' },
      {
        path: 'selector', component: SelectorComponent, title: 'Login Selector',
        canActivate: [TestModeGuard]
      },
      {
        path: 'configure', component: EntityConfiguratorComponent, title: 'Configure Test User',
        canActivate: [TestModeGuard]
      }
    ]
  }
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(moduleRoutes)
  ],
  exports: [
    RouterModule
  ]
})
export class SplashRoutingModule { }

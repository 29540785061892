import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'cert-obscured-ag-grid-cell-renderer',
  template: `<span obscure>{{ params.value }}</span>`,
  styles: ``
})
export class ObscuredAgGridCellRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  agInit(params: any): void {
    this.params = params;
  }
  refresh(params: ICellRendererParams<any, any, any>): boolean {
    return false;
  }
}

import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { JwtService } from 'src/auth/services/jwt.service';
import { AuthClient, AvailableEdOrgDto } from 'src/shared/services/api.service';

@Component({
  selector: 'cert-ed-org-selection',
  templateUrl: './ed-org-selection.component.html',
  styles: ``
})
export class EdOrgSelectionComponent {
  dataLoaded: boolean = false;
  selectedEdOrgId!: number;
  edOrgOptions: AvailableEdOrgDto[] = [];
  currentEdOrgId!: number;
  currentEdOrgName!: string;

  constructor(private activeModal: NgbActiveModal, private authClient: AuthClient, private jwtService: JwtService) {
  }

  ngOnInit() {
    this.currentEdOrgId = this.jwtService.currentEdOrgId!;
    this.currentEdOrgName = this.jwtService.currentEdOrgName!;

    this.authClient.getAvailableEdOrgs().subscribe(x => {
      this.edOrgOptions = x;
      this.dataLoaded = true;
    });
  }

  selectEdOrg(edOrg: AvailableEdOrgDto) {
    this.selectedEdOrgId = edOrg.edOrgId;
  }

  onChange() {
    this.activeModal.close(this.selectedEdOrgId);
  }

  close() {
    this.activeModal.dismiss();
  }
}

<div class="row mb-2">
  <div class="col-6">
    <h2 class="h4">Cohort Listing<i class="info-icon ms-2" certTooltip="Each cohort is a grouping to allow you to manage candidates at your institution."></i></h2>
  </div>
  <div class="col-6">
    <button type="button" class="btn btn-primary float-end" certTooltip="Add a new cohort" requiresPolicy="canAddCohort"
      (click)="onAddNewCohort()">Add New Cohort</button>
    <button type="button" class="btn btn-primary float-end me-2" certTooltip="Reset any filters applied to the grid below."
      (click)="resetFilters()">Reset Filters</button>
  </div>
</div>
<epp-cohorts-listing-grid />

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { RolePermissionAssignmentComponent } from './role-permission-assignment/role-permission-assignment.component';
import { AdminRoutingModule } from './admin-routing.module';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/shared/shared.module';
import { AdminHelpComponent } from './admin-help/admin-help.component';
import { HelpAddFormComponent } from './admin-help/help-add-form/help-add-form.component';
import { HelpResultsComponent } from './admin-help/help-results/help-results.component';


@NgModule({
  declarations: [
    AdminLayoutComponent,
    RolePermissionAssignmentComponent,
    AdminHelpComponent,
    HelpAddFormComponent,
    HelpResultsComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    SharedModule
  ]
})
export class AdminModule { }

import { NgForm } from "@angular/forms";
import { Observable } from "rxjs";
import { camelCase } from "lodash";
import { EventEmitter } from '@angular/core';

export function catchValidationErrors(form: NgForm | undefined, validationErrorsEvent: EventEmitter<any>) {
  return function <T>(source: Observable<T>): Observable<T> {
    return new Observable(observer => {
      source.subscribe({
        next(value) {
          validationErrorsEvent.emit(null); // Clear any previous errors
          observer.next(value);
        },
        error(error: any) {
          if (error.status === 422) {
            const errors = JSON.parse(error.response);
            const modelErrors = [];
            const allErrors = [];
            for (const key of Object.keys(errors)) {
              const control = form?.controls[camelCase(key)];
              // Consider form-controls with acronym names here. "reportedEIN" will come out as "reportedEin,"
              // so you'll want to name your form-controls appropriately
              control?.setErrors(errors[key]);
              if (control) {
                control.setErrors(errors[key]);
                allErrors.push(errors[key]);
              } else {
                modelErrors.push(errors[key]);
              }
            }
            validationErrorsEvent.emit({ modelErrors, allErrors });
          }

          observer.error(error);
        },
        complete() {
          observer.complete();
        }
      })
    })
  }
}

export function catchGridValidationErrors(validationErrorsEvent: EventEmitter<string[]>) {
  return function <T>(source: Observable<T>): Observable<T> {
    return new Observable(observer => {
      source.subscribe({
        next(value) {
          observer.next(value);
        },
        error(error: any) {
          console.log('Error found:', error)
          if (error.status === 422) {
            console.log('Handling validation errors...');
            const errors = mapErrorsFromResponse(error);
            validationErrorsEvent.emit(errors);
          }

          observer.error(error);
        },
        complete() {
          observer.complete();
        }
      });
    });
  };
}

function mapErrorsFromResponse(error: any): string[] {
  const errorsDict = JSON.parse(error.response);
  const errorsArray: string[][] = Object.values(errorsDict);
  return errorsArray.reduce<string[]>((acc, curr) => acc.concat(curr), []);
}

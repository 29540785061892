<div class="modal-content">
  <div class="modal-header">
    <h3>Role Information</h3>
    <div class="d-flex">
      <button type="button" class="btn-close " data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
    </div>
  </div>
  <div class="modal-body m-2">
    <dl class="fs-5">
      <dt>Education Organization</dt>
      <dd>{{currentEdOrgName}}</dd>
      <dt>Role(s)</dt>
      <dd>
        <ul>
          @for(role of currentRoles; track role) {
          <li>{{role}}</li>
          }
        </ul>
      </dd>
      <dt>User Type(s)</dt>
      <dd>
        <ul>
          @for(type of currentUserTypes; track type) {
          <li>{{type}}</li>
          }
        </ul>
      </dd>
    </dl>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-outline-primary" (click)="close()">Close</button>
  </div>
</div>

<div class="d-flex justify-content-between my-3">
  <div>
    <button type="button" class="btn btn-sm btn-outline-primary me-2" (click)="onViewDocumentInNewTab()" certTooltip="Open In New Tab (Ctrl + O)"><i
        class="bi bi-box-arrow-up-right"></i> Open In Tab </button>
    <button type="button" class="btn btn-sm btn-outline-primary" (click)="onRotate()" certTooltip="Rotate"><i
        class="bi bi-arrow-clockwise"></i> Rotate</button>
  </div>

  <div class="btn-group d-flex" role="group" aria-label="document zoom">
    <button type="button" class="btn btn-sm btn-outline-primary flex-grow-1" (click)="onZoomIn()" certTooltip="Zoom In (Ctrl + +)"><i
        class="bi bi-zoom-in"></i> Zoom In</button>
    <button type="button" class="btn btn-sm btn-outline-primary flex-grow-1" (click)="onZoomOut()" certTooltip="Zoom Out (Ctrl + -)"><i
        class="bi bi-zoom-out"></i> Zoom Out</button>
  </div>


  <div class="btn-group d-flex" role="group" aria-label="document navigation">
    <button type="button" class="btn btn-sm btn-outline-primary flex-grow-1" (click)="onPreviousDocument()" certTooltip="Zoom In (Ctrl + Left Arrow)"><i
        class="bi bi-caret-left"></i> Previous
      Document</button>
    <button type="button" class="btn btn-sm btn-outline-primary flex-grow-1" (click)="onNextDocument()" certTooltip="Zoom In (Ctrl + Right Arrow)">Next Document <i
        class="bi bi-caret-right"></i></button>
  </div>

</div>

<ngx-extended-pdf-viewer [src]="pdfBlob" [height]="'800px'" [showToolbar]="false" [scrollMode]="1"
  [(rotation)]="documentAngle" [(zoom)]="zoomLevel">
</ngx-extended-pdf-viewer>

<ul class="navbar-nav flex-grow">
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/epp/cohorts']" [routerLinkActive]="['link-active']" role="button"
      aria-expanded="false">Cohorts</a>
  </li>
  <li class="nav-item">
    <a class="nav-link clickable" [routerLink]="['/epp/help']" [routerLinkActive]="['link-active']" role="button"
      aria-expanded="false">Help</a>
  </li>
</ul>

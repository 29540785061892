<div class="container">
  <div class="d-flex justify-content-between align-items-center">
    <span class="h2">System Help Objects</span>
    @if(!isAddMode){
      <button type="button" class="btn btn-outline-primary" (click)="toggleAddMode()">Add Help Object</button>
    }
  </div>

  @if(isAddMode){
    <cert-help-add-form (helpFormUpdate)="handleHelpObjectAdded($event)"/>
  }

  <hr>

  @if(dataLoaded) {
    <cert-help-results [helpObjects]="helpObjects" (helpFormUpdate)="handleHelpObjectAdded($event)"/>
  }
</div>

export enum CertPolicies {
  CanViewLab = "canViewLab",
  CanUpdateLab = "canUpdateLab",
  CanMapRolePermissions = "canMapRolePermissions",
  CanViewDataDictionary = "canViewDataDictionary",
  CanViewPersons = "canViewPersons",
  CanCreatePersons = "canCreatePersons",
  CanUpdatePersons = "canUpdatePersons",
  CanDeletePersons = "canDeletePersons",
  CanReviewDocuments = "canReviewDocuments",
  CanModifySSNPersons = "canModifySSNPersons",
  CanMergePersons = "canMergePersons",
  CanViewPassedTests = "canViewPassedTests",
  CanViewTests = "canViewTests",
  CanCreateManualTests = "canCreateManualTests",
  CanUpdateManualTests = "canUpdateManualTests",
  CanDeleteManualTests = "canDeleteManualTests",
  CanModifyTestFeedTests = "canModifyTestFeedTests",
  CanViewEducation = "canViewEducation",
  CanCreateEducation = "canCreateEducation",
  CanUpdateEducation = "canUpdateEducation",
  CanDeleteEducation = "canDeleteEducation",
  CanReceiveApplications = "canReceiveApplications",
  CanViewApplications = "canViewApplications",
  CanUpdateApplications = "canUpdateApplications",
  CanDeleteApplications = "canDeleteApplications",
  CanManageAdminQueue = "canManageAdminQueue",
  CanAssignServiceItemToOthers = "canAssignServiceItemToOthers",
  CanViewManualFingerprintCards = "canViewManualFingerprintCards",
  CanCreateManualFingerprintCards = "canCreateManualFingerprintCards",
  CanDeleteManualFingerprintCards = "canDeleteManualFingerprintCards ",
  CanViewDPSFingerprintCards = "canViewDPSFingerprintCards",
  CanDeleteDPSFingerprintCards = "canDeleteDPSFingerprintCards",
  CanViewCER = "canViewCER",
  CanCreateCER = "canCreateCER",
  CanUpdateCER = "canUpdateCER",
  CanDeleteCER = "canDeleteCER",
  CanViewNotes = "canViewNotes",
  CanCreateNotes = "canCreateNotes",
  CanUpdateNotes = "canUpdateNotes",
  CanDeleteNotes = "canDeleteNotes",
  CanSelfAssignServiceItems = "canSelfAssignServiceItems",
  CanUpdateBackgroundCheckAfterPayment = "canUpdateBackgroundCheckAfterPayment",
  CanViewCertificates = "canViewCertificates",
  CanDeleteCertificates = "canDeleteCertificates",
  CanUpdateCertificateStatuses = "canUpdateCertificateStatuses",
  CanUpdateCertificateDates = "canUpdateCertificateDates",
  CanEnterPaymentReversal = "canEnterPaymentReversal",
  CanEnterPaymentRedeposit = "canEnterPaymentRedeposit",
  CanAddEvaluations = "canAddEvaluations",
  CanEditEvaluations = "canEditEvaluations",
  CanApproveCancellations = "canApproveCancellations",
  CanUploadDocuments = "canUploadDocuments",
  CanInitiateHold = "canInitiateHold",
  CanViewCollectedPaymentsReport = "canViewCollectedPaymentsReport",
  CanViewPaymentReversalReport = "canViewPaymentReversalReport",
  CanSendPaymentReversalToCollections = "canSendPaymentReversalToCollections",
  CanProcessRefunds = "canProcessRefunds",
  CanCreatePrintJobs = "canCreatePrintJobs",
  CanViewNasdtecEntries = "canViewNasdtecEntries",
  CanUpdateNasdtecEntries = "canUpdateNasdtecEntries",
  CanViewInvestigativeAlerts = "canViewInvestigativeAlerts",
  CanViewCaseIQ = "canViewCaseIQ",
  CanViewDiscipline = "canViewDiscipline",
  CanAddDiscipline = "canAddDiscipline",
  CanUpdateDiscipline = "canUpdateDiscipline",
  CanDeleteDiscipline = "canDeleteDiscipline",
  CanPerformFinalSIReview = "canPerformFinalSIReview",
  CanProcessAppeals = "canProcessAppeals",
  CanViewCohort = "canViewCohort",
  CanAddCohort = "canAddCohort",
  CanUpdateCohort = "canUpdateCohort",
  CanDeleteCohort = "canDeleteCohort",
  CanAddStudentsToCohort = "canAddStudentsToCohort",
  CanTransferStudentsToCohort = "canTransferStudentsToCohort",
  CanRemoveStudentFromCohort = "canRemoveStudentFromCohort",
  CanAuthorizeRecommendation = "canAuthorizeRecommendation",
  CanDeleteRecommendation = "canDeleteRecommendation",
  CanSubmitUnprofessionalConductReport = "canSubmitUnprofessionalConductReport",
  CanSearchEducators = "canSearchEducators",
  CanManageHelpObjects = "canManageHelpObjects",
}

import { Injectable } from "@angular/core";
import { LocalStorageService } from "./local-storage.service";
import { Params } from "@angular/router";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class RedirectUrlService {
  constructor(private localStorage: LocalStorageService) { }

  private REDIRECT_URL_KEY: string = 'REDIRECT_URL'

  set(url: string) {
    this.localStorage.set(this.REDIRECT_URL_KEY, url)
  }

  has(): boolean {
    return this.get() !== '';
  }

  get(): string {
    return this.localStorage.get(this.REDIRECT_URL_KEY) ?? '';
  }

  getRoute(): string {
    return this.get().split('?')[0]
  }

  getQueryParams(): Params {
    let httpParams = new HttpParams({
      fromString: this.get().split('?')[1]
    })

    let params: Params = {}

    httpParams.keys().forEach(k => {
      params[k] = httpParams.get(k)
    })

    return params
  }

  remove() {
    this.localStorage.remove(this.REDIRECT_URL_KEY)
  }

  validate(): boolean {
    return !this.has() || this.get()!.startsWith('/')
  }
}

<div class="row mb-2">
  <div class="col-6">
    <h2 class="h4">Cohort Management: {{cohortDescription}}</h2>
  </div>
  <div class="col-6">

    <div class="dropdown float-end">
      <button class="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        Actions
      </button>
      <ul class="dropdown-menu">
        <li><a class="dropdown-item" href="#" certTooltip="Export all of the candidates in this cohort."
          (click)="onExportStudents($event)">Export Candidates</a></li>
        <li><hr class="dropdown-divider"></li>
        <li><a class="dropdown-item" href="#" certTooltip="Upload candidate programs to this cohort."
            requiresPolicy="canAddStudentsToCohort" (click)="onUploadRecommendationsFile($event)">Upload Recommendations
            File</a></li>
            <li><a class="dropdown-item" href="#"
              certTooltip="Download the template used for bulk-uploading candidate recommendations for a cohort."
              requiresPolicy="canAddStudentsToCohort" (click)="onDownloadIRUploadFileTemplate($event)">Download Recommendations
              File Template</a></li>
        <li><hr class="dropdown-divider"></li>
        <li><a class="dropdown-item" href="#" certTooltip="Upload a new candidate file to this cohort."
            requiresPolicy="canAddStudentsToCohort" (click)="onUploadStudentFile($event)">Upload Candidate File</a></li>
        <li><a class="dropdown-item" href="#"
            certTooltip="Download the template used for uploading candidates to a cohort."
            requiresPolicy="canAddStudentsToCohort" (click)="onDownloadStudentFileTemplate($event)">Download Candidate
            File Template</a></li>
      </ul>
    </div>
    <button type="button" class="btn btn-outline-primary float-end me-2" (click)="resetFilters()">Reset Filters</button>
  </div>
</div>
<epp-cohort-management-grid [cohortId]="cohortId" />

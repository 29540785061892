import { Component, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, map } from 'rxjs';
import { AddManualPersonAlertCommand, AlertCodeDto, CodeTablesClient, EducatorsClient } from 'src/shared/services/api.service';
import { catchValidationErrors } from 'src/shared/utilities/validator-utilities';

@Component({
  selector: 'cert-add-alert-modal',
  templateUrl: './add-alert-modal.component.html',
  styles: ``
})
export class AddAlertModalComponent {
  constructor(private activeModal: NgbActiveModal, private codeTablesClient: CodeTablesClient, private educatorsClient: EducatorsClient) { }

  @ViewChild('addAlertForm') form!: NgForm;

  personId!: number;

  alertCodes$!: Observable<AlertCodeDto[]>;

  errorEmitter = new EventEmitter<any>();

  command: AddManualPersonAlertCommand = {
    personId: undefined!,
    alertCodeId: undefined!,
    additionalInformation: undefined
  };

  onClose(): void {
    this.activeModal.close();
  }

  onSubmit() {
    this.educatorsClient.addManualPersonAlert(this.command)
      .pipe(catchValidationErrors(this.form, this.errorEmitter)).subscribe({
        next: () => this.activeModal.close()
      });
  }

  ngOnInit() {
    this.command.personId = this.personId;
    this.alertCodes$ = this.codeTablesClient.getAlertCodesForManualAdd()
    .pipe(
      map(alertCodes => alertCodes.filter(x => x.canManuallyAddAlert))
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/shared/shared.module';
import { EppRoutingModule } from './epp-routing.module';
import { EppLayoutComponent } from './epp-layout/epp-layout.component';
import { CohortsListingComponent } from './cohorts-listing/cohorts-listing.component';
import { CohortsListingGridComponent } from './cohorts-listing/cohorts-listing-grid/cohorts-listing-grid.component';
import { CohortManagementComponent } from './cohort-management/cohort-management.component';
import { CohortManagementGridComponent } from './cohort-management/cohort-management-grid/cohort-management-grid.component';
import { CohortAddCohortModalComponent } from './cohorts-listing/cohort-add-cohort-modal/cohort-add-cohort-modal.component';
import { CohortsListingActionsCellRendererComponent } from './cohorts-listing/cohorts-listing-grid/cohorts-listing-actions-cell-renderer/cohorts-listing-actions-cell-renderer.component';
import { CohortManagementActionsCellRendererComponent } from './cohort-management/cohort-management-grid/cohort-management-actions-cell-renderer/cohort-management-actions-cell-renderer.component';
import { AuthModule } from 'src/auth/auth.module';
import { RecommendationsUploadComponent } from './cohort-management/recommendations-upload/recommendations-upload.component';
import { IrValidationResultsComponent } from './cohort-management/recommendations-upload/ir-validation-results/ir-validation-results.component';
import { IrWorksheetErrorsComponent } from './cohort-management/recommendations-upload/ir-validation-results/ir-worksheet-errors/ir-worksheet-errors.component';
import { UploadWarningModalComponent } from './cohort-management/recommendations-upload/upload-warning-modal/upload-warning-modal.component';
import { HelpPageComponent } from './help-page/help-page.component';
import { IrFileUploadGuideComponent } from './help-page/ir-file-upload-guide/ir-file-upload-guide.component';
import { ValidationRulesModalComponent } from './help-page/ir-file-upload-guide/validation-rules-modal/validation-rules-modal.component';
import { ExpectedValuesModalComponent } from './help-page/ir-file-upload-guide/expected-values-modal/expected-values-modal.component';
import { StudentsUploadComponent } from './cohort-management/students-upload/students-upload.component';
import { StudentsValidationResultsComponent } from './cohort-management/students-upload/students-validation-results/students-validation-results.component';

@NgModule({
  declarations: [
    EppLayoutComponent,
    CohortsListingComponent,
    CohortsListingGridComponent,
    CohortManagementComponent,
    CohortManagementGridComponent,
    CohortAddCohortModalComponent,
    CohortsListingActionsCellRendererComponent,
    CohortManagementActionsCellRendererComponent,
    RecommendationsUploadComponent,
    IrValidationResultsComponent,
    IrWorksheetErrorsComponent,
    UploadWarningModalComponent,
    HelpPageComponent,
    IrFileUploadGuideComponent,
    ValidationRulesModalComponent,
    ExpectedValuesModalComponent,
    StudentsUploadComponent,
    StudentsValidationResultsComponent
  ],
  imports: [
    CommonModule,
    EppRoutingModule,
    SharedModule,
    AuthModule
  ]
})
export class EppModule { }

import { Component } from '@angular/core';
import { HelpClient, HelpObjectDto } from 'src/shared/services/api.service';
import { LoaderService } from 'src/shared/services/loader.service';

@Component({
  selector: 'cert-admin-help',
  templateUrl: './admin-help.component.html',
  styles: ``
})
export class AdminHelpComponent {
  helpObjects!: HelpObjectDto[];
  isAddMode: boolean = false;

  dataLoaded: boolean = false;

  constructor(private helpClient: HelpClient, private loaderService: LoaderService) {}

  ngOnInit() {
    this.getHelpObjects();
  }

  getHelpObjects() {
    this.dataLoaded = false;
    this.loaderService.loaderOn();
    this.helpClient.getHelpObjects().subscribe(x => {
      this.helpObjects = x;
      this.dataLoaded = true;
      this.loaderService.loaderOff();
    });
  }

  toggleAddMode() {
    this.isAddMode = !this.isAddMode;
  }

  handleHelpObjectAdded(refreshObjects: boolean) {
    if (this.isAddMode) {
      this.toggleAddMode();
    }
    if (refreshObjects) {
      this.getHelpObjects();
    }
  }
}

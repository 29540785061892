<div class="skippy visually-hidden-focusable">
  <div class="container-fluid">
    <a href="#main" class="d-inline-flex p-2 m-1">Skip to Main Content</a>
  </div>
</div>
<header class="d-print-none">
  <nav class="navbar navbar-expand-lg navbar-light bg-primary-100" aria-label="Main Navigation">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="[homeUrl]">
        <img src="/assets/azde-seal-blank.png" alt="AZDE Logo" title="Arizona Department of Education" height="40">
      </a>
      <div class="me-5">
        <div class="text-body-emphasis">{{currentEdOrgName}} ({{currentEdOrgId}})
          @if (canChangeEdOrg) {
          <i class="bi bi-arrow-left-right ms-2 clickable" certTooltip="Click here to change your EdOrg"
            (click)="onChangeEdOrg($event)"></i>
          }
        </div>
        <div class="text-body-emphasis">School Year {{fiscalYearDescription}}</div>
      </div>

      @if (dataLoaded && showEducatorSearch()) {
        <button type="button" class="btn btn-outline-primary" requiresPolicy="canSearchEducators"
          (click)="openEducatorSearch()"><i class="search-icon me-2"></i> Educator Search</button>
      }

      <button class="navbar-toggler btn-sidebar" type="button" data-toggle="collapse" data-target=".navbar-collapse"
        aria-label="Toggle Main Navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <i class="bi bi-list"></i>
      </button>

      <div class="navbar-collapse collapse d-lg-inline-flex justify-content-end" [ngClass]="{ show: isExpanded }">
        <ul class="navbar-nav flex-grow">
          <li class="nav-item dropdown my-auto">
            @if (userTypeRoutes.length > 1) {
              <a class="nav-link dropdown-toggle" [routerLink]="[homeUrl]" [routerLinkActive]="['link-active']"
                role="button" data-bs-toggle="dropdown" aria-expanded="false">Home</a>
              <ul class="dropdown-menu">
                @for (userTypeRoute of userTypeRoutes; track userTypeRoute.userType) {
                <li>
                  <a class="dropdown-item" [routerLinkActive]="['link-active']"
                    [routerLink]="[ userTypeRoute.route]">{{userTypeRoute.description}}</a>
                </li>
                }
              </ul>
            }
            @else {
            <a class="nav-link" [routerLink]="[homeUrl]" [routerLinkActive]="['link-active']" role="button"
              aria-expanded="false">Home</a>
            }
          </li>
        </ul>

        @switch (currentRouteType) {
          @case(routeTypes.Cert) {
            <cert-cert-nav-menu />
          }
          @case(routeTypes.Lea) {
            <cert-lea-nav-menu />
          }
          @case(routeTypes.Epp) {
            <cert-epp-nav-menu />
          }
        }



        <ul class="navbar-nav flex-grow">
          <li class="nav-item dropdown my-auto">
            <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"><i
                class="bi bi-person-circle"></i></a><span class="visually-hidden">User Preferences</span>
            <ul class="dropdown-menu dropdown-menu-left">
              @if (canChangeEdOrg) {
              <li>
                <a class="dropdown-item clickable" href="#" (click)="onChangeEdOrg($event)">Change Organization</a>
              </li>
              }
              @if (obscurityFeatureEnabled) {
                <li>
                  <a class="dropdown-item clickable" href="#" (click)="onToggleObscurity($event)">Toggle Sensitive Data Obscurity</a>
                </li>
              }
              <li><a class="dropdown-item clickable" href="#" (click)="onViewProfile($event)">Profile</a></li>
              <li><cert-issue-collector /></li>
              <li><a class="dropdown-item clickable" href="#" (click)="logout($event)">Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

import { Injectable } from '@angular/core';
import { UserManager } from 'oidc-client-ts';
import { exhaustMap, from, map, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { AuthClient, EdOrgRoleSelectionDto, TokenRefreshResult, UserPolicyEvalDto } from 'src/shared/services/api.service';
import { JwtService } from 'src/auth/services/jwt.service';
import { ConfigService } from 'src/shared/services/config.service';
import { LocalStorageService } from 'src/shared/services/local-storage.service';
import { AuthenticationMethods, PolicySetKey } from 'src/shared/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _userManager!: UserManager;
  private loginChangedSubject = new Subject<boolean>();

  loginChanged$ = this.loginChangedSubject.asObservable();
  tokenRefreshed$ = new Subject<void>();

  constructor(private authClient: AuthClient, private jwtService: JwtService, private configService: ConfigService,
    private localStorageService: LocalStorageService, private modalService: NgbModal, private router: Router) {
  }

  get userManager(): UserManager {
    return this._userManager ?? new UserManager(
      {
        authority: this.configService.config.azureAppConfig.authority,
        metadataUrl: this.configService.config.azureAppConfig.metadataUrl,
        client_id: this.configService.config.azureAppConfig.clientId,
        redirect_uri: this.configService.config.azureAppConfig.redirectUri,
        scope: 'openid',
        response_type: 'code',
        post_logout_redirect_uri: this.configService.config.azureAppConfig.postLogoutRedirectUri,
        prompt: 'login'
      }
    );
  }

  login(): Promise<void> {
    // remove existing policy set if present.
    this.localStorageService.remove(PolicySetKey);

    return this.userManager.signinRedirect();
  }

  logout() {
    const userId = this.jwtService.getUserId();

    const logout$ = userId
      ? this.authClient.logout({ userId: userId })
      : of();

    logout$.subscribe(() => {
      const isTestLogin = this.jwtService.getAuthMethod() === AuthenticationMethods.test;

      this.jwtService.removeToken();
      this.localStorageService.remove(PolicySetKey);
      this.modalService.dismissAll();

      if (isTestLogin) {
        this.router.navigateByUrl('/launch');
      } else {
        this.userManager.signoutRedirect();
      };
    });
  }

  refreshToken(): Observable<TokenRefreshResult> {
    return this.authClient.refreshToken()
      .pipe(tap(x => {
        this.jwtService.setToken(x.token)
        this.tokenRefreshed$.next();
      }));
  }

  isLoggedIn(): Observable<boolean> {
    const isTokenValid = this.jwtService.getToken() && this.jwtService.isTokenRenewable();

    return of(!!isTokenValid);
  }

  completeTestLogin(testEmail: string, edOrgs: EdOrgRoleSelectionDto[]): Observable<UserPolicyEvalDto> {
    return this.authClient.createNonProdUserToken({
      edOrgRoleSelections: edOrgs,
      testEmail: testEmail
    }).pipe(map(token => {
      this.jwtService.setToken(token);
    }))
      .pipe(exhaustMap(() => {
        this.loginChangedSubject.next(true);
        return this.storePolicySet();
      }));
  }

  completeLogin(): Observable<UserPolicyEvalDto> {
    return from(this.userManager.signinRedirectCallback())
      .pipe(switchMap(idpUser => {
        return this.authClient.exchangeIdpToken({
          idpIdentityToken: idpUser.id_token!
        })
      }))
      .pipe(map(token => {
        this.jwtService.setToken(token);
        // Remove the idp user info if we've exchanged the token.
        this.userManager.removeUser();
      }))
      .pipe(exhaustMap(() => {
        this.loginChangedSubject.next(true);
        return this.storePolicySet();
      }));
  };

  changeEdOrg(edOrgId: number): Observable<UserPolicyEvalDto> {
    return this.authClient.changeEdOrg({
      edOrgId: edOrgId
    }).pipe(map(token => {
      this.jwtService.setToken(token);
    }))
      .pipe(exhaustMap(() => {
        this.loginChangedSubject.next(true);
        return this.storePolicySet();
      }));
  }

  completeLogout() {
    return this.userManager.signoutRedirectCallback();
  }

  storePolicySet(): Observable<UserPolicyEvalDto> {
    return this.authClient.getPolicyResults()
      .pipe(tap(policySet => this.localStorageService.set(PolicySetKey, JSON.stringify(policySet))));
  }

  hasPolicy(policyName: keyof UserPolicyEvalDto): boolean {
    const policySet = JSON.parse(this.localStorageService.get(PolicySetKey) ?? '{}') as UserPolicyEvalDto;
    if (policySet) {
      return policySet[policyName];
    }
    return false;
  }
}

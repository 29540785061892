<form #addHelpObjectForm="ngForm" autocomplete="off">
  <div class="row">
    <!-- Title -->
    <div class="col-3">
      <div class="form-group">
        <label for="title">Title</label>
        <input #title="ngModel" class="form-control" type="text" id="title" name="title"
          [(ngModel)]="command.title" placeholder="Enter a title...">
        <div validationErrors [for]="title.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>
    <!-- Description -->
    <div class="col-3">
      <div class="form-group">
        <label for="description">Description</label>
        <textarea #description="ngModel" row="1" class="form-control" id="description" name="description"
          [(ngModel)]="command.description" placeholder="Enter a description..."></textarea>
        <div validationErrors [for]="description.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>
    <!-- Portal Type -->
    <div class="col-3">
      <div class="form-group">
        <label for="portalType">Portal Type</label>
        <ng-select #portalType="ngModel" id="portalType" name="portalType" labelForId="portalType"
          [items]="portalTypeOptions" [closeOnSelect]="true" [multiple]="false" [bindValue]="'value'"
          [bindLabel]="'text'" [(ngModel)]="command.portalType">
        </ng-select>
        <div validationErrors [for]="portalType.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>
    <!-- Content Type -->
    <div class="col-3">
      <div class="form-group">
        <label for="contentType">Content Type</label>
        <ng-select #contentType="ngModel" id="contentType" name="contentType" labelForId="contentType"
          [items]="contentTypeOptions" [closeOnSelect]="true" [multiple]="false" [bindValue]="'value'"
          [bindLabel]="'text'" [(ngModel)]="command.contentType">
        </ng-select>
        <div validationErrors [for]="contentType.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>
  </div>

  <div class="row">
    @if(command.contentType == ContentType.PDF) {
      <!-- Pdf Content -->
      <div class="col-4">
        <cert-uploader (fileSelected)="setUploadContent($event)"/>
      </div>
    }
    @else if (command.contentType == ContentType.Link) {
      <!-- ExternalUri -->
      <div class="col-4">
        <div class="form-group">
          <label for="externalUri">External URI</label>
          <input #externalUri="ngModel" class="form-control" type="text" id="externalUri" name="externalUri"
            [(ngModel)]="command.externalUri" placeholder="Enter an external URI...">
          <div validationErrors [for]="externalUri.control" [errorEmitter]="errorEmitter"></div>
        </div>
      </div>
    }
  </div>

  <div formLevelValidationErrors [for]="addHelpObjectForm" [errorEmitter]="errorEmitter"></div>

  <div class="row text-end mt-3">
    <div class="col">
      <button class="btn btn-sm btn-outline-secondary me-2" (click)="cancelAddHelpObject()" type="button">Cancel</button>
      <button class="btn btn-sm btn-primary" (click)="onAddHelpObject()" type="submit">Save</button>
    </div>
  </div>
</form>

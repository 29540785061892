import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EppLayoutComponent } from './epp-layout/epp-layout.component';
import { IsLoggedInGuard } from 'src/app/route-guards/is-logged-in.guard';
import { CohortsListingComponent } from './cohorts-listing/cohorts-listing.component';
import { CohortManagementComponent } from './cohort-management/cohort-management.component';
import { RecommendationsUploadComponent } from './cohort-management/recommendations-upload/recommendations-upload.component';
import { IrFileUploadGuideComponent } from './help-page/ir-file-upload-guide/ir-file-upload-guide.component';
import { StudentsUploadComponent } from './cohort-management/students-upload/students-upload.component';
import { HelpHomeComponent } from 'src/help/help-home/help-home.component';

const moduleRoutes: Routes = [
  {
    path: '', component: EppLayoutComponent,
    canActivate: [IsLoggedInGuard],
    children: [
      { path: '', redirectTo: 'cohorts', pathMatch: 'prefix' },
      { path: 'help', component: HelpHomeComponent, title: 'Help' },
      { path: 'help/ir-upload-guide', component: IrFileUploadGuideComponent, title: 'Recommendations File Upload Integration Guide' },
      { path: 'cohorts', component: CohortsListingComponent, title: 'Cohorts' },
      { path: 'cohort-management/:cohortId', component: CohortManagementComponent, title: 'Cohort Management' },
      { path: 'cohort-management/recommendations-upload/:cohortId', component: RecommendationsUploadComponent, title: 'Recommendations File Upload' },
      { path: 'cohort-management/students-upload/:cohortId', component: StudentsUploadComponent, title: 'Candidates File Upload' },
      { path: 'candidates', loadChildren: () => import('./students/students.module').then(m => m.StudentsModule) },
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(moduleRoutes)],
  exports: [RouterModule]
})
export class EppRoutingModule { }

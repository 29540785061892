<label for="dateAndTime" class="form-label">Date and Time</label>
<div class="form-group">
  <div class="input-group">
    <input id="date-input-{{name}}" class="form-control" type="text" [name]="dateName" [(ngModel)]="modelDate"
      [disabled]="isDisabled" placeholder="mm/dd/yyyy" autocomplete="off" mask="M0/d0/0000"
      [outputTransformFn]="dateParser" (ngModelChange)="modelChanged()" [validation]="false" />
    <input id="time-input-{{name}}" class="form-control" type="text" [name]="timeName" [(ngModel)]="modelTime"
      [disabled]="isDisabled" placeholder="hh:mm" autocomplete="off" [apm]="true" mask="Hh:m0"
      (ngModelChange)="modelChanged()" />
    <ng-select #amPm="ngModel" id="amPm" name="amPm" [(ngModel)]="modelAmPm" [closeOnSelect]="true" [multiple]="false"
      placeholder="AM/PM..." (change)="modelChanged()">
      <ng-option value="AM">AM</ng-option>
      <ng-option value="PM">PM</ng-option>
    </ng-select>
  </div>
</div>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HelpLayoutComponent } from './help-layout/help-layout.component';
import { IsLoggedInGuard } from 'src/app/route-guards/is-logged-in.guard';
import { HelpHomeComponent } from './help-home/help-home.component';

const moduleRoutes: Routes = [
  {
    path: '', component: HelpLayoutComponent,
    canActivate: [IsLoggedInGuard],
    children: [
      { path: '', component: HelpHomeComponent, title: 'Help' },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(moduleRoutes)],
  exports: [RouterModule]
})
export class HelpRoutingModule { }

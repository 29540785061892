import { Component, Input } from '@angular/core';
import { WorksheetErrorDto } from 'src/shared/services/api.service';

@Component({
  selector: 'epp-ir-worksheet-errors',
  templateUrl: './ir-worksheet-errors.component.html',
  styles: `.error-text {
    font-size: 1.15rem;
  }`
})
export class IrWorksheetErrorsComponent {
  @Input() errors!: WorksheetErrorDto | undefined;
}

import { Injectable } from '@angular/core';
import { Observable, fromEvent, merge, debounceTime, map, takeUntil, tap, startWith, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class IdleService {

  private broadcastChannel: BroadcastChannel;
  private manualTimerReset = new Subject<Event>();
  private isCrossTabEvent = false;

  constructor() {
    this.broadcastChannel = new BroadcastChannel('idle_activity_channel');

    // Listen for activity messages from other tabs and reset the idle timer
    this.broadcastChannel.onmessage = (message) => {
      if (message.data === 'user_activity') {
        this.isCrossTabEvent = true;
        this.manualTimerReset.next(new Event('cross-tab-activity'));
      }
    };
  }

  createIdleTimeoutStream(idleTime: number, stop$: Observable<any>): Observable<boolean> {
    const sourceEvents = ['mousemove', 'keydown', 'wheel', 'touchstart', 'click'];

    // Merge all source events into a single Observable stream
    const activity$ = merge(
      ...sourceEvents.map(event => fromEvent(document, event)),
      this.manualTimerReset.pipe(map(() => new Event('cross-tab-activity')))
    ).pipe(
      startWith(null),
      tap(() => {
        if (!this.isCrossTabEvent) {
          this.broadcastActivity();
        } else {
          this.isCrossTabEvent = false; // Reset flag after handling
        }
      })
    );

    // Create a timeout stream that resets the timer on new activity
    const timeout$ = activity$.pipe(
      debounceTime(idleTime), // Reset the timer on new activity
      takeUntil(stop$), // Stop the idle timer when stop$ emits
      map(() => true) // idle timeout is reached
    );

    return timeout$;
  }

  private broadcastActivity() {
    // Broadcast a message to other tabs about the user activity
    this.broadcastChannel.postMessage('user_activity');
  }
}

import { Component } from '@angular/core';
import Dynamsoft from 'dwt';
import { WebTwain } from 'dwt/dist/types/WebTwain';
import { ToastService } from '../services/toast.service';
import { ScannerProductKey, ScannerResourcesPath } from '../constants';
import { LoaderService } from '../services/loader.service';
import { Device } from 'dwt/dist/types/WebTwain.Acquire';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cert-scanner',
  templateUrl: './scanner.component.html',
  styleUrls: ['./scanner.component.scss']
})
export class ScannerComponent {
  constructor(private toastService: ToastService, private loaderService: LoaderService, private activeModal: NgbActiveModal) { }

  containerId = "dwtControlContainer";
  DWObject: WebTwain | any;
  readonly scanner = Dynamsoft.DWT;

  scannedImages: { index: number, content: string }[] = [];
  scannerDevices: Device[] = [];
  selectedScannerDevice!: string;

  ngOnInit() {
    this.scanner.CustomizableDisplayInfo.loaderBarClassName = 'd-none';

    this.scanner.Containers = [{
      WebTwainId: 'dwtObject',
      ContainerId: this.containerId,
    }];

    this.scanner.RegisterEvent('OnWebTwainReady', () => {
      this.onReady();
    });

    this.scanner.ProductKey = ScannerProductKey;
    this.scanner.ResourcesPath = ScannerResourcesPath;
    this.scanner.Load();
  }

  ngOnDestroy() {
    this.scanner.Unload();
  }

  onReady() {
    this.loaderService.loaderOn();
    this.DWObject = this.scanner.GetWebTwain(this.containerId);

    this.DWObject.GetDevicesAsync().then((devices: Device[]) => {
      devices.filter(x => x.deviceType != Dynamsoft.DWT.EnumDWT_DeviceType.ESCLSCANNER)
        .map(x => this.scannerDevices.push(x))

      this.selectedScannerDevice = devices ? devices[0].name : '';
      this.loaderService.loaderOff();
    });
  }

  acquireImage() {
    if (this.DWObject) {
      this.DWObject.SelectDeviceAsync(this.scannerDevices.find(x => x.name === this.selectedScannerDevice))
        .then(() => {
          return this.DWObject.AcquireImageAsync({
            IfDisableSourceAfterAcquire: true,
          });
        })
        .then(() => {
          this.convertToDataURL(this.DWObject.HowManyImagesInBuffer - 1);
        })
        .catch((error: { message: any; }) => {
          this.toastService.error(error.message);
        })
        .finally(() => {
          this.DWObject.CloseSourceAsync().catch((error: any) => {
            this.toastService.error(error.message);
          });
        });
    }
  }

  convertToDataURL(imageIndex: number) {
    this.DWObject.ConvertToBlob([imageIndex], this.scanner.EnumDWT_ImageType.IT_PNG, (result: Blob) => {
      const reader = new FileReader();

      reader.onload = () => {
        const dataURL = reader.result as string;
        this.scannedImages.push({
          index: imageIndex,
          content: dataURL
        })
      };

      reader.onerror = (error) => {
        console.error('Error reading the Blob:', error);
      }

      reader.readAsDataURL(result);
    });
  }

  removeScannedImage(index: number) {
    this.scannedImages = this.scannedImages.filter(x => x.index != index);
  }

  onComplete() {
    const allIndices = Array.from({ length: this.DWObject.HowManyImagesInBuffer }, (_, i) => i);
    this.DWObject.ConvertToBlob(allIndices, this.scanner.EnumDWT_ImageType.IT_PDF, (result: Blob) => {
      const reader = new FileReader();

      reader.onload = () => {
        const dataURL = reader.result as string;
        const base64ContentPayload = dataURL.split(',')[1];

        this.activeModal.close({content: base64ContentPayload});
      };

      reader.onerror = (error) => {
        console.error('Error reading the Blob:', error);
      }

      reader.readAsDataURL(result);
    });
  }

  async onCancel() {
    await this.DWObject.CloseSourceAsync()
    this.DWObject.RemoveAllImages();
    this.activeModal.dismiss();
  }
}



import { Directive, ElementRef, Input } from '@angular/core';
import { PolicySetKey } from 'src/shared/constants';
import { UserPolicyEvalDto } from 'src/shared/services/api.service';
import { LocalStorageService } from 'src/shared/services/local-storage.service';

@Directive({
  selector: '[requiresPolicy]'
})
export class RequiresPolicyDirective {
  private maxRetries = 5;
  private delay = 500; // Delay in milliseconds
  private attempts = 0;

  constructor(
    private el: ElementRef,
    private localStorageService: LocalStorageService
  ) { }

  @Input() set requiresPolicy(policyName: keyof UserPolicyEvalDto) {
    this.checkPolicy(policyName);
  }

  private checkPolicy(policyName: keyof UserPolicyEvalDto) {
    const policySet = JSON.parse(this.localStorageService.get(PolicySetKey) ?? '{}') as UserPolicyEvalDto;

    if (policySet && this.hasAtLeastOneValue(policySet)) {
      const result = policySet[policyName];
      if (result !== true) {
        this.el.nativeElement.style.display = 'none';
      }
    } else if (this.attempts < this.maxRetries) {
      this.attempts++;
      setTimeout(() => this.checkPolicy(policyName), this.delay);
    } else {
      console.warn(`Max retries reached for checking policy ${policyName}`);
    }
  }

  private hasAtLeastOneValue(policySet: UserPolicyEvalDto): boolean {
    return Object.values(policySet).some(value => value !== undefined);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LeaLayoutComponent } from './lea-layout/lea-layout.component';
import { IsLoggedInGuard } from 'src/app/route-guards/is-logged-in.guard';
import { EducatorRosterComponent } from './educator-roster/educator-roster.component';
import { HelpHomeComponent } from 'src/help/help-home/help-home.component';

const moduleRoutes: Routes = [
  {
    path: '', component: LeaLayoutComponent,
    canActivate: [IsLoggedInGuard],
    children: [
      { path: '', redirectTo: 'roster', pathMatch: 'prefix' },
      { path: 'help', component: HelpHomeComponent, title: 'Help' },
      { path: 'roster', component: EducatorRosterComponent, title: 'Educator Roster' },
      { path: 'educators', loadChildren: () => import('./educators/educators.module').then(m => m.EducatorsModule) },
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(moduleRoutes)],
  exports: [RouterModule]
})
export class LeaRoutingModule { }

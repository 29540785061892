import { Component, Input } from '@angular/core';
import { ProfessionalDevelopmentRequirementState } from '../services/api.service';

@Component({
  selector: 'cert-pd-requirement-state',
  templateUrl: './pd-requirement-state.component.html',
  styles: `
  .badge {
    font-size: 1rem;
  }`
})
export class PdRequirementStateComponent {
  @Input() pdRequirementState!: ProfessionalDevelopmentRequirementState;

  ProfessionalDevelopmentRequirementState = ProfessionalDevelopmentRequirementState;
}

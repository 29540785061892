<div class="modal-content">
  <div class="modal-header bg-primary-100">
    <h5 class="modal-title">Program Authorization and Existing Program Overwrites</h5>
  </div>
  <div class="modal-body">
    <p markdown>There {{getAutoAuthorizationText()}} set to auto-authorize and {{getManualAuthorizationText()}} that will need to be manually authorized.</p>
    <p markdown>{{getProgramsToOverwriteText()}}</p>
    <p>Are you sure you want to continue?</p>
  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-secondary" (click)="onConfirm(false)">No</button>
    <button type="button" class="btn btn-primary" (click)="onConfirm(true)">Yes</button>
  </div>
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'cert-epp-layout',
  templateUrl: './epp-layout.component.html',
  styles: ``
})
export class EppLayoutComponent {

}

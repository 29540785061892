import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EppClient } from 'src/shared/services/api.service';
import { CohortManagementGridComponent } from './cohort-management-grid/cohort-management-grid.component';
import { FileService } from 'src/shared/services/file.service';

@Component({
  selector: 'epp-cohort-management',
  templateUrl: './cohort-management.component.html',
  styles: ``
})
export class CohortManagementComponent {
  @ViewChild(CohortManagementGridComponent) gridComponent!: CohortManagementGridComponent;

  cohortId!: number;
  cohortDescription!: string;

  constructor(
    private route: ActivatedRoute,
    private eppClient: EppClient,
    private router: Router,
    private fileService: FileService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.cohortId = +params.get('cohortId')!;
      this.getCohortInformation();
    });
  }

  getCohortInformation() {
    this.eppClient.getCohortInformation(this.cohortId).subscribe(x => this.cohortDescription = x.description);
  }

  onUploadStudentFile(ev: Event) {
    ev.preventDefault();
    this.router.navigateByUrl(`epp/cohort-management/students-upload/${this.cohortId}`);
  }

  onUploadRecommendationsFile(ev: Event) {
    ev.preventDefault();
    this.router.navigateByUrl(`epp/cohort-management/recommendations-upload/${this.cohortId}`);
  }

  resetFilters() {
    this.gridComponent.resetFilters();
  }

  onDownloadStudentFileTemplate(ev: Event) {
    ev.preventDefault();

    this.eppClient.downloadStudentUploadTemplate().subscribe(fileResponse => {
      if (fileResponse?.data) {
        this.fileService.renameAndDownloadExcelFile(fileResponse, 'CandidateTemplate.xlsx');
      }
    });
  }

  onDownloadIRUploadFileTemplate(ev: Event) {
    ev.preventDefault();

    this.eppClient.downloadIRUploadTemplate().subscribe(fileResponse => {
      if (fileResponse?.data) {
        this.fileService.renameAndDownloadExcelFile(fileResponse, 'IR_Bulk_Upload_Template.xlsx');
      }
    });
  }

  onExportStudents(ev: Event) {
    ev.preventDefault();
    this.gridComponent.onExportStudents();
  }
}

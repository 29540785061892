import { Component } from '@angular/core';
import { NgSelectConfig } from '@ng-select/ng-select';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(private ngSelectConfig: NgSelectConfig) {
  }
}

<form #createPersonsForm="ngForm" autocomplete="off">

  <div class="row">
    <div class="col-sm">
      <div class="form-group">
        <label for="lastName">Last Name</label>
        <input #lastName="ngModel" class="form-control" type="text" id="lastName" name="lastName"
          [(ngModel)]="createPersonsCommand.lastName">
        <div validationErrors [for]="lastName.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>

    <div class="col-sm">
      <div class="form-group">
        <label for="firstName">First Name</label>
        <input #firstName="ngModel" class="form-control" type="text" id="firstName" name="firstName"
          [(ngModel)]="createPersonsCommand.firstName">
        <div validationErrors [for]="firstName.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>

    <div class="col-sm">
      <div class="form-group">
        <label for="middleName">Middle Name</label>
        <input class="form-control" type="text" id="middleName" name="middleName"
          [(ngModel)]="createPersonsCommand.middleName">
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <label for="ssn">SSN</label>
        <!-- only required unless CreateSSN is checked -->
        <div class="input-group">
          <input #ssn="ngModel" class="form-control" type="text" id="ssn" name="ssn"
            [(ngModel)]="createPersonsCommand.ssn" [required]="!createPersonsCommand.generateSSN"
            [disabled]="createPersonsCommand.generateSSN" minlength="9" maxlength="9" pattern="^[0-9]*$">
          <div class="input-group-text">
            <input class="form-check-input mt-0 me-2" type="checkbox" (click)="clearSSN()"
              [(ngModel)]="createPersonsCommand.generateSSN" value="" name="createSSN" id="createSSN">
            <span>Generate</span>
          </div>
        </div>
        <div validationErrors [for]="ssn.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>

    <div class="col-sm-4">
      <div class="form-group">
        <label for="dateOfBirth">Date of Birth</label>
        <cert-date-input name="dateOfBirth" [(model)]="dateOfBirthString" [propertyName]="'dateOfBirth'"/>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-4">
      <div class="form-group">
        <label for="genderCodeId">Gender</label>
        <ng-select #genderCodeId="ngModel" id="genderCodeId" name="genderCodeId" [closeOnSelect]="true" [multiple]="false"
          [(ngModel)]="createPersonsCommand.genderCodeId">
          <ng-option *ngFor="let gender of genderCodes" [value]="gender.genderCodeId">{{gender.description}}</ng-option>
        </ng-select>
        <div validationErrors [for]="genderCodeId.control" [errorEmitter]="errorEmitter"></div>
      </div>
    </div>

    <div class="col-sm-4">
      <label for="ethnicityCodeId">Ethnicity</label>
      <ng-select #ethnicityCodeId="ngModel" id="ethnicityCodeId" name="ethnicityCodeId" [closeOnSelect]="true" [multiple]="false"
        [(ngModel)]="createPersonsCommand.ethnicityCodeId">
        <ng-option *ngFor="let ethnicity of ethnicityCodes" [value]="ethnicity.ethnicityCodeId">{{ethnicity.description}}</ng-option>
      </ng-select>
      <div validationErrors [for]="ethnicityCodeId.control" [errorEmitter]="errorEmitter"></div>
    </div>

    <div class="col-sm-4">
      <label for="raceCodesIds">Race</label>
      <ng-select #raceCodesIds="ngModel" id="raceCodesIds" name="raceCodesIds" [closeOnSelect]="true" [multiple]="true"
        [(ngModel)]="this.createPersonsCommand.raceCodesIds">
        <ng-option *ngFor="let race of raceCodes" [value]="race.raceCodeId">{{race.description}}</ng-option>
      </ng-select>
      <div validationErrors [for]="raceCodesIds.control" [errorEmitter]="errorEmitter"></div>
    </div>
  </div>

  <div class="row text-end mt-3">
    <div class="col">
      <button class="btn btn-outline-primary me-2" (click)="onCancel()" type="button">Cancel</button>
      <button class="btn btn-primary" (click)="onSubmit()" type="submit">Save</button>
    </div>
  </div>
</form>

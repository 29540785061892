<div class="modal-content">
  <div class="modal-header">
    <h3>Change Education Organization</h3>
    <div class="d-flex">
      <button type="button" class="btn-close " data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button>
    </div>
  </div>
  <div class="modal-body m-2">
    <p class="mb-4 lead">You're currently operating on behalf of <span class="fw-bold">{{currentEdOrgName}}</span>. Choose an option below to change your organization.</p>
    <label for="edOrgSelect" class="mb-1">Available Education Organizations</label>
    <div *ngIf="!dataLoaded">
      <ngx-skeleton-loader count="1" [theme]="{ height: '3rem' }"></ngx-skeleton-loader>
    </div>
    <ng-select *ngIf="dataLoaded" id="edOrgSelect" name="edOrgSelect" [closeOnSelect]="true" [multiple]="false" [(ngModel)]="selectedEdOrgId"
      placeholder="Select an organization...">
      <ng-option *ngFor="let edOrg of edOrgOptions" [value]="edOrg.edOrgId">{{ edOrg.edOrgName }}
      </ng-option>
    </ng-select>
  </div>
  <div class="modal-footer d-flex justify-content-end">
    <button type="button" class="btn btn-primary" (click)="onChange()">Change</button>
  </div>
</div>

import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cert-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styles: ``
})
export class ConfirmationModalComponent {
  message!: string;
  header!: string;

  constructor(private activeModal: NgbActiveModal) { }

  onConfirm(confirmed: boolean): void {
    this.activeModal.close(confirmed);
  }
}

import { Component, ViewChild, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cert-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @ViewChild('content') content!: TemplateRef<any>;

	constructor(private offcanvasService: NgbOffcanvas) {}

  open() {
		this.offcanvasService.open(this.content, {
      ariaLabelledBy: 'offcanvas-basic-title',
      scroll: true,
      backdrop: false
    });
	}
}

@if(educatorCertificates && educatorCertificates.length > 0) {
  <div class="table-container d-flex flex-column ms-5">
    <table class="table table-hover">
      <thead>
        <tr>
          <th class="col" scope="col">Certificate</th>
          <th class="col" scope="col">Valid</th>
          <th class="col" scope="col">Status</th>
          <th class="col" scope="col">Allowable Deficiencies</th>
          <th class="col" scope="col">Renewal App Status</th>
          <th class="col" scope="col" aria-label="tags"></th>
        </tr>
      </thead>
      <tbody>
        @for(cert of educatorCertificates; track cert.personCertificateId; let index = $index) {
          <tr scope="row">
            <td class="col">
              <span>{{cert.certificateDescription}}</span>
            </td>
            <td class="col">{{cert.validityRange}} <i>({{getExpiryMonthsDescription(cert.monthsToExpiry)}})</i></td>
            <td class="col">{{cert.status}}</td>
            <td class="col">{{cert.allowableDeficiencies}}</td>
            <td class="col">{{cert.renewalAppStatus}}</td>
            <td class="col">
              @if (cert.isRenewable && cert.professionalDevelopmentRequirementState == ProfessionalDevelopmentRequirementState.Met) {
                <span class="badge text-bg-info ms-2">Ready for Renewal</span>
              } @else if (cert.isRenewable && cert.professionalDevelopmentRequirementState == ProfessionalDevelopmentRequirementState.NotMet) {
                <span class="badge text-bg-info ms-2">Due for Renewal</span>
              }
              <cert-pd-requirement-state [pdRequirementState]="cert.professionalDevelopmentRequirementState"/>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
}
@else {
  <p class="lead ms-5 mt-2">This educator has no certificates on file.</p>
}

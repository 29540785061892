import { Component, EventEmitter, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ScannerComponent } from '../scanner.component';

@Component({
  selector: 'cert-scanner-launcher',
  templateUrl: './scanner-launcher.component.html',
  styles: ``
})
export class ScannerLauncherComponent {
  @Output() returnContent = new EventEmitter<string>();

  constructor(private modalService: NgbModal) {

  }

  launch() {
    const modal = this.modalService.open(ScannerComponent, {
      backdrop: 'static',
      size: 'xl',
      centered: true,
    });

    modal.result.then((result) => {
      if (result) {
        this.returnContent.emit(result.content);
      }
    });
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AuthService } from 'src/auth/services/auth.service';
import { IdleService } from 'src/auth/services/idle.service';
import { JwtService } from 'src/auth/services/jwt.service';
import { ConfigService } from 'src/shared/services/config.service';
import { RouteTypeService } from 'src/shared/services/route-type.service';

@Component({
  selector: 'cert-app-container',
  templateUrl: './app-container.component.html',
  styleUrls: ['./app-container.component.scss']
})
export class AppContainerComponent {
  private stop$ = new Subject<void>();
  isLoggedIn = false;
  environment?: string;

  isLoading: boolean = true;

  constructor(private authService: AuthService, private routeTypeService: RouteTypeService, private configService: ConfigService,
    private idleService: IdleService, private jwtService: JwtService, private router: Router) {
    this.authService.loginChanged$.subscribe(loggedIn => {
      this.isLoggedIn = loggedIn;
    });
  }

  ngOnInit() {
    this.authService.isLoggedIn().subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;

      if (this.isLoggedIn) {
        this.setIdleTime();
      }
    });

    this.environment = this.configService.config.environment;

    if (this.router.url == '') {
      this.routeTypeService.navigateToUserHomePage().subscribe();
    }
  }

  private setIdleTime() {
    const idleTimeoutInSeconds: number = this.configService.config.idleTimeoutInSeconds;

    this.idleService.createIdleTimeoutStream(idleTimeoutInSeconds * 1000, this.stop$).subscribe((isTimeExpired: boolean) => {
      if (isTimeExpired) {
        console.log('Idle session timeout expired.')
        this.authService.logout();
      }
    });
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }
}

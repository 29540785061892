import { Directive, ElementRef, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { FeatureManagementService } from "../services/feature-management.service";
import { Subscription } from "rxjs";

@Directive({
  selector: '[obscure]'
})
export class ObscurityDirective implements OnInit, OnDestroy {
  private featureSubscription: Subscription = new Subscription();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private featureManagementService: FeatureManagementService
  ) {}

  ngOnInit(): void {
    this.featureSubscription = this.featureManagementService.features.subscribe(features => {
      const element = this.el.nativeElement as HTMLElement;

      if (features.informationObscurity) {
        this.renderer.addClass(element, 'obscured-text');
      } else {
        this.renderer.removeClass(element, 'obscured-text');
      }
    });
  }

  ngOnDestroy(): void {
    this.featureSubscription.unsubscribe();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { dateParser } from '../services/mask-date-parser';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
  selector: 'cert-date-input',
  templateUrl: './date-input.component.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  styles: ``
})
export class DateInputComponent {
  @Input() model!: string | undefined;
  @Input() name: string = '';
  @Input() propertyName: string = '';
  @Input() isDisabled: boolean = false;

  @Output() modelChange = new EventEmitter<string>();

  errorEmitter = new EventEmitter<any>();

  dateParser = dateParser;
}

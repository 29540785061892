<span certTooltip="View Student Details" class="me-1"><a class="link-opacity-100 clickable" (click)="viewStudentDetails()">View</a></span>
@if(hasRemoveStudentPolicy && !hasAuthorizedProgram) {
  <span certTooltip="Remove Student" (click)="removeStudentFromCohort()"><a class="link-opacity-100 clickable">/ Remove</a></span>
}
@else if (!hasRemoveStudentPolicy) {
  <span certTooltip="You do not have permission to remove this student"><a class="link-opacity-75 text-muted cursor-not-allowed">/ Remove</a></span>
}
@else if (hasAuthorizedProgram) {
  <span certTooltip="This student has an authorized program, and can not be removed from this cohort"><a class="link-opacity-75 text-muted cursor-not-allowed">/ Remove</a></span>
}

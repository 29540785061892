import { Component } from '@angular/core';
import { ConfigService } from 'src/shared/services/config.service';

@Component({
  selector: 'cert-splash-layout',
  templateUrl: './splash-layout.component.html',
  styleUrls: ['./splash-layout.component.scss']
})
export class SplashLayoutComponent {
  environment?: string;

  constructor(private configService: ConfigService) {
    this.environment = this.configService.config.environment;
  }
}

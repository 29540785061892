import { Directive, ElementRef, HostListener, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[numericOnly][ngModel]'
})
export class NumericOnlyDirective {

  constructor(
    private el: ElementRef,
    @Optional() @Self() private control: NgControl
  ) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    const sanitizedValue = input.value.replace(/[^0-9]/g, '');

    input.value = sanitizedValue;

    if (this.control) {
      this.control.control!.setValue(sanitizedValue);
    }
  }
}

import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ConfirmationModalComponent } from 'src/shared/confirmation-modal/confirmation-modal.component';
import { ContactTypeCodes, FormEvents, NoteTypes } from 'src/shared/constants';
import { DeleteEducatorNoteCommand, EducatorAlertDto, EducatorsClient, EducatorNoteDto, EducatorSystemEventDto } from 'src/shared/services/api.service';
import { orderBy } from 'lodash';

@Component({
  selector: 'cert-educator-notes-list',
  templateUrl: './educator-notes-list.component.html',
  styleUrls: ['./educator-notes-list.component.scss']
})
export class EducatorNotesListComponent {
  @Input() personId$!: Observable<number>;
  @Input() educatorNotes!: EducatorNoteDto[];
  @Input() educatorSystemEvents!: EducatorSystemEventDto[];
  @Input() educatorPersonAlerts!: EducatorAlertDto[];
  @Input() dataLoaded$!: Observable<boolean>;

  dataLoaded!: boolean;
  displayNotes: boolean = true;
  displayEvents: boolean = true;
  displayAlerts: boolean = true;
  searchText: string = '';
  filterStartDate!: string;
  filterEndDate!: string;
  startDateFilter!: string;
  endDateFilter!: string;
  filtersActive: boolean = false;
  educatorAuditItems: (EducatorNoteDto | EducatorSystemEventDto | EducatorAlertDto)[] = [];
  filteredEducatorAuditItems: (EducatorNoteDto | EducatorSystemEventDto | EducatorAlertDto)[] = [];
  noteTypes = NoteTypes;

  @Output() noteToEdit = new EventEmitter<EducatorNoteDto>();
  @Output() noteDeleted = new EventEmitter<FormEvents>();

  ContactTypeCodes = ContactTypeCodes;

  constructor(
    private educatorsClient: EducatorsClient,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    this.dataLoaded$.subscribe(x => {
      this.dataLoaded = x;
      this.updateEducatorAuditItems();
    });
  }

  ngOnChanges(changes: SimpleChanges) { // detects any post-initialization changes, and updates the displayed items
    if (changes.educatorNotes || changes.educatorSystemEvents || changes.educatorPersonAlerts) {
      this.updateEducatorAuditItems();
    }
  }

  private updateEducatorAuditItems() { // by moving this outside of ngOnInit() it can be seen after initialization
    this.educatorAuditItems = orderBy([...this.educatorNotes, ...this.educatorSystemEvents, ...this.educatorPersonAlerts], e => e.sortDate, 'desc');
    this.filteredEducatorAuditItems = this.educatorAuditItems;
  }

  onEdit(note: EducatorNoteDto) {
    this.noteToEdit.emit(note);
  }

  onDelete(personNoteId: number) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      backdrop: 'static',
      size: 'md',
      centered: true,
    });
    modalRef.componentInstance.header = 'Delete Note';
    modalRef.componentInstance.message = 'Are you sure you want to delete this educator note? Operation can not be undone.';

    modalRef.result.then((result) => {
      if (result) {
        this.deleteNote(personNoteId);
      }
    });
  }

  onFilterDateRangeChange() {
    this.filterAuditItems();
  }

  filterAuditItems() {
    if (!this.educatorAuditItems) {
      return;
    }

    this.filteredEducatorAuditItems = this.educatorAuditItems;

    // apply text filter
    if (this.searchText.trim()) {
      const lowerCaseSearchText = this.searchText.toLowerCase();
      this.filteredEducatorAuditItems = this.educatorAuditItems.filter((item: EducatorNoteDto | EducatorAlertDto | EducatorSystemEventDto) => {
        let content: string;

        switch (item.itemType) {
          case 'EducatorNoteDto':
            content = (item as EducatorNoteDto).content;
            break;
          case 'EducatorAlertDto':
            content = (item as EducatorAlertDto).description;
            break;
          case 'EducatorSystemEventDto':
            content = (item as EducatorSystemEventDto).eventDescription;
            break;
          default:
            return true;
        }

        return content.toLowerCase().includes(lowerCaseSearchText);
      });
    }

    // apply date filter
    if (this.startDateFilter && this.endDateFilter) {
      let parsedStartDate = new Date(this.startDateFilter);
      let parsedEndDate = new Date(this.endDateFilter);

      let startDate: Date;
      let endDate: Date;

      if (parsedStartDate.toDateString() === parsedEndDate.toDateString()) {
        startDate = parsedStartDate;
        endDate = parsedEndDate;
      }
      else {
        startDate = parsedStartDate < parsedEndDate ? parsedStartDate : parsedEndDate;
        endDate = parsedStartDate > parsedEndDate ? parsedStartDate : parsedEndDate;
      }

      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);

      this.filteredEducatorAuditItems = this.filteredEducatorAuditItems.filter((item: EducatorNoteDto | EducatorAlertDto | EducatorSystemEventDto) => {
        const itemDate = new Date(item.sortDate);
        return itemDate >= startDate && itemDate <= endDate;
      });
    }

    // apply item type filters
    this.filteredEducatorAuditItems = this.filteredEducatorAuditItems.filter((item: EducatorNoteDto | EducatorAlertDto | EducatorSystemEventDto) => {
      switch (item.itemType) {
        case this.noteTypes.EducatorNoteDto:
          return this.displayNotes;
        case this.noteTypes.EducatorAlertDto:
          return this.displayAlerts;
        case this.noteTypes.EducatorSystemEventDto:
          return this.displayEvents;
        default:
          return true;
      }
    });
  }

  toggleNotesDisplay() {
    this.displayNotes = !this.displayNotes;
    this.filterAuditItems();
  }

  toggleEventsDisplay() {
    this.displayEvents = !this.displayEvents;
    this.filterAuditItems();
  }

  toggleAlertsDisplay() {
    this.displayAlerts = !this.displayAlerts;
    this.filterAuditItems();
  }

  toggleFilters(): void {
    this.filtersActive = !this.filtersActive;
  }

  deleteNote(personNoteId: number) {
    const deleteEducatorNoteCommand: DeleteEducatorNoteCommand = {
      personNoteId: personNoteId
    }

    this.educatorsClient.deleteEducatorNote(deleteEducatorNoteCommand).subscribe({
      next: () => {
        this.noteDeleted.emit(FormEvents.Successful);
      }
    });
  }

}

import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'cert-lea-nav-menu',
  templateUrl: './lea-nav-menu.component.html',
  styles: ``
})
export class LeaNavMenuComponent {

}

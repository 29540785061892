import { Injectable } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, ExcelStyle, RowModelType, RowNode } from 'ag-grid-community';
import { AgGridFilterModel, AgGridSortModel } from '../services/api.service';
import { Router } from '@angular/router';

// Define a dictionary type for our quick filters;
export type FilterDetailsDictionary = { [key: string]: AgGridFilterModel };

// Define a generic type for enums so we can use generic enums.
export type EnumType = { [key: string]: string | number };

@Injectable({
  providedIn: 'root'
})
export class GridCommonService {
  constructor(private router: Router) { }

  public agGrid!: AgGridAngular;
  public gridApi!: GridApi;
  public readonly numberFilterOptions = ['lessThan', 'greaterThan', 'equals'];
  public readonly textFilterOptions = ['contains'];
  public readonly dateFilterOptions = ['inRange', 'lessThan', 'greaterThan', 'equals'];

  public filters!: { [key: string]: AgGridFilterModel };
  public sortModel!: AgGridSortModel[];

  public excelStyles: ExcelStyle[] = [
    {
      id: 'date',
      dataType: 'DateTime',
      numberFormat: {
        format: 'mm/dd/yy',
      },
    },
  ];

  public rowModelType: RowModelType = 'serverSide';

  exportToExcel(fileNameInput: string, grid: GridApi) {
    if (grid) {
      const params = {
        fileName: fileNameInput,
      };
      grid.exportDataAsExcel(params);
    } else {
      console.error('Grid API is not available.');
    }
  }

  onRowSelected(event: any) {
    const selectedRowData = event.node.data;
    selectedRowData.selected = event.node.selected;
  }

  onSelectionChanged(node: RowNode, isSelected: boolean) {
    node.setSelected(isSelected);
  }

  onFilterChanged(filters: any): { [key: string]: AgGridFilterModel } {
    const restructuredFilters: { [key: string]: AgGridFilterModel } = {};

    Object.keys(filters).forEach(key => {
      const filter = filters[key];
      if (filter.filterType === 'number' && filter.operator) {
        const condition1 = filter.condition1;
        const condition2 = filter.condition2;

        if (condition1.filter) {
          condition1.filter = condition1.filter.toString();
        }
        if (condition2.filter) {
          condition2.filter = condition2.filter.toString();
        }

        restructuredFilters[key] = {
          filterType: filter.filterType,
          operator: filter.operator,
          values: [],
          filterModels: [condition1, condition2]
        };
      }
      else if (filter.filterType === 'multi') {
        const filterModels: AgGridFilterModel[] = [];

        let operator: string | undefined = undefined;
        if (filter.filterModels[0]?.operator) {
          operator = filter.filterModels[0].operator;
        }

        filter.filterModels.forEach((model: any) => {
          if (model) {
            if (model.filterType === 'set') {
              filterModels.push({
                filterType: model.filterType,
                values: model.values || [],
                filterModels: []
              });
            }
            else if (model.operator) {
              if (model.condition1) {
                filterModels.push({
                  filterType: model.condition1.filterType,
                  filter: model.condition1.filter,
                  type: model.condition1.type,
                  values: [],
                  filterModels: []
                });
              }

              if (model.condition2) {
                filterModels.push({
                  filterType: model.condition2.filterType,
                  filter: model.condition2.filter,
                  type: model.condition2.type,
                  values: [],
                  filterModels: []
                });
              }
            }
            else {
              filterModels.push({
                filterType: model.filterType,
                values: [],
                type: model.type,
                filter: model.filter,
                filterModels: []
              });
            }
          }
        });

        restructuredFilters[key] = {
          filterType: filter.filterType,
          operator: operator,
          values: [],
          filterModels: filterModels
        };
      }
      else {
        if (filter.filter !== null && filter.filter !== undefined) {
          filter.filter = filter.filter.toString();
        }
        restructuredFilters[key] = filter;
      }
    });

    return restructuredFilters;
  }

  bindQuickFilters<TEnum extends EnumType>(filters: any, quickFilterEnum: TEnum,
    quickFilterSet: FilterDetailsDictionary): keyof TEnum | undefined {
    const filterKeys = Object.keys(filters);

    if (filterKeys.length === 1) {
      for (const key of Object.keys(filters)) {
        const isQuickFilterKey = Object.values(quickFilterEnum).includes(key);
        if (isQuickFilterKey) {
          const currFilter = filters[key];

          // fill-in the filter models if it's missing.
          currFilter.filterModels ??= [];
          const filterDef = quickFilterSet[key];
          const hasQuickFilterMatch = this.deepEqual(currFilter, filterDef);

          if (hasQuickFilterMatch) {
            return key as keyof TEnum;
          }
        }
      }
    }
    return undefined;
  }

  castToEnum<T extends EnumType>(obj: any): T {
    return obj as T;
  }

  // Function to check if two objects are deeply equal
  deepEqual(obj1: any, obj2: any): boolean {
    // Helper function to get sorted JSON string of an object
    const getSortedJSONString = (obj: any): string => {
      return JSON.stringify(obj, Object.keys(obj).sort((a,b) => a.localeCompare(b)));
    };

    // Compare sorted JSON strings
    return getSortedJSONString(obj1) === getSortedJSONString(obj2);
  };
}

<div class="modal-content">
  <div class="modal-header bg-primary-100">
    <h5 class="modal-title">Recommendations File Upload Expected Values</h5>
  </div>
  <div class="modal-body">
    @if(dataLoaded) {
      <ul class="nav nav-tabs" id="expectedValuesTabs" role="tablist">
        <li class="nav-item h5">
          <a class="nav-link active clickable" id="tab-ProgramCode" data-bs-toggle="tab" data-bs-target="#content-ProgramCode"
            role="tab" aria-controls="content-ProgramCode" aria-selected="true">
            ProgramCode
          </a>
        </li>
        <li class="nav-item h5">
          <a class="nav-link clickable" id="tab-DegreeLevel" data-bs-toggle="tab" data-bs-target="#content-DegreeLevel"
            role="tab" aria-controls="content-DegreeLevel" aria-selected="false">
            DegreeLevel
          </a>
        </li>
        <li class="nav-item h5">
          <a class="nav-link clickable" id="tab-DegreeType" data-bs-toggle="tab" data-bs-target="#content-DegreeType"
            role="tab" aria-controls="content-DegreeType" aria-selected="false">
            DegreeType
          </a>
        </li>
        <li class="nav-item h5">
          <a class="nav-link clickable" id="tab-CertificateType" data-bs-toggle="tab"
            data-bs-target="#content-CertificateType" role="tab" aria-controls="content-CertificateType"
            aria-selected="false">
            CertificateType
          </a>
        </li>
        <li class="nav-item h5">
          <a class="nav-link clickable" id="tab-ApprovedArea" data-bs-toggle="tab"
            data-bs-target="#content-ApprovedArea" role="tab" aria-controls="content-ApprovedArea"
            aria-selected="false">
            ApprovedArea
          </a>
        </li>
        <li class="nav-item h5">
          <a class="nav-link clickable" id="tab-Endorsement" data-bs-toggle="tab"
            data-bs-target="#content-Endorsement" role="tab" aria-controls="content-Endorsement"
            aria-selected="false">
            Endorsement
          </a>
        </li>
        <li class="nav-item h5">
          <a class="nav-link clickable" id="tab-Language" data-bs-toggle="tab"
            data-bs-target="#content-Language" role="tab" aria-controls="content-Language"
            aria-selected="false">
            Language
          </a>
        </li>
        <li class="nav-item h5">
          <a class="nav-link clickable" id="tab-Requirement" data-bs-toggle="tab"
            data-bs-target="#content-Requirement" role="tab" aria-controls="content-Requirement"
            aria-selected="false">
            Requirement
          </a>
        </li>
      </ul>

      <!-- Tabs Content -->
      <div class="tab-content" id="expectedValuesContent">
        <!-- ProgramCode Tab Content -->
        <div class="tab-pane fade show active" id="content-ProgramCode" role="tabpanel" aria-labelledby="tab-ProgramCode">
          <div class="mt-2">
            <ul class="list-group">
              @for(programCode of expectedValues.programCodes; track programCode;) {
                <li class="list-group-item">{{programCode.code}} - {{programCode.description}}</li>
              }
            </ul>
          </div>
        </div>
        <!-- DegreeLevel Tab Content -->
        <div class="tab-pane fade" id="content-DegreeLevel" role="tabpanel" aria-labelledby="tab-DegreeLevel">
          <div class="mt-2">
            <ul class="list-group">
              @for(item of expectedValues.degreeLevels; track item;) {
                <li class="list-group-item">{{item}}</li>
              }
            </ul>
          </div>
        </div>
        <!-- DegreeType Tab Content -->
        <div class="tab-pane fade" id="content-DegreeType" role="tabpanel" aria-labelledby="tab-DegreeType">
          <div class="mt-2">
            <ul class="list-group">
              @for(item of expectedValues.degreeTypes; track item;) {
                <li class="list-group-item">{{item}}</li>
              }
            </ul>
          </div>
        </div>
        <!-- CertificateType Tab Content -->
        <div class="tab-pane fade" id="content-CertificateType" role="tabpanel" aria-labelledby="tab-CertificateType">
          <div class="mt-2">
            <ul class="list-group">
              @for(item of expectedValues.certificateTypes; track item;) {
                <li class="list-group-item">{{item}}</li>
              }
            </ul>
          </div>
        </div>
        <!-- ApprovedArea Tab Content -->
        <div class="tab-pane fade" id="content-ApprovedArea" role="tabpanel" aria-labelledby="tab-ApprovedArea">
          <div class="mt-2">
            <ul class="list-group">
              @for(item of expectedValues.approvedAreas; track item;) {
                <li class="list-group-item">{{item}}</li>
              }
            </ul>
          </div>
        </div>
        <!-- Endorsement Tab Content -->
        <div class="tab-pane fade" id="content-Endorsement" role="tabpanel" aria-labelledby="tab-Endorsement">
          <div class="mt-2">
            <ul class="list-group">
              @for(item of expectedValues.endorsements; track item;) {
                <li class="list-group-item">{{item}}</li>
              }
            </ul>
          </div>
        </div>
        <!-- Language Tab Content -->
        <div class="tab-pane fade" id="content-Language" role="tabpanel" aria-labelledby="tab-Language">
          <div class="mt-2">
            <ul class="list-group">
              @for(item of expectedValues.languages; track item;) {
                <li class="list-group-item">{{item}}</li>
              }
            </ul>
          </div>
        </div>
        <!-- Requirement Tab Content -->
        <div class="tab-pane fade" id="content-Requirement" role="tabpanel" aria-labelledby="tab-Requirement">
          <div class="mt-2">
            <ul class="list-group">
              @for(item of expectedValues.requirements; track item;) {
                <li class="list-group-item">{{item}}</li>
              }
            </ul>
          </div>
        </div>
      </div>
    }
    @else {
      <ngx-skeleton-loader count="2" [theme]="{height: '2rem'}"/>
    }
  </div>
  <div class="modal-footer d-flex justify-content-between">
    <a class="link-opacity-75 clickable card-link" (click)="onDownloadExpectedValuesWorkbook()">Download Expected Values List</a>
    <button type="button" class="btn btn-secondary" (click)="onConfirm(false)">Close</button>
  </div>
</div>

<div class="modal-content">
  <div class="modal-header bg-primary-100">
    <h5 class="modal-title">Scanner</h5>
  </div>
  <div class="modal-body">
    <div class="row justify-content-between">
      <div class="col-md-6">
        <label class="form-label" for="selectedScanner">Choose a Scanner</label>
        <ng-select id="selectedScanner" name="selectedScanner" [closeOnSelect]="true" [multiple]="false"
          [(ngModel)]="selectedScannerDevice" placeholder="Select a device...">
          <ng-option *ngFor="let scannerDevice of scannerDevices" [value]="scannerDevice.name">
            {{scannerDevice.displayName}}
          </ng-option>
        </ng-select>
      </div>
      <div class="col-md-3">
        <div class="d-grid text-center">
          <span class="form-label">Scan one or more pages for this document.</span>
          <button type="submit" class="btn btn-outline-primary" (click)="acquireImage()"><i class="bi bi-file-earmark-break"></i> Scan</button>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="thumbnail-wrapper mx-3" *ngFor="let item of scannedImages">
        <div class="d-flex justify-content-between align-items-center">
          <span class="thumbnail-title">Image #{{ item.index }}</span>
          <i certTooltip="Remove" class="trash-icon" (click)="removeScannedImage(item.index)"></i>
        </div>
        <img class="thumbnail" [src]="item.content" alt="Scanned Thumbnail">
      </div>
    </div>

  </div>
  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">Cancel</button>
    <button *ngIf="scannedImages.length > 0" type="button" class="btn btn-primary" (click)="onComplete()">Complete</button>
  </div>
</div>
<div class="m-2 d-none" id="dwtControlContainer"></div>
